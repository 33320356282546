<script setup lang="ts">
import type { ButtonVariants } from '@/components/ui/button'
import type { PrimitiveProps } from 'radix-vue'
import { type HTMLAttributes, computed } from 'vue'
import { omit } from 'lodash-es'
import ToggleButton from '@/components-v2/data-input/ToggleButton.vue'

interface Props extends PrimitiveProps {
  variant?: ButtonVariants['variant']
  size?: ButtonVariants['size']
  class?: HTMLAttributes['class']
  value: string | null | undefined
}

const props = defineProps<Props>()
const model = defineModel<string | null | undefined>({ required: true })
const booleanValue = computed({
  get() {
    return model.value === props.value
  },
  set(value: boolean) {
    if (value) {
      model.value = props.value
    }
  },
})

const delegatedProps = computed(() => omit(props, ['class', 'value', 'modelValue']))
</script>

<template>
  <ToggleButton v-model="booleanValue" :class="props.class" v-bind="delegatedProps">
    <input v-model="model" :value="props.value" hidden type="radio" />
    <slot />
  </ToggleButton>
</template>

<style scoped lang="scss">

</style>
