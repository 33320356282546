import type { Sticker } from '@/components/Stickers/stickerLibrary/stickerLibrary'
import GenericTranslucent from '@/components/Stickers/stickerLibrary/components/GenericTranslucent.vue'
import GenericSquare from '@/components/Stickers/stickerLibrary/components/GenericSquare.vue'
import GenericRound from '@/components/Stickers/stickerLibrary/components/GenericRound.vue'
import GenericSquareRounded from '@/components/Stickers/stickerLibrary/components/GenericSquareRounded.vue'
import { markRaw } from 'vue'

export const kickStickers = [
  {
    key: 'kick-generic-square',
    component: markRaw(GenericSquare),
    tags: ['kick'],
    icon: 'kick',
    colors: {
      color: '#53FC19',
      dark: 'black',
      light: 'white',
    },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'kick-generic-round',
    component: markRaw(GenericRound),
    tags: ['kick'],
    icon: 'kick',
    colors: {
      color: '#53FC19',
      light: '#000000',
      dark: '#ffffff',
    },
    createdAt: new Date(1970, 0, 1),
  },

  {
    key: 'kick-generic-square-rounded',
    component: markRaw(GenericSquareRounded),
    tags: ['kick'],
    icon: 'kick',
    colors: {
      color: '#53FC19',
      dark: '#000000',
      light: '#ffffff',
    },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'kick-generic-translucent',
    component: markRaw(GenericTranslucent),
    tags: ['kick'],
    icon: 'kick',
    colors: { dark: '#000000', kick: '#53FC19' },
    createdAt: new Date(2023, 9, 1),
  },
] as const satisfies Readonly<Sticker[]>
