<template>
  <div
    :class="{
      'border-[#5BB0FF] from-[#315DF8] to-[#26B1FF] text-white/90': color === 'celestial-blue',
      'border-[#2AB585] from-[#31F8BF] to-[#02A571] text-gray-800/90': color === 'aurora-green',
      'border-[#F8D284] from-[#FEDD00] to-[#FF9400] text-gray-800/90': color === 'stellar-gold',
      'border-[#E59DFF] from-[#AD00FE] to-[#FF00A8] text-white/90': color === 'lavender-nebula',
      'border-[#484848] from-[#000] to-[#585858] text-white/90': color === 'galactic-slate',
      'border-[#CACACA] from-[#FFF] to-[#AFAFAF] text-gray-800/90': color === 'selunite-silver',
      'border-[#E5908E] from-[#EB3F3F] to-[#DA7023] text-white/90': color === 'jupiter-storm',
    }"
    class="mt-[0.6rem] flex flex-col items-center justify-center border-2 px-3 text-center text-lg font-bold"
    style="background-image: linear-gradient(94deg, var(--tw-gradient-stops))"
  >
    <div v-if="socials.length > 0" class="-mt-3 flex items-center justify-center gap-0.5">
      <div
        v-for="icon in socials"
        :key="icon"
        class="relative flex h-5 w-5 items-center justify-center rounded-md bg-zinc-100 text-black"
      >
        <StickerIcons :icon="icon" class="h-2.5 w-2.5"/>
      </div>
    </div>
    <TextSticker
      ref="sticker"
      variant="none"
      @update-rect="(e) => emit('updateRect', e)"
      @update-content="(e) => emit('updateContent', e)"
      @update="(e) => emit('update', e)"
      @sticker-loaded="(e) => emit('stickerLoaded', e)"
      :html-content="htmlContent"
    />
  </div>
</template>

<script lang="ts" setup>
import StickerIcons from '../../../StickerIcons.vue'
import { computed, ref } from 'vue'
import TextSticker from '@/components/Stickers/TextSticker.vue'

const props = defineProps({
  htmlContent: {
    type: String,
    default: '',
  },
  color: {
    type: String,
    default: 'blue',
  },
  icon: {
    type: String,
    default: '["twitch", "youtube", "kick"]',
  },
  setEditMode: {
    type: Function,
    required: false,
  },
})
const socials = computed(() => JSON.parse(props.icon))

const emit = defineEmits(['stickerLoaded', 'updateContent', 'update', 'paste', 'updateRect'])

const sticker = ref<TextSticker>()
defineExpose({
  setEditMode: () => {
    sticker.value.setEditMode()
  },
})
</script>

<style lang="scss" scoped>
.none {
  padding: 0.2em 0.2em 0.3em 0.2em;
  line-height: 24px;
  font-size: 24px;
  font-weight: bold;
}
</style>
