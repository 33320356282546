export const formatFileSize = (bytes: number, decimals = 2) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  for (let i = 0; i < sizes.length; i++) {
    const fileSize = Number((bytes / Math.pow(1e3, i)).toFixed(decimals))
    if (fileSize < 1e3) {
      return fileSize + ' ' + sizes[i]
    }
  }
}

export const kbToBytes = (kb: number) => kb * 1e3
export const mbToBytes = (mb: number) => mb * 1e6
export const gbToBytes = (gb: number) => gb * 1e9
export const tbToBytes = (tb: number) => tb * 1e12

export const bytesToKb = (bytes: number) => bytes / 1e3
export const mbToKb = (mb: number) => mb * 1e3
export const gbToKb = (gb: number) => gb * 1e6
export const tbToKb = (tb: number) => tb * 1e9

export const bytesToMb = (bytes: number) => bytes / 1e6
export const kbToMb = (kb: number) => kb / 1e3
export const gbToMb = (gb: number) => gb * 1e3
export const tbToMb = (tb: number) => tb * 1e6

export const bytesToGb = (bytes: number) => bytes / 1e9
export const kbToGb = (kb: number) => kb / 1e6
export const mbToGb = (mb: number) => mb / 1e3
export const tbToGb = (tb: number) => tb * 1e3

export const bytesToTb = (bytes: number) => bytes / 1e12
export const kbToTb = (kb: number) => kb / 1e9
export const mbToTb = (mb: number) => mb / 1e6
export const gbToTb = (gb: number) => gb / 1e3
