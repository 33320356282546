import { acceptHMRUpdate, defineStore } from 'pinia'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { useEditorStickersStore } from '@/store/editor/editorStickers'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { canGuardWithPopup } from '@/Hooks/useGuard'
import { useStickersStore as useOldStickersStore } from '@/store/entity-system/useStickersStore'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import { last } from 'lodash-es'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { useCurrentCaption } from '@/areas/editor/pages/captions/useCurrentCaption'

export const FocusTypes = {
  NONE: 'none',
  TEXTSTICKER: 'textsticker',
  CAPTION: 'caption',
  FEED: 'feed',
  SEGMENT: 'segment',
  STICKER: 'sticker',
  ZOOM: 'zoom',
  CROP: 'crop',
} as const
export type FocusType = (typeof FocusTypes)[keyof typeof FocusTypes]

type FocusTarget = {
  type: FocusType
  key?: string
}

interface EditorFocusState {
  focus: FocusTarget | null
  selectedCaptions: string[]
}

export const useEditorFocusStore = defineStore('editorFocus', {
  state: (): EditorFocusState => {
    return {
      focus: null,
      selectedCaptions: [],
    }
  },
  getters: {
    canDeleteFocusModel(state) {

      if (state.focus?.type === FocusTypes.FEED) return false
      if (state.focus?.type === FocusTypes.CROP) return false

      const editorFeedDataStore = useEditorFeedDataStore()
      const segmentsStore = useSegmentsStore()

      if (state.focus?.type === FocusTypes.SEGMENT && state.focus.key && (editorFeedDataStore.segments.length === 1 || segmentsStore.ids.length === 1)) {
        return false
      }

      return state.focus?.key !== undefined
    },
  },
  actions: {
    setFocus(type: FocusType, key?: string) {
      // console.trace('focus', type, key)
      this.focus = { type, key }
      if (type === FocusTypes.CAPTION && key) {
        this.selectedCaptions = [key]
      }
    },
    unFocus() {
      // console.trace('unFocus')
      this.focus = null
    },
    deleteFocusModel() {
      const isNewEditor = useFeatureFlagEnabled('new-editor-v2').value
      const historyStore = useHistoryStore()
      if (this.focus?.type === FocusTypes.CAPTION && this.focus.key) {
        const editorCaptionsStore = useEditorCaptionsStore()
        if (isNewEditor) {
          const caption = editorCaptionsStore.captions.find((caption) => caption.id === this.focus!.key)!
          if (caption.words.length === 1) {
            editorCaptionsStore.deleteCaption(this.focus.key)
          } else {
            const { currentWordId } = useCurrentCaption()
            editorCaptionsStore.updateCaption(caption.id, {
              ...caption,
              words: caption.words.filter((word) => word.id !== currentWordId.value),
            })
          }
          this.unFocus()
          historyStore.push()
        } else {
          const editorCaptionsStore = useEditorCaptionsStore()
          editorCaptionsStore.deleteCaption(this.focus.key)
          this.unFocus()
        }
      }
      if ((this.focus?.type === FocusTypes.STICKER || this.focus?.type === FocusTypes.TEXTSTICKER) && this.focus.key) {
        if (isNewEditor) {
          const stickersStore = useStickersStore()
          stickersStore.removeById(this.focus.key)
          const nextSticker = stickersStore.ids[stickersStore.ids.length - 1]
          if (nextSticker) {
            stickersStore.focus(nextSticker)
          }
          historyStore.push()
        } else {
          const editorStickerStore = useEditorStickersStore()
          editorStickerStore.removeSticker(this.focus.key)

          const prevFocusId = this.focus.key
          this.unFocus()

          const stickersStore = useOldStickersStore()
          if (stickersStore.ids.length) {
            stickersStore.removeById(prevFocusId)
            const nextFocusId = last(stickersStore.ids)
            if (nextFocusId) {
              const focusType = stickersStore.selectFocusTypeById(nextFocusId)
              this.focus = {
                type: focusType.value,
                key: nextFocusId,
              }
            }
          }
        }
      }

      if (this.focus?.type === FocusTypes.SEGMENT && this.focus.key) {
        if (isNewEditor) {
          const segmentsStore = useSegmentsStore()
          const segments = segmentsStore.whereIsNotZoom()
          if (segments.value.length > 1) {
            segmentsStore.removeById(this.focus.key)
            historyStore.push()
          }
        } else {
          const editorFeedStore = useEditorFeedDataStore()
          editorFeedStore.removeSegment(this.focus.key)
        }

        this.unFocus()
      }
      if (this.focus?.type === FocusTypes.ZOOM && this.focus.key) {
        if (isNewEditor) {
          const segmentsStore = useSegmentsStore()
          segmentsStore.removeById(this.focus.key)
          historyStore.push()
        } else {
          const editorFeedStore = useEditorFeedDataStore()
          editorFeedStore.removeZoom(this.focus.key)
        }

        this.unFocus()
      }
    },
    splitFocusModel(timeMs: number) {
      const isNewEditor = useFeatureFlagEnabled('new-editor-v2').value
      if (this.focus?.type === FocusTypes.SEGMENT && this.focus.key) {
        if (!canGuardWithPopup('video-segmentation')) return
        if (isNewEditor) {

          const segmentsStore = useSegmentsStore()
          segmentsStore.splitAt('layout', timeMs)

          useHistoryStore().push()
        } else {
          const editorFeedStore = useEditorFeedDataStore()
          editorFeedStore.splitSegment(timeMs)
          // focus on the new right segment
          const rightSegment = editorFeedStore.segments.find((segment) => segment.start === timeMs)
          if (rightSegment) {
            this.focus = {
              type: FocusTypes.SEGMENT,
              key: rightSegment.id,
            }
          }
        }
      }

      if (this.focus?.type === FocusTypes.CROP && this.focus.key && isNewEditor) {
        const segmentsStore = useSegmentsStore()
        segmentsStore.splitAt('layout', timeMs)
        useHistoryStore().push()
      }

      if (this.focus?.type === FocusTypes.CAPTION && this.focus.key) {
        const editorCaptionsStore = useEditorCaptionsStore()
        editorCaptionsStore.splitCaption(timeMs)
        if (isNewEditor) {
          useHistoryStore().push()
        }
      }

      if (this.focus?.type === FocusTypes.ZOOM && this.focus.key) {
        if (isNewEditor) {
          const segmentsStore = useSegmentsStore()
          segmentsStore.splitAt('zoom', timeMs)
          useHistoryStore().push()
        } else {
          const editorFeedStore = useEditorFeedDataStore()
          editorFeedStore.splitZoom(timeMs)
        }

      }

      if (this.focus?.type === FocusTypes.STICKER || this.focus?.type === FocusTypes.TEXTSTICKER) {
        if (isNewEditor) {
          const newStickersStore = useStickersStore()
          newStickersStore.splitStickerById(this.focus.key, timeMs)
          useHistoryStore().push()
        } else {
          const editorStickerStore = useEditorStickersStore()
          editorStickerStore.splitSticker(timeMs)

          const oldStickersStore = useOldStickersStore()
          oldStickersStore.splitStickerById(this.focus.key, timeMs)
        }
      }
    },
    pullModel(ms: number, direction: 'left' | 'right') {
      if (!this.focus?.key) return
      const isNewEditor = useFeatureFlagEnabled('new-editor-v2').value
      const historyStore = useHistoryStore()
      if (this.focus.type === FocusTypes.SEGMENT) {
        if (isNewEditor) {
          const segmentsStore = useSegmentsStore()
          segmentsStore.pullSegment(this.focus.key, direction, ms)
          historyStore.push()
        } else {
          const editorFeedStore = useEditorFeedDataStore()
          const _segment = editorFeedStore.segments.find((segment) => segment.id === this.focus.key)
          if (_segment) {
            const newSegment = findAndPull(_segment, direction, ms)
            if (newSegment) {
              editorFeedStore.updateSegment(newSegment.id, newSegment)
            }
          }
        }
      }

      if (this.focus.type === FocusTypes.CAPTION) {
        const editorCaptionsStore = useEditorCaptionsStore()
        const caption = editorCaptionsStore.captions.find((caption) => caption.id === this.focus.key)
        if (!caption) return
        const newCaption = findAndPull(caption, direction, ms)
        if (newCaption) {
          editorCaptionsStore.updateCaption(newCaption.id, newCaption)
        }
      }

      if (this.focus.type === FocusTypes.ZOOM) {
        if (isNewEditor) {
          const segmentsStore = useSegmentsStore()
          segmentsStore.pullSegment(this.focus.key, direction, ms)
          historyStore.push()
        } else {
          const editorFeedStore = useEditorFeedDataStore()
          const zoom = editorFeedStore.zooms.find((zoom) => zoom.id === this.focus.key)
          if (!zoom) return
          const newZoom = findAndPull(zoom, direction, ms)
          if (newZoom) {
            editorFeedStore.updateZoom(newZoom.id, newZoom)
          }
        }
      }

      if (this.focus.type === FocusTypes.STICKER || this.focus.type === FocusTypes.TEXTSTICKER) {
        if (isNewEditor) {
          const stickersStore = useStickersStore()
          stickersStore.pullSticker(this.focus.key, direction, ms)
          historyStore.push()
        } else {
          const editorStickerStore = useEditorStickersStore()
          const sticker = editorStickerStore.selectedStickers.find((sticker) => sticker.key === this.focus.key)
          if (sticker) {
            sticker.id = this.focus.key
            const newSticker = findAndPull(sticker, direction, ms)
            if (newSticker) {
              editorStickerStore.updateSticker(newSticker.id, newSticker)
            }
          }
        }
      }
    },
  },
})

const findAndPull = (
  model: {
    start: number
    end: number
    id: string
  },
  direction: 'left' | 'right',
  targetMs: number
) => {
  const alignLeft = direction === 'left'
  return {
    start: alignLeft ? model.start : targetMs,
    end: alignLeft ? targetMs : model.end,
    id: model.id,
  }
}

// Allows hot-reloading of the store
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useEditorFocusStore, import.meta.hot))
}
