// @ts-ignore
import format from 'date-fns/format'

export const clamp = function (value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max)
}

/**
 * @param ms time in milliseconds
 * @param format string that will be used to format the time. This string can contain the following placeholders:
 * - hh  : hours wil by default be removed if 0 along with any following colons,commas, spaces and dots
 * - mm  : minutes
 * - ss  : seconds
 * - ms  : milliseconds
 */
export const msToTime = (ms: number) => {
  const minutes = Math.floor(ms / (60 * 1000))
  const seconds = Math.floor((ms % (60 * 1000)) / 1000)
  const milliseconds = ms % 1000

  return format(new Date(0, 0, 0, 0, minutes, seconds, milliseconds), 'm:ss.S')
}

export function addEvents(target: HTMLElement | Window, events: Map<string, () => any>) {
  events.forEach((cb, eventName) => {
    target.addEventListener(eventName, cb)
  })
}

export function removeEvents(target: HTMLElement | Window, events: Map<string, () => void>) {
  events.forEach((cb, eventName) => {
    target.removeEventListener(eventName, cb)
  })
}

export const isInputTargetOrRangeSelection = (e: Event) => {
  return window.getSelection()?.type === 'Range' || isInputTarget(e)
}

export const isInputTarget = (e: Event) => {
  if (e.target instanceof HTMLElement) {
    const { tagName, contentEditable } = e.target;
    return tagName === 'INPUT' || tagName === 'TEXTAREA' || contentEditable === 'true'
  } else {
    return false;
  }
}

export function isForeignTarget(e: Event) {
  if (!e.target) return false
  const target = e.target as HTMLElement
  return !target.matches('#app *') && !target.matches('body')
}

type EasingFunction = (progress: number) => number

interface EasingDictionary {
  [easing: string]: EasingFunction
}

const pow = Math.pow
const sqrt = Math.sqrt
const sin = Math.sin
const cos = Math.cos
const PI = Math.PI
const c1 = 1.70158
const c2 = c1 * 1.525
const c3 = c1 + 1
const c4 = (2 * PI) / 3
const c5 = (2 * PI) / 4.5

const bounceOut: EasingFunction = function (x) {
  const n1 = 7.5625
  const d1 = 2.75

  if (x < 1 / d1) {
    return n1 * x * x
  } else if (x < 2 / d1) {
    return n1 * (x -= 1.5 / d1) * x + 0.75
  } else if (x < 2.5 / d1) {
    return n1 * (x -= 2.25 / d1) * x + 0.9375
  } else {
    return n1 * (x -= 2.625 / d1) * x + 0.984375
  }
}

// https://easings.net/

export const normalize = (min, max) => {
  const delta = max - min
  return function (val) {
    return (val - min) / delta
  }
}

export const interpolate = (a: number, b: number, progress: number) => {
  return a * progress + b * (1 - progress)
}

export function invlerp(a: number, b: number, v: number) {
  // prevent division by 0
  if (a === b) return 0
  return (v - a) / (b - a)
}

export const easingsFunctions = {
  linear: (x) => x,
  easeInQuad: function (x) {
    return x * x
  },
  easeOutQuad: function (x) {
    return 1 - (1 - x) * (1 - x)
  },
  easeInOutQuad: function (x) {
    return x < 0.5 ? 2 * x * x : 1 - pow(-2 * x + 2, 2) / 2
  },
  easeInCubic: function (x) {
    return x * x * x
  },
  easeOutCubic: function (x) {
    return 1 - pow(1 - x, 3)
  },
  easeInOutCubic: function (x) {
    return x < 0.5 ? 4 * x * x * x : 1 - pow(-2 * x + 2, 3) / 2
  },
  easeInQuart: function (x) {
    return x * x * x * x
  },
  easeOutQuart: function (x) {
    return 1 - pow(1 - x, 4)
  },
  easeInOutQuart: function (x) {
    return x < 0.5 ? 8 * x * x * x * x : 1 - pow(-2 * x + 2, 4) / 2
  },
  easeInQuint: function (x) {
    return x * x * x * x * x
  },
  easeOutQuint: function (x) {
    return 1 - pow(1 - x, 5)
  },
  easeInOutQuint: function (x) {
    return x < 0.5 ? 16 * x * x * x * x * x : 1 - pow(-2 * x + 2, 5) / 2
  },
  easeInSine: function (x) {
    return 1 - cos((x * PI) / 2)
  },
  easeOutSine: function (x) {
    return sin((x * PI) / 2)
  },
  easeInOutSine: function (x) {
    return -(cos(PI * x) - 1) / 2
  },
  easeInExpo: function (x) {
    return x === 0 ? 0 : pow(2, 10 * x - 10)
  },
  easeOutExpo: function (x) {
    return x === 1 ? 1 : 1 - pow(2, -10 * x)
  },
  easeInOutExpo: function (x) {
    return x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ? pow(2, 20 * x - 10) / 2 : (2 - pow(2, -20 * x + 10)) / 2
  },
  easeInCirc: function (x) {
    return 1 - sqrt(1 - pow(x, 2))
  },
  easeOutCirc: function (x) {
    return sqrt(1 - pow(x - 1, 2))
  },
  easeInOutCirc: function (x) {
    return x < 0.5 ? (1 - sqrt(1 - pow(2 * x, 2))) / 2 : (sqrt(1 - pow(-2 * x + 2, 2)) + 1) / 2
  },
  easeInBack: function (x) {
    return c3 * x * x * x - c1 * x * x
  },
  easeOutBack: function (x) {
    return 1 + c3 * pow(x - 1, 3) + c1 * pow(x - 1, 2)
  },
  easeInOutBack: function (x) {
    return x < 0.5
      ? (pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2
      : (pow(2 * x - 2, 2) * ((c2 + 1) * (x * 2 - 2) + c2) + 2) / 2
  },
  easeInElastic: function (x) {
    return x === 0 ? 0 : x === 1 ? 1 : -pow(2, 10 * x - 10) * sin((x * 10 - 10.75) * c4)
  },
  easeOutElastic: function (x) {
    return x === 0 ? 0 : x === 1 ? 1 : pow(2, -10 * x) * sin((x * 10 - 0.75) * c4) + 1
  },
  easeInOutElastic: function (x) {
    return x === 0
      ? 0
      : x === 1
      ? 1
      : x < 0.5
      ? -(pow(2, 20 * x - 10) * sin((20 * x - 11.125) * c5)) / 2
      : (pow(2, -20 * x + 10) * sin((20 * x - 11.125) * c5)) / 2 + 1
  },
  easeInBounce: function (x) {
    return 1 - bounceOut(1 - x)
  },
  easeOutBounce: bounceOut,
  easeInOutBounce: function (x) {
    return x < 0.5 ? (1 - bounceOut(1 - 2 * x)) / 2 : (1 + bounceOut(2 * x - 1)) / 2
  },
} as const
