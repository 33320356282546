<template>
  <svg v-bind="$attrs" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" class="fill-current">
    <path
      d="M12.25 3.92583C12.2383 3.92583 12.2208 3.92583 12.2033 3.92583C9.1175 3.61667 6.0375 3.5 2.98666 3.80917L1.79666 3.92583C1.55166 3.94917 1.33583 3.77417 1.3125 3.52917C1.28916 3.28417 1.46416 3.07417 1.70333 3.05083L2.89333 2.93417C5.99666 2.61917 9.14083 2.74167 12.2908 3.05083C12.53 3.07417 12.705 3.29 12.6817 3.52917C12.6642 3.75667 12.4717 3.92583 12.25 3.92583Z"
    />
    <path
      d="M4.95833 3.33666C4.935 3.33666 4.91167 3.33666 4.8825 3.33083C4.64917 3.29 4.48583 3.0625 4.52667 2.82916L4.655 2.065C4.74833 1.505 4.87667 0.729164 6.23583 0.729164H7.76417C9.12917 0.729164 9.2575 1.53416 9.345 2.07083L9.47333 2.82916C9.51417 3.06833 9.35083 3.29583 9.1175 3.33083C8.87833 3.37166 8.65083 3.20833 8.61583 2.975L8.4875 2.21666C8.40583 1.70916 8.38833 1.61 7.77 1.61H6.24167C5.62333 1.61 5.61167 1.69166 5.52417 2.21083L5.39 2.96916C5.355 3.185 5.16833 3.33666 4.95833 3.33666Z"
    />
    <path
      d="M8.8725 13.2708H5.1275C3.09167 13.2708 3.01 12.145 2.94583 11.235L2.56667 5.36083C2.54917 5.12166 2.73583 4.91167 2.975 4.89417C3.22 4.8825 3.42417 5.06333 3.44167 5.3025L3.82083 11.1767C3.885 12.0633 3.90833 12.3958 5.1275 12.3958H8.8725C10.0975 12.3958 10.1208 12.0633 10.1792 11.1767L10.5583 5.3025C10.5758 5.06333 10.7858 4.8825 11.025 4.89417C11.2642 4.91167 11.4508 5.11583 11.4333 5.36083L11.0542 11.235C10.99 12.145 10.9083 13.2708 8.8725 13.2708Z"
    />
    <path
      d="M7.96833 10.0625H6.02583C5.78667 10.0625 5.58833 9.86417 5.58833 9.625C5.58833 9.38583 5.78667 9.1875 6.02583 9.1875H7.96833C8.2075 9.1875 8.40583 9.38583 8.40583 9.625C8.40583 9.86417 8.2075 10.0625 7.96833 10.0625Z"
    />
    <path
      d="M8.45833 7.72916H5.54166C5.3025 7.72916 5.10416 7.53083 5.10416 7.29166C5.10416 7.0525 5.3025 6.85416 5.54166 6.85416H8.45833C8.6975 6.85416 8.89583 7.0525 8.89583 7.29166C8.89583 7.53083 8.6975 7.72916 8.45833 7.72916Z"
    />
  </svg>
</template>
