<script setup lang="ts">
import { ref } from 'vue'
import RadioToggleButton from '@/components-v2/data-input/RadioToggleButton.vue'
import IconSaxDocumentUpload from '@/components/Icons/iconsax/IconSaxDocumentUpload.vue'
import StickerLibraryTab from '@/areas/editor/pages/stickers/StickerLibraryTab.vue'
import StickerUploadTab from '@/areas/editor/pages/stickers/StickerUploadTab.vue'

const tab = ref<'library' | 'upload'>('library')
</script>

<template>
  <div class="flex flex-col gap-4 md:gap-8 p-4 pb-0 2xl:p-8 h-full sm:h-screen overflow-y-auto">

    <header class="hidden md:block">
      <h2 class="text-xl font-semibold leading-snug">Social stickers</h2>
      <p class="font-light">
        Add social stickers to your clips and let your audience know where to find you.
      </p>
    </header>

    <div class="w-full min-w-0 overflow-x-auto overflow-y-hidden shrink-0 -my-4">
      <div class="flex flex-row gap-1">
        <RadioToggleButton v-model="tab" :value="'library'" class="flex-col relative font-light data-[state=active]:font-semibold shrink-0">
          <span class="absolute inset-auto">Library</span>
          <span class="font-semibold invisible">Library</span>
        </RadioToggleButton>
        <RadioToggleButton v-model="tab" :value="'upload'" class="flex-col relative font-light data-[state=active]:font-semibold shrink-0">
          <span class="absolute inset-auto flex gap-2 items-center">
            <IconSaxDocumentUpload class="w-4 h-4" />
            Upload image
          </span>
          <span class="font-semibold invisible flex gap-2 items-center">
            <IconSaxDocumentUpload class="w-4 h-4" />
            Upload image
          </span>
        </RadioToggleButton>
      </div>
    </div>

    <div class="relative min-h-0 flex-1">
      <Transition
        :enter-from-class="tab === 'library' ? 'opacity-0 -translate-x-6' : 'opacity-0 translate-x-6'"
        :leave-to-class="tab === 'library' ? 'opacity-0 translate-x-6' : 'opacity-0 -translate-x-6'"
        enter-active-class="motion-safe:transition-[transform,_opacity] duration-1000"
        leave-active-class="motion-safe:transition-[transform,_opacity] duration-1000"
      >
        <div class="absolute inset-0" :key="tab">
          <StickerLibraryTab v-model:tab="tab" v-show="tab === 'library'" />
          <StickerUploadTab v-model:tab="tab" v-show="tab === 'upload'" />
        </div>
      </Transition>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
