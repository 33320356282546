import { computed, reactive, watch, type MaybeRefOrGetter, toValue } from 'vue'
import { sumBy, noop } from 'lodash-es'
import { metadataService } from '@/services/metadataService'
import unwrap from '@/helpers/unwrap'
import { v4 as uuid } from 'uuid'
import { useLocalFileValidator } from '@/Hooks/clip-form/useLocalFileValidator'
import { canGuard } from '@/Hooks/useGuard'
import { useUserInfoStore, onLoggedInAsync, onLoggedIn } from '@/store/user/userInfo'
import { retryAsync } from '@/helpers/retry'
import axios, { CanceledError } from 'axios'
import { useGetUploadedVideosQuery, deleteUploadedVideo } from '@/components/Dialog/MultiUploadDialog/file-uploads/useUploadedVideos'
import type { Upload } from '@/components/Dialog/MultiUploadDialog/file-uploads/Upload'
import { getFileFromStorage, saveFileInStorage, removeFileFromStorage } from '@/components/Dialog/MultiUploadDialog/file-uploads/_storage'
import { startFileUpload, createFileUpload } from '@/components/Dialog/MultiUploadDialog/file-uploads/_api'
import * as Sentry from '@sentry/vue'
import { batchAsync } from '@/helpers/batchAsync'
import logging from '@/logging'

const uploadsById = reactive<Record<string, Upload>>({})
const uploads = computed(() => unwrap.values(uploadsById))
const ids = computed(() => unwrap.keys(uploadsById))

/**
 * File upload state is intentionally global, because we want to be able to start and resume uploads from anywhere in the
 * application. Even if the user were to upload multiple files at once, we want to be able to manage them all in one place
 * and pick the correct one to use in the Editor while still uploading the rest in the background.
 */
export function useFileUploads() {

  function selectById(id: MaybeRefOrGetter<string>) {
    return computed(() => uploadsById[toValue(id)])
  }

  function find(predicate: (upload: Upload) => boolean) {
    return computed(() => uploads.value.find(predicate))
  }

  function where(predicate: (upload: Upload) => boolean) {
    return computed(() => uploads.value.filter(predicate))
  }
  
  function idsWhereStatusIn(statuses?: Upload['status'][]) {
    return computed(() => {
      if (!statuses) {
        return ids.value
      }
      return uploads.value.filter(u => statuses.includes(u.status)).map(u => u.id)
    })
  }

  function any(predicate: (upload: Upload) => boolean) {
    return computed(() => uploads.value.some(predicate))
  }

  function all(predicate: (upload: Upload) => boolean) {
    return computed(() => uploads.value.every(predicate))
  }

  const allUploadsFailed = all(u => u.status === 'error')
  const anyUploadFinished = any(u => u.status === 'finished')
  const anyUploadInProgress = any(u => u.status === 'in-progress' || u.status === 'pending')
  const validUploads = where(u =>  u.status !== 'error')

  const overallProgress = computed(() => {
    if (uploads.value.length === 0) {
      return 0
    }
    const progress = sumBy(uploads.value, 'progress') / uploads.value.length
    return Math.round(progress)
  })

  return reactive({
    ids: ids,
    uploads: uploads,
    state: uploadsById,

    add: add,
    resume: resume,
    start: startUploadingWhenUploadIsValid,
    remove: remove,
    cancel: cancel,
    batchCancel: batchCancel,
    batchRemove: batchRemove,
    deleteFromServer: deleteFromServer,

    find: find,
    where: where,
    any: any,
    all: all,

    overallProgress: overallProgress,

    allUploadsFailed: allUploadsFailed,
    anyUploadFinished: anyUploadFinished,
    validUploads: validUploads,
    anyUploadInProgress: anyUploadInProgress,

    selectById: selectById,
    idsWhereStatusIn: idsWhereStatusIn,
  })
}

/**
 * Creates a new upload object and adds it to the list of active uploads. This function is meant as a helper function for
 * the `add` and `resume` functions. It will create a new upload object with the given ID and file, and add it to the list
 * of active uploads. As well as any additional payload data that is passed in.
 */
function create(id: string, file: File, payload: Partial<Omit<Upload, 'file' | 'id'>> = {}) {
  uploadsById[id] = {
    id: id,
    file: file,
    progress: 0,
    status: 'pending',
    isDupe: false,
    tokens: {
      video: axios.CancelToken.source(),
      thumbnail: axios.CancelToken.source(),
    },
    dispose: noop,
    suspense: () => new Promise((resolve) => resolve),
    ...payload,
  }
}

function userCanUseThisUpload(validationResult: Awaited<ReturnType<ReturnType<typeof useLocalFileValidator>>> | undefined) {
  return Boolean(validationResult
    && !validationResult.error
    && !validationResult.requiresAuth
    && !(validationResult.guard && !canGuard(validationResult.guard)))
}

async function prepareUpload(id: string, file: File) {

  saveFileInStorage(id, file).catch(Sentry.captureException)

  const upload = uploadsById[id]

  if (!upload) {
    // Sentry.captureException(new Error('File preparation was requested before upload request was created.'))
    return
  }

  const validateLocalFile = useLocalFileValidator()
  
  validateLocalFile(file).then((result) => {
    if (uploadsById[id]) {
      uploadsById[id].validationResult = result
      uploadsById[id].status = userCanUseThisUpload(result) ? 'pending' : 'invalid'
    }
  })

  metadataService.fetchVideoFileMeta(upload.file).then((meta) => {
    if (uploadsById[id]) {
      uploadsById[id].fileMeta = meta
    }
  }) 
}

function updateValidationResultOnLoggedIn(id: string) {
  onLoggedIn(async () => {
    if (uploadsById[id] && uploadsById[id].status === 'invalid') {
      const validateLocalFile = useLocalFileValidator()
      uploadsById[id].validationResult = await validateLocalFile(uploadsById[id].file)
      uploadsById[id].status = userCanUseThisUpload(uploadsById[id].validationResult)
        ? 'pending'
        : 'invalid'
    }
  })
}

/**
 * Adds a video file to the list of active uploads. This function is meant to be called when the user selects a new video
 * file to upload. It will add the file to the list of active uploads and start the upload process.
 */
async function add(file: File) {

  const duplicate = uploads.value.find((u => metadataService.isSameMetaData(file, u.file)))
  if (duplicate) {
    uploadsById[duplicate.id].isDupe = true
    return
  }

  const id = uuid()
  create(id, file)

  const userInfoStore = useUserInfoStore()
  
  const promise = async () => {
    // If the user is logged in, we can try to request the signed upload URLs. If the user is not logged in, we can't
    // request the signed upload URLs because the endpoints require authentication.
    if (userInfoStore.isLoggedIn) {
      await setUrls(id, file)
    }

    await prepareUpload(id, file)
    
    if (!userInfoStore.isLoggedIn) {
      updateValidationResultOnLoggedIn(id)
    }
 
    // If the signed upload URLs are not yet set, the user was probably not logged in yet. We need to wait for the user to
    // be logged in before we can request the signed upload URLs, because the endpoints require authentication.
    if (!uploadsById[id].urls) {
      await onLoggedInAsync()
      await setUrls(id, file)
    }

    await startUploadingWhenUploadIsValid(id, file).catch((reason) => {
      throw new Error(`Failed to start upload: ${reason}`)
    })
  }
  
  const suspense = wrapSuspense(id, promise())
  uploadsById[id].suspense = () => suspense
}

function wrapSuspense(id: string, promise: Promise<void>): Promise<void> {
  return promise
    .then(() => console.log('Upload complete'))
    .catch((reason) => {
      Sentry.captureException(reason)
      if (uploadsById[id]) {
        uploadsById[id].status = 'error'
        uploadsById[id].error = reason
        }
    })
}

/**
 * Starts the upload process for a video file. This function is meant to be called when the user starts uploading a new
 * video file or resumes an upload that was previously started but not finished. It will validate the file and start the
 * upload process, updating the upload progress and status accordingly.
 */
async function startUploadingWhenUploadIsValid(id: string, file: File) {

  await new Promise((resolve, reject) => {
    
    const userInfoStore = useUserInfoStore()

    // The watch function returns a function that can be called to stop watching the reactive values. This is useful
    // for cleaning up the watchers when the upload is finished or cancelled. This function is invoked when the upload
    // is disposed of.

    const stopWatchingValidationResult = watch([
      () => uploadsById[id].validationResult,
      () => userInfoStore.tier,
    ], ([validationResult]) => {

      // Check if the upload is valid for the current user. If the upload is valid, start the upload process and update
      // the upload progress and status accordingly.

      if (userCanUseThisUpload(validationResult)) {
        manageUploadProgress(id, file).then(resolve).catch(reject)
      }
    }, {
      deep: true,
      immediate: true,
    })
    
    if (uploadsById[id]) {
      uploadsById[id].dispose = (message) => {
        stopWatchingValidationResult()
        uploadsById[id].fileMeta?.dispose()
        uploadsById[id].tokens.thumbnail?.cancel(message)
        uploadsById[id].tokens.video?.cancel(message)
      }
    }
  }).catch((reason) => {
    Sentry.captureException(reason)
    if (uploadsById[id]) {
      uploadsById[id].status = 'error'
      uploadsById[id].error = reason
    }
  })
}

/**
 * Resumes an upload that was previously started but not finished. This function is meant to be called when the user
 * navigates away from the page and then returns to it. It will attempt to resume the upload and update the upload
 * progress and status accordingly.
 */
async function resume(id: string) {

  const file = await getFileFromStorage(id)
  if (!file) {
    throw new Error('File not found in storage')
  }
  
  if (uploadsById[id]) {
    return
  }

  create(id, file, { status: 'pending' })
  const userInfoStore = useUserInfoStore()

  const promise = async () => {
    // If the user is logged in, we can try to resume the upload. If the user is not logged in, we can't resume the upload
    // because the endpoints require authentication.
    if (userInfoStore.isLoggedIn) {
      await setUrls(id, file)
    }

    await prepareUpload(id, file)

    if (!userInfoStore.isLoggedIn) {
      updateValidationResultOnLoggedIn(id)
    }

    // Wait for the user to be logged in before resuming the upload, because the endpoints require authentication.
    if (!uploadsById[id].urls) {
      await onLoggedInAsync()
      await setUrls(id, file)
    }
  
    if (!uploadsById[id].urls) {
      uploadsById[id].status = 'error'
      uploadsById[id].error = new Error('Failed to resume upload, please try uploading a different clip.')
      return
    }
  
    if (await metadataService.canPlayUrl(uploadsById[id].urls!.videoSignedUploadRequest.resultUrl!)) {
      await remove(id, 'Upload was already finished.')
      return
    }
  
    await startUploadingWhenUploadIsValid(id, file).catch((reason) => {
      throw new Error(`Failed to resume upload: ${reason}`)
    })
  }

  const suspense = wrapSuspense(id, promise())
  uploadsById[id].suspense = () => suspense
}

async function setUrls(id: string, file: File) {
  const uploadUrls = await createFileUpload(id, file)
  if (!uploadsById[id]) return
  if (uploadUrls.error) {
    uploadsById[id].status = 'error'
    uploadsById[id].error = uploadUrls.error
  } else {
    uploadsById[id].urls = uploadUrls.data
  }
}

/**
 * Manages the upload progress of a video file. This function is meant to be called after the upload has been started
 * and the signed upload requests have been fetched. It will upload the video and thumbnail files to the server and
 * update the upload progress and status accordingly.
 */
async function manageUploadProgress(id: string, file: File) {

  if (!uploadsById[id]) {
    return
  }

  if (!uploadsById[id].urls) {
    
    const uploadUrls = await createFileUpload(id, file)

    if ('error' in uploadUrls && uploadUrls.error) {
      if (uploadsById[id]) {
        uploadsById[id].status = 'error'
        uploadsById[id].error = uploadUrls.error
      }
      Sentry.captureException(uploadUrls.error)
      return
    }

    uploadsById[id].urls = uploadUrls.data
  }
  
  if (!uploadsById[id].fileMeta) {
    
    const meta = await metadataService.fetchVideoFileMeta(uploadsById[id].file)
    if (!uploadsById[id]) return

    if (!meta) {
      uploadsById[id].status = 'error'
      uploadsById[id].error = new Error('Failed to fetch metadata for the video file, please try again.')
      return
    }

    uploadsById[id].fileMeta = meta
  }
  
  if (!uploadsById[id]) return

  uploadsById[id].progress = 0
  uploadsById[id].status = 'in-progress'
  delete uploadsById[id].error
  
  const videoRequest = uploadsById[id].urls!.videoSignedUploadRequest
  const video = startFileUpload(videoRequest, uploadsById[id].file, uploadsById[id].tokens!.video, {
    onProgress(progress) {
      if (uploadsById[id]) {
        uploadsById[id].progress = progress
        uploadsById[id].status = 'in-progress'
      }
    },
  })

  const { blob, fileName } = uploadsById[id].fileMeta!.thumbnail
  const thumbnail = new File([blob], fileName + '.jpeg', { type: 'image/jpeg' })
  const thumbnailRequest = uploadsById[id].urls!.thumbnailSignedUploadRequest
  await startFileUpload(thumbnailRequest, thumbnail, uploadsById[id].tokens!.thumbnail)

  await video
    .then(async () => {

      try {
        await removeFileFromStorage(id)
      } catch (e) {
        Sentry.captureException(e)
      }

      if (!uploadsById[id]) return

      uploadsById[id].status = 'finished'
      await useGetUploadedVideosQuery().refetch()
      
      logging.trackEvent('File upload finished', {
        fileSize: file.size,
        fileType: file.type,
        fileName: file.name,
      })
    })
    .catch((reason: Error) => {

      console.error({
        fileSize: file.size,
        fileType: file.type,
        fileName: file.name,
        error: reason.message,
      })

      if (!(reason instanceof CanceledError)) {
        Sentry.captureException(reason)
      }

      if (uploadsById[id]) {
        uploadsById[id].progress = null
        uploadsById[id].status = 'error'
        uploadsById[id].error = reason
      }

      deleteFromServer(id, 'File upload failed')
    })
}

/**
 * Cancels the upload and deletes the uploaded video from the server. Meant for stopping and removing active or broken
 * uploads.
 */
async function cancel(id: string, message = 'Upload was manually cancelled.') {

  const upload = uploadsById[id]

  if (!upload) {
    Sentry.captureException(new Error('File cancel requested before upload request was created.'))
    return
  }

  upload.dispose(message)
  
  delete uploadsById[id]
  await deleteFromServer(id, message)
}

/**
 * Removes the upload from the list of active uploads. Meant for removing finished uploads and cleaning up UI state.
 */
async function remove(id: string, message = 'Upload was manually cancelled.') {

  const upload = uploadsById[id]

  if (!upload) {
    Sentry.captureException(new Error('File remove requested before upload request was created.'))
    return
  }

  if (upload.status === 'error' || upload.status === 'invalid') {
    await cancel(id, message)
  } else {
    upload.dispose(message)
    delete uploadsById[id]
  }
}

async function deleteFromServer(id: string, reason: string) {
  const userInfoStore = useUserInfoStore()
  if (userInfoStore.isLoggedIn) {
    await retryAsync(async () => deleteUploadedVideo(id))
      .catch((reason) => {
        console.error(`Failed to delete uploaded video`)
        Sentry.captureException(reason)
      })
  }
}

/**
 * Cancels all uploads and deletes the uploaded videos from the server. Meant for stopping and removing all active or
 * broken uploads. Example usage: when the user leaves the page.
 */
async function batchCancel(statuses?: Upload['status'][], message = 'Uploads were manually cancelled.') {

  const fileUploads = useFileUploads()
  const ids = fileUploads.idsWhereStatusIn(statuses)
  await batchAsync(ids.value, (id: string) => cancel(id, message))
  
  if (ids.value.length > 0) {
    logging.trackEvent('File uploads cancelled', {
      statuses: statuses,
      quantity: ids.value.length,
      message: message
    })
  }
}

/**
 * Removes all finished uploads from the list of active uploads. Meant for cleaning up UI state. For example when the
 * MultiUploadDialog is closed.
 */
async function batchRemove(statuses?: Upload['status'][], message = 'Uploads were manually removed.') {
  
  const fileUploads = useFileUploads()
  const ids = fileUploads.idsWhereStatusIn(statuses).value
  await batchAsync(ids, (id: string) => remove(id, message))

  if (ids.length > 0) {
    logging.trackEvent('File uploads removed', {
      statuses: statuses,
      quantity: ids.length,
      message: message
    })
  }
}
