<script lang="ts" setup>
import axios from 'axios'
import * as Sentry from '@sentry/vue'
import type { LottiePlayer, AnimationItem, AnimationConfigWithData } from 'lottie-web'
import { onMounted, ref, nextTick, onBeforeUnmount } from 'vue'

const props = withDefaults(defineProps<{
  url: string
  loop?: boolean
  autoPlay?: boolean
  speed?: number
  width?: string
  height?: string
  marginBottom?: string
}>(), {
  loop: true,
  autoPlay: true,
  speed: 1,
  width: '100%',
  height: '100%',
  marginBottom: '0',
})

const emit = defineEmits<{
  (name: 'loopComplete', instance: AnimationItem | null): void
  (name: 'complete', instance: AnimationItem | null): void
  (name: 'enterFrame', instance: AnimationItem | null): void
}>()

defineExpose({
  play: () => instance.value?.play(),
  pause: () => instance.value?.pause(),
  stop: () => instance.value?.stop(),
  setSpeed: (speed: number) => instance.value?.setSpeed(speed),
  goToAndStop: (value: number, isFrame: boolean) => instance.value?.goToAndStop(value, isFrame),
  goToAndPlay: (value: number, isFrame: boolean) => instance.value?.goToAndPlay(value, isFrame),
})

const container = ref<HTMLDivElement | null>(null)
const instance = ref<AnimationItem | null>(null)

onMounted(async () => {

  const lottie = await tryLoadLottie()
  if (!lottie || !container.value) {
    return
  }

  const animation = await fetchAnimationJson()
  const settings: AnimationConfigWithData = {
    container: container.value,
    loop: props.loop,
    autoplay: props.autoPlay,
    animationData: animation,
  }

  instance.value = lottie.loadAnimation(settings)

  instance.value.addEventListener('loopComplete', () => {
    emit('loopComplete', instance.value)
  })

  instance.value.addEventListener('complete', () => {
    emit('complete', instance.value)
  })

  instance.value.addEventListener('enterFrame', () => {
    emit('enterFrame', instance.value)
  })
})

onBeforeUnmount(() => {
  instance.value?.destroy()
})

async function tryLoadLottie(): Promise<LottiePlayer | null> {
  for (let i = 0; i < 3; i++) {
    try {

      const lottie = await import('lottie-web').then((module) => {
        if (module && typeof module === 'object' && 'default' in module) {
          return module
        } else {
          const error = new Error(`Module does not have a default export '${module ? JSON.stringify(module) : module}'`)
          Sentry.captureException(error)
          throw error
        }
      })

      return lottie.default
    } catch (e) {
      console.warn(e)
      await new Promise((resolve) => setTimeout(resolve, 10_000 * Math.pow(2, i)))
    }
  }

  return null;
}

async function fetchAnimationJson(): Promise<Record<string, string>> {
  const response = await axios.get(props.url, { withCredentials: false })
  return response.data
}
</script>

<template>
  <div :style="`height: calc(${height} - ${marginBottom})`">
    <div ref="container" :style="`width: ${width}; height: ${height}`"></div>
  </div>
</template>
