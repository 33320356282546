<script setup lang="ts">
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { ref, onMounted } from 'vue'
import { useMutation } from '@tanstack/vue-query'
import { useClipboard } from '@vueuse/core'
import { SelectValue, SelectGroup, SelectContent, Select, SelectTrigger, SelectItem } from '@/components/ui/select'
import MegaphoneIcon from '@/components/Icons/MegaphoneIcon.vue'
import { Button } from '@/components/ui/button'
import ErrorIcon from '@/components/Dialog/Icons/ErrorIcon.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import IconSaxMagicStar from '@/components/Icons/iconsax/IconSaxMagicStar.vue'
import { useGenerateCaptions } from '@/areas/editor/pages/captions/useGenerateCaptions'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { AlertDialogTitle, AlertDialogDescription } from '@/components/ui/alert-dialog'

const emit = defineEmits<{ (event: 'done'): void }>()

const editorClipInfoStore = useEditorClipInfoStore()
const historyStore = useHistoryStore()
const { supportedLocales, generateCaptionsAsync, captionsLanguageCode, shouldTrimBeforeGenerating, isOverDurationLimit }
  = useGenerateCaptions()

const mustTrim = ref(isOverDurationLimit.value)
onMounted(() => {
  mustTrim.value = isOverDurationLimit.value
})

const { mutateAsync: generateCaptions, isPending: isGenerating, error } = useMutation({ 
  mutationFn: async () => {
    await generateCaptionsAsync()
    await historyStore.push()
    emit('done')
  } 
})

const { copy, copied } = useClipboard()
function copyErrorMessage() {
  if (!error.value) return
  copy(`${error.value.message}\nProject ID: ${editorClipInfoStore.id}`)
}
</script>

<template>
  <Transition>
    <div class="flex flex-col gap-6" :key="mustTrim ? 'trim' : error ? 'error' : 'generating'">
      <template v-if="mustTrim">
    
        <LottieAnimation :loop="false" class="mx-auto w-24 h-24" url="/lottie/error-alt.json" />
    
        <AlertDialogTitle class="text-xl font-bold">Oops! Video too long 🎥</AlertDialogTitle>
        <AlertDialogDescription>
          Your clip exceeds the 3-minute limit for caption generation. Please upload a shorter video or trim your
          current one to fit within the time frame.
        </AlertDialogDescription>
    
        <div class="mt-8 flex w-full flex-col items-stretch justify-center gap-4 md:flex-row md:items-center">
          <Button variant="gradient" @click="mustTrim = shouldTrimBeforeGenerating; emit('done')">
            Try again
          </Button>
        </div>
      </template>
    
      <template v-else-if="error">
        <header class="flex gap-4 items-center flex-wrap">
          <ErrorIcon />
          <div>
            <AlertDialogTitle>Error while generating captions 😔</AlertDialogTitle>
            <AlertDialogDescription class="text-black/50">Unfortunately an error has occurred</AlertDialogDescription>
          </div>
        </header>
    
        <p class="mb-0 mt-2">
          Please report this issue in our support channel in discord together with your video/twitch-clip and the following
          message:
        </p>
    
        <code class="mb-0 mt-2 flex gap-2 rounded bg-zinc-100 p-4 text-sm">
          <span class="flex flex-col">
            <span>{{ error.message }}</span>
            <span>Project ID: {{ editorClipInfoStore.id }}</span>
          </span>
        </code>
    
        <div class="flex gap-2">
          <Button class="flex-1" variant="depressed" @click="emit('done')">
            Close
          </button>
          <Button class="flex-1" :variant="copied ? 'ghost' : 'primary'" @click="copyErrorMessage">
            <template v-if="copied">Copied!</template>
            <template v-else>Copy error message</template>
          </Button>
        </div>
      </template>
    
      <template v-else>
        <div v-if="!isGenerating" class="text-left">
          <AlertDialogTitle class="text-lg font-bold">What language is being spoken in the clip?</AlertDialogTitle>
          <div class="mt-4 flex items-center gap-2">
            <AlertDialogDescription class="whitespace-nowrap">Spoken language:</AlertDialogDescription>
    
            <Select v-model="captionsLanguageCode">
              <SelectTrigger class="">
                <SelectValue placeholder="Select an option" />
              </SelectTrigger>
              <SelectContent class="z-[1000]">
                <SelectGroup>
                  <SelectItem v-for="locale of supportedLocales" :key="locale.code" :value="locale.code">
                    {{ locale.label }}
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
    
          <div v-if="captionsLanguageCode !== 'en_us'" class="flex justify-center pt-4">
            <div class="flex items-center space-x-2 rounded-lg bg-blue-100 px-4 py-2 text-sm text-company-primary">
              <MegaphoneIcon height="17" width="16" />
    
              <span>With this language, long clip captions may run out of sync with the audio</span>
            </div>
          </div>
    
          <div class="flex items-center justify-between gap-2 pt-10">
            <Button variant="depressed" @click="emit('done')">
              Close
            </button>
            <Button variant="primary" @click="generateCaptions" class="ml-auto">
              <template v-if="isGenerating">
                <div class="w-4 h-4 border-2 border-t-transparent border-current rounded-full animate-spin" />
                Generating...
              </template>
              <template v-else>
                <IconSaxMagicStar class="w-4 h-4 -scale-x-100" />
                Generate
              </template>
            </Button>
          </div>
        </div>
    
        <div v-else class="text-center">
          <LottieAnimation class="-mt-16" height="300px" marginBottom="90px" url="/lottie/magician-wand.json" />
          <p>
            <strong>Making magic</strong>
          </p>
          <p class="mb-0 mt-2">
            This process might take up to<br />
            20 seconds depending on the<br />
            length of your clip
          </p>
          <LottieAnimation height="100px" url="/lottie/purple-spinner.json" />
        </div>
      </template>
    </div>
  </Transition>
</template>

<style scoped lang="scss">

</style>
