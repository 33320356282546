import { defineStore } from 'pinia'
import { useEntityStore } from './useEntityStore'
import type { Layout } from '@/areas/editor/@type/Project'

export const useLayoutsStore = defineStore('layouts', () => {

  const { state, ids, entities, operations }
    = useEntityStore<Layout>()

  return {

    state: state,
    ids: ids,
    entities: entities,

    ...operations,
  }
})
