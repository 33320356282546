export const tiers = Object.freeze({
  SILVER: 10,
  GOLD: 100,
  FREE: 0,
  toString: (num: number) => {
    switch (num) {
      case tiers.GOLD:
        return 'gold'
      case tiers.SILVER:
        return 'silver'
      default:
      case tiers.FREE:
        return 'free'
    }
  },
  fromString: (str: string) => {
    switch (str.toLowerCase()) {
      case 'gold':
        return tiers.GOLD
      case 'silver':
        return tiers.SILVER
      default:
      case 'free':
        return tiers.FREE
    }
  },
})
