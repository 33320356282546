import type { Sticker } from '@/components/Stickers/stickerLibrary/stickerLibrary'
import GenericA from '@/components/Stickers/stickerLibrary/components/GenericA.vue'
import GenericSquare from '@/components/Stickers/stickerLibrary/components/GenericSquare.vue'
import GenericRound from '@/components/Stickers/stickerLibrary/components/GenericRound.vue'
import GenericSquareRounded from '@/components/Stickers/stickerLibrary/components/GenericSquareRounded.vue'
import GenericRoundedFollow from '@/components/Stickers/stickerLibrary/components/GenericRoundedFollow.vue'
import GenericSquareLive from '@/components/Stickers/stickerLibrary/components/GenericSquareLive.vue'
import GenericRounded from '@/components/Stickers/stickerLibrary/components/GenericRounded.vue'
import GenericRainbow from '@/components/Stickers/stickerLibrary/components/GenericRainbow.vue'
import GenericTranslucent from '@/components/Stickers/stickerLibrary/components/GenericTranslucent.vue'
import GenericB from '@/components/Stickers/stickerLibrary/components/GenericB.vue'
import GenericC from '@/components/Stickers/stickerLibrary/components/GenericC.vue'
import GenericD from '@/components/Stickers/stickerLibrary/components/GenericD.vue'
import { markRaw } from 'vue'

export const twitchStickers = [
  {
    key: 'twitch-generic-square',
    component: markRaw(GenericSquare),
    tags: ['twitch'],
    icon: 'twitch',
    colors: {
      dark: '#000000',
      color: '#9146FF',
      light: '#ffffff',
    },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'twitch-generic-round',
    component: markRaw(GenericRound),
    tags: ['twitch'],
    icon: 'twitch',
    colors: {
      dark: '#000000',
      color: '#9146FF',
      light: '#ffffff',
    },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'twitch-generic-square-rounded',
    component: markRaw(GenericSquareRounded),
    tags: ['twitch'],
    icon: 'twitch',
    colors: {
      dark: '#000000',
      color: '#9146FF',
      light: '#ffffff',
    },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'twitch-generic-rounded-follow',
    component: markRaw(GenericRoundedFollow),
    tags: ['twitch'],
    icon: 'twitch',
    colors: {
      dark: '#000000',
      light: '#ffffff',
    },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'twitch-generic-square-live',
    component: markRaw(GenericSquareLive),
    tags: ['twitch'],
    icon: 'twitch',
    colors: {
      // dark: '#000000',
      color: '#9146FF',
      light: '#ffffff',
    },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'twitch-generic-rounded',
    component: markRaw(GenericRounded),
    tags: ['twitch'],
    icon: 'twitch',
    colors: {
      dark: '#000000',
      color: '#9146FF',
      light: '#ffffff',
    },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'twitch-generic-rainbow',
    component: markRaw(GenericRainbow),
    tags: ['twitch'],
    icon: 'twitch',
    colors: {
      dark: '#9146ff',
      color: 'rgba(185, 159, 228, 1)',
      light: 'rgba(255, 131, 83, 1)',
    },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'twitch-generic-translucent',
    component: markRaw(GenericTranslucent),
    tags: ['twitch'],
    icon: 'twitch',
    colors: { dark: '#000000', twitch: '#9146ff' },
    createdAt: new Date(2023, 9, 1),
  },
  {
    key: 'twitch-generic-a',
    component: markRaw(GenericA),
    tags: ['twitch'],
    icon: 'twitch',
    colors: {
      blue: '#37C2FE',
      red: '#DA0062',
      green: '#0FA688',
      // rainbow: RAINBOW_GRADIENT
    },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'twitch-generic-b',
    component: markRaw(GenericB),
    icon: 'twitch',
    tags: ['twitch'],
    colors: { black: '#000', blue: '#223ECF', red: '#ff0000' },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'twitch-generic-c',
    component: markRaw(GenericC),
    icon: 'twitch',
    tags: ['twitch'],
    colors: { purple: '#a236fe', orange: '#ff9500', pink: '#de21c8', green: '#42b25a' },
    createdAt: new Date(1970, 0, 1),
  },

  {
    key: 'twitch-generic-d',
    component: markRaw(GenericD),
    icon: 'twitch',
    tags: ['twitch'],
    colors: { purple: '#9D38FE', aqua: '#1BBDF2', black: '#000' },
    createdAt: new Date(1970, 0, 1),
  },
] as const satisfies Readonly<Sticker[]>
