import { metadataService } from '@/services/metadataService'
import { retryAsync } from '@/helpers/retry'
import { streamLadderAxios } from '@/services/axios'
import { bytesToMb } from '@/helpers/fileSize'
import type { AxiosResponse, CancelTokenSource } from 'axios'
import type { CreateUploadedVideoResponseDto, S3RequestUploadResult } from '@/apis/streamladder-api/model'
import type { DeepNonNullable, DeepRequired } from 'ts-essentials'
import { noop } from 'lodash-es'
import uploadService from '@/services/uploadService'

export async function createFileUpload(id: string, file: File): Promise<UrlsForFileUploadResponse> {

  const duration = await metadataService.fetchVideoFileDuration(file)
  if (!duration) {
    return { data: null, error: new Error(`File could not be opened. Please ensure the video is encoded properly.`) }
  }

  const name = file.name.split('.')
  const fileExtension = name.pop()

  return await handleRetriesAndErrors(() => {
    return streamLadderAxios.post(`api/UploadedVideos/${id}`, {
      name: name.join('.'),
      fileExtension: fileExtension,
      fileSizeMb: bytesToMb(file.size),
      videoDurationSeconds: duration,
    })
  }).catch((reason) => {
    const error = new Error('Failed to start upload, please check your internet connection and try again.')
    console.error(reason)
    // Sentry.captureException(error)
    return { data: null, error: error }
  })
}

/**
 * Used to create comprehensive and consistent responses for the file uploader to digest.
 */
async function handleRetriesAndErrors(
  request: () => Promise<AxiosResponse<CreateUploadedVideoResponseDto>>
): Promise<UrlsForFileUploadResponse>{
  return await retryAsync(async () => {
    const response = await request()
    return { data: response.data as DeepNonNullable<DeepRequired<CreateUploadedVideoResponseDto>>, error: null }
  }).catch((reason) => {
    const error = new Error('Failed to start upload, please check your internet connection and try again.')
    console.error(reason)
    // Sentry.captureException(error)
    return { data: null, error: error }
  })
}

type UrlsForFileUploadResponse =
  | { data: DeepNonNullable<DeepRequired<CreateUploadedVideoResponseDto>>, error: null }
  | { data: null, error: Error }

export async function startFileUpload(
  result: S3RequestUploadResult,  
  file: File, 
  cancelSource: CancelTokenSource,
  options?: { onProgress: (progress: number) => void }
) {
  await retryAsync(() => uploadService.uploadFileS3(
    result.signedUrl!, file, options?.onProgress ?? noop, 
    file.type, file.name, { cancelToken: cancelSource.token })
  )
}
