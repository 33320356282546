export default {
  quotes: [
    'Hey there, gorgeous',
    'Unbothered. Moisturized. Happy. In my lane. Flourishing',
    'Lookin’ good, feelin’ gorgeous',
    'New look, new me ✨',
    'You look beautiful today!',
  ],
  randomQuote() {
    const randomIndex = Math.floor(Math.random() * this.quotes.length)
    return this.quotes[randomIndex]
  }
} as const
