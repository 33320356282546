import { contain } from '@/modules/SLMovable/helpers/fit'
import type { Cropper } from '@/apis/streamladder-api/model'
import { shapes } from '@/modules/CustomLayouts/@data/shapes'
import { LayoutData } from '@/data/layoutData'

export function calculateRemainingAspectRatio(width: number, height: number) {
  const taken = contain({ width, height }, { width: 9, height: 16 })
  return 9 / (16 - taken.height)
}

export function calculateFeedSize(width: number, height: number) {

  const outputWidth = 1080
  const outputHeight = 1920

  const feedSize = contain({ width, height }, { width: outputWidth, height: outputHeight })
  return {
    width: feedSize.width / outputWidth,
    height: feedSize.height / outputHeight
  }
}

export function findShapeOf(cropper: Cropper) {
  const shapeFromPrefix = findShapeFromPrefix(cropper.cropperKey)
  if (cropper.cropperKey === 'circlefacecam_fc') {
    return 'circle'
  } else if (shapeFromPrefix) {
    return shapeFromPrefix
  } else {
    return findShapeByAspectRatio(cropper.data.ratio!)
  }
}

function findShapeFromPrefix(id: string) {
  return shapes.find((shape) => id.startsWith(shape.value + ':'))?.value
}

export function findNameFromId(id: string) {
  const parts = id.split(':')
  if (parts.length !== 3) {
    return id.endsWith('_fc') ? 'Facecam' : 'Gameplay'
  } else {
    return parts[1]
  }
}

export function findZIndexById(id: string) {
  for (const layout of Object.values(LayoutData)) {
    for (const fragment of layout.baseFragments) {
      if (fragment.key === id) {
        return fragment.zIndex
      }
    }
  }
}

export function findShapeByAspectRatio(aspectRatio: number) {
  switch (aspectRatio) {
    case 16 / 9:
      return '16:9'
    case 9 / 16:
      return '9:16'
    case 4 / 3:
      return '4:3'
    default:
      return 'freeform'
  }
}

export const baseColors = {
  facecamColor: "rgb(170,0,255)",
  gameplayColor: "rgb(0,170,255)",
  thirdCropColor: "rgb(255,0,170)",
}
