import type { CaptionStyleDefinition } from '@/components/Captions/captionTypes'
import { getCurrentCampaign } from '@/data/campaigns'
import { captionBaseStylesSettings } from '@/components/Captions/styles/captionStyles'
import { halloweenCaptionStyles } from '@/components/Captions/styles/seasonal/halloweenCaptionStyles'
import { christmasCaptionStyles } from '@/components/Captions/styles/seasonal/christmasCaptionStyles'
import unwrap from '@/helpers/unwrap'
import { uniq } from 'lodash-es'

const campaign = getCurrentCampaign()

export const captionStylesSettings = {
  ...captionBaseStylesSettings,
  ...enhanceWithSeasonalTag(halloweenCaptionStyles),
  ...enhanceWithSeasonalTag(christmasCaptionStyles),
} as const

export const captionStyleSettingsArray = Object.entries(captionStylesSettings)
  .map(([key, value]) => ({
    ...value,
    type: key,
  }))
  .sort((a, b) => {
    if (a.tags?.includes(campaign?.id)) return -1
    return 0
  }) as CaptionStyleSettings[]

for (const style of captionStyleSettingsArray) {
  const colors = [] as string[]
  for (const color of style.colors.map(c => c.toUpperCase())) {
    if (!colors.includes(color)) {
      colors.push(color)
    } else {
      console.error(`Duplicate color ${color} in style ${style.type}`)
    }
  }
}

export type CaptionStyle = keyof typeof captionStylesSettings
export const captionStyles = Object.keys(captionStylesSettings) as CaptionStyle[]

export type CaptionStyleSettings = CaptionStyleDefinition & { type: CaptionStyle }

function enhanceWithSeasonalTag(styles: Record<string, CaptionStyleDefinition>) {
  const result: Record<string, CaptionStyleDefinition> = {}
  for (const [key, style] of unwrap.entries(styles)) {
    if (style.tags?.includes(campaign?.id)) {
      result[key] = {
        ...style,
        tags: uniq([...(style.tags ?? []), 'seasonal']),
      }
    } else {
      result[key] = {
        ...style,
        tags: uniq([...(style.tags ?? [])]),
      }
    }
  }
  return result
}
