import type { Project, Captions, TypedSticker } from '@/areas/editor/@type/Project'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { useLayoutsStore } from '@/areas/editor/store/useLayoutsStore'
import { uniqBy } from 'lodash-es'
import { useCropsStore } from '@/areas/editor/store/useCropsStore'
import { useEditorFocusStore, FocusTypes } from '@/store/editor/editorFocus'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { useGenerateCaptions } from '@/areas/editor/pages/captions/useGenerateCaptions'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { startupFromLocalFileByClipId } from '@/areas/editor/startup/startupFromLocalFile'
import { startupFromTwitchClipByClipId } from '@/areas/editor/startup/startupFromTwitchClip'
import { startupFromYoutubeClipByClipId } from '@/areas/editor/startup/startupFromYoutubeClip'
import { startupFromKickClipByClipId } from '@/areas/editor/startup/startUpFromKickClip'
import type { StartupConfig } from '@/areas/editor/startup/StartupConfig'
import { computed } from 'vue'

export async function startupFromSnapshot(snapshot: Project) {

  const editorClipInfoStore = useEditorClipInfoStore()
  const abortSignal = computed(() => new AbortController().signal)

  editorClipInfoStore.isLoadingClip = true

  if (!snapshot.id) {
    editorClipInfoStore.isLoadingClip = false
    return
  }

  editorClipInfoStore.loadingState = {
    state: 'loading',
    description: 'Resuming project...',
  }

  editorClipInfoStore.title = snapshot.title ?? 'New Clip'
  editorClipInfoStore.source = snapshot.source ?? 'unknown'
  editorClipInfoStore.languageCode = snapshot.language ?? 'en_us'

  try {
    const isOverrideUrl = snapshot.mp4Url?.startsWith('https://imports-sl-wnam.') || snapshot.mp4Url?.includes('skipRepair=true')
    const mp4UrlOverride = isOverrideUrl ? snapshot.mp4Url : undefined

    const result =  await startupBySource(snapshot.source, snapshot.id, mp4UrlOverride, { signal: abortSignal })
    if (result.error) {
      console.error(result.error)
      return;
    }
  } catch (e) {
    return;
  }

  const errors = []

  if (snapshot.segments.length > 0) {
    const segmentsStore = useSegmentsStore()
    try {
      segmentsStore.$reset()
      for (const segment of snapshot.segments) {
        try {
          segmentsStore.createById(segment.id, segment)
        } catch (e) {
          errors.push(e)
        }
      }
    } catch (e) {
      errors.push(e)
    }

    const layoutsStore = useLayoutsStore()
    try {
      layoutsStore.$reset()
      for (const layout of uniqBy(snapshot.layouts, 'id')) {
        try {
          layoutsStore.createById(layout.id, layout)
        } catch (e) {
          errors.push(e)
        }
      }
    } catch (e) {
      errors.push(e)
    }

    const cropsStore = useCropsStore()
    try {
      cropsStore.$reset()
      for (const crop of snapshot.crops) {
        try {
          cropsStore.createById(crop.id, crop)
        } catch (e) {
          errors.push(e)
        }
      }
    } catch (e) {
      errors.push(e)
    }
  }

  const editorFocusStore = useEditorFocusStore()
  try {
    if (snapshot.crops[0]) {
      editorFocusStore.setFocus(FocusTypes.CROP, snapshot.crops[0].id)
    }
  } catch (e) {
    errors.push(e)
  }

  const stickersStore = useStickersStore()
  try {
    stickersStore.$reset()
    for (const sticker of snapshot.stickers) {
      try {
        const upgradedSticker = purgeBlobUrlFrom(sticker)

        stickersStore.createById(upgradedSticker.id, upgradedSticker)
      } catch (e) {
        errors.push(e)
      }
    }
  } catch (e) {
    errors.push(e)
  }

  const editorCaptionsStore = useEditorCaptionsStore()
  applyCaptionsSettings(snapshot.captions)

  if (snapshot.captions.generated && !editorCaptionsStore.captionsGenerated) {
    try {
      await applyCaptionsDocument(snapshot.id)
      abortSignal.value.throwIfAborted()
    } catch (e) {
      errors.push(e)
    }
  }

  if (errors.length > 0) {
    console.error(errors)
  }

  editorClipInfoStore.isLoadingClip = false
  const historyStore = useHistoryStore()
  await historyStore.push()

  editorClipInfoStore.loadingState = null
}

async function applyCaptionsDocument(captionsId: string) {

  const editorCaptionsStore = useEditorCaptionsStore()
  const editorClipInfoStore = useEditorClipInfoStore()

  editorClipInfoStore.loadingState = {
    state: 'loading',
    description: 'Importing captions...',
  }

  const storedCaptions = localStorage.getItem('captions-' + captionsId)
  if (storedCaptions) {
    const captions = JSON.parse(storedCaptions).captions
    editorCaptionsStore.groupedCaptions.splice(0, editorCaptionsStore.groupedCaptions.length, ...captions)
  } else {
    const { generateCaptionsAsync } = useGenerateCaptions()
    await generateCaptionsAsync()
  }
}

function applyCaptionsSettings({ wrapper, settings }: Captions) {

  const editorCaptionsStore = useEditorCaptionsStore()

  editorCaptionsStore.captionsWrapper = wrapper
  editorCaptionsStore.captionStyle = settings.style

  editorCaptionsStore.baseOptions.size = settings.fontSize

  editorCaptionsStore.baseOptions.animation = settings.animation.style
  editorCaptionsStore.baseOptions.animationTarget = settings.animation.target

  editorCaptionsStore.baseOptions.highlight = settings.highlights.enabled

  editorCaptionsStore.baseOptions.emojis = settings.emojis.enabled
  editorCaptionsStore.baseOptions.emojiLocation = settings.emojis.position

  editorCaptionsStore.baseOptions.grouping = settings.grouping
  editorCaptionsStore.baseOptions.stripPunctuation = settings.stripPunctuation
  editorCaptionsStore.baseOptions.rotate = settings.rotate

  editorCaptionsStore.styleOptions.data = {
    baseColor: settings.color,
    highlightColor: settings.highlights.color,
  }
}

function startupBySource(source: Project['source'], clipId: string, mp4UrlOverride: string | undefined, config: StartupConfig) {
  switch (source) {
    case 'local-file':
      return startupFromLocalFileByClipId(clipId, config)
    case 'twitch-clip':
      return startupFromTwitchClipByClipId(clipId, config, mp4UrlOverride)
    case 'youtube-clip':
      return startupFromYoutubeClipByClipId(clipId, config)
    case 'kick-clip':
        return startupFromKickClipByClipId(clipId, config)
    default:
      throw new Error('Not implemented')
  }
}

// In some cases a Blob URL is stored in the sticker object. This function removes it, because it can cause the application
// to throw errors when the Blob URL is no longer valid (for example after reloading).
function purgeBlobUrlFrom(sticker: TypedSticker) {
  const upgradedSticker = { ...sticker }
  if (upgradedSticker.imageUrl?.startsWith('blob:')) {
    upgradedSticker.imageUrl = ''
  }
  return upgradedSticker
}
