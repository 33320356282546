<script lang="ts" setup>
import type { IGif, GifID } from '@giphy/js-types'
import { ref, computed } from 'vue'
import { useElementSize } from '@vueuse/core'
import DynamicPlanButtonWithTooltip from '@/components/Account/Upgrade/DynamicPlanButtonWithTooltip.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { tiers } from '@/enums/tiers'
import { useIsMobile } from '@/Hooks/useIsMobile'

const userInfoStore = useUserInfoStore()
const isGold = computed(() => userInfoStore.tier === tiers.GOLD)

const props = defineProps<{
  item: {
    url: string
    id: GifID
    gif: IGif
  }
}>()

const button = ref<HTMLButtonElement | null>(null)
const { width } = useElementSize(button)

const height = computed(() => {
  if (width.value) {
    return width.value * props.item.gif.images.downsized_medium.height / props.item.gif.images.downsized_medium.width
  } else {
    return 0
  }
})

const isMobile = useIsMobile()
</script>

<template>
  <button ref="button"
    :style="{ gridRow: `span ${Math.round(height / 12)}` }"
    class="group relative w-full overflow-hidden rounded-lg border border-surface-panel-border 50 transition-[color,border-color,background-color,transform] hover:border-brand-state-hover-border hover:bg-brand-state-hover-bg active:scale-90 active:shadow-none min-h-[100px] bg-checkerboard"
  >

    <img draggable="false" :src="item.url" :alt="item.gif.title" class="w-full h-full object-cover bg-transparent rounded-lg select-none" />

    <div class="absolute bottom-2 left-0 flex w-full flex-row justify-center text-[10px] font-light text-white" v-if="!isMobile">
      <span
        class="flex translate-y-2 transform flex-col gap-0.5 overflow-hidden rounded bg-gray-500 invisible opacity-0 transition-[transform,_opacity] group-hover:translate-y-0 group-hover:opacity-90 group-hover:visible"
      >
        <span
          v-if="item.gif.user"
          class="bg-gray-800 px-1.5 py-0.5 pointer-events-none"
        >
          @{{ item.gif.username }}
        </span>
      </span>
    </div>

    <div class="absolute right-2 top-2">
      <DynamicPlanButtonWithTooltip v-if="!isGold" feature="giphy-stickers" />
    </div>
  </button>
</template>
