export const shapes = [
  { label: 'Freeform', value: 'freeform', aspectLock: null },
  { label: 'Rectangle 9:16', value: '9:16', aspectLock: { width: 9, height: 16 } },
  { label: 'Rectangle 16:9', value: '16:9', aspectLock: { width: 16, height: 9 } },
  { label: 'Rectangle 4:3', value: '4:3', aspectLock: { width: 4, height: 3 } },
  { label: 'Square', value: '1:1', aspectLock: { width: 1, height: 1 } },
  { label: 'Circle', value: 'circle', aspectLock: { width: 1, height: 1 } },
] as const

export type Shape = (typeof shapes)[number]['value']
export const shapeValues = shapes.map((shape) => shape.value)
