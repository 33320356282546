import { editorRouteNames } from '@/areas/editor/routeNames'
import LayoutsPage from '@/areas/editor/pages/layouts/LayoutsPage.vue'
import TextPage from '@/areas/editor/pages/text/TextPage.vue'
import StickersPage from '@/areas/editor/pages/stickers/StickersPage.vue'
import GifsPage from '@/areas/editor/pages/gifs/GifsPage.vue'
import CaptionsPage from '@/areas/editor/pages/captions/CaptionsPage.vue'
import ExportPage from '@/areas/editor/pages/export/ExportPage.vue'
import { ref, computed } from 'vue'
import { usePrevious, createGlobalState } from '@vueuse/core'

export const useEditorStep = createGlobalState(() => {

  const steps = [
    {
      name: editorRouteNames.layouts,
      component: LayoutsPage,
    },
    {
      name: editorRouteNames.text,
      component: TextPage,
    },
    {
      name: editorRouteNames.stickers,
      component: StickersPage,
    },
    {
      name: editorRouteNames.gifs,
      component: GifsPage,
    },
    {
      name: editorRouteNames.captions,
      component: CaptionsPage,
    },
    {
      name: editorRouteNames.export,
      path: 'export',
      component: ExportPage,
    }
  ] as const

  function findStepIndex(step: (typeof steps)[number]['name'] | undefined) {
    return step ? steps.map(s => s.name).indexOf(step) : -1
  }

  const currentStep = ref<typeof steps[number]['name']>('editor-layouts')
  const previousStep = usePrevious(currentStep)
  const component = computed(() => steps[findStepIndex(currentStep.value)]?.component)

  const isLayoutsStep = computed(() => currentStep.value === editorRouteNames.layouts)
  const isCaptionsStep = computed(() => currentStep.value === editorRouteNames.captions)

  const stepIndex = computed(() => findStepIndex(currentStep.value))
  const direction = computed(() => {
    if (findStepIndex(previousStep.value) < findStepIndex(currentStep.value)) {
      return 'forward'
    } else {
      return 'backward'
    }
  })

  function selectLabelByStepIndex(index: number) {
    switch (index) {
      case 0:
        return 'Crop'
      case 1:
        return 'Text'
      case 2:
        return 'Stickers'
      case 3:
        return 'Gifs'
      case 4:
        return 'Captions'
      case 5:
        return 'Export'
      default:
        return 'Render'
    }
  }

  return {
    steps,
    currentStep,
    previousStep,
    component,
    stepIndex,
    direction,
    isLayoutsStep,
    isCaptionsStep,
    selectLabelByStepIndex,
    resetEditorStep() {
      currentStep.value = 'editor-layouts'
    }
  }
})
