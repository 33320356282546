import type { CaptionStyleDefinition } from '@/components/Captions/captionTypes'

export const christmasCaptionStyles: Record<string, CaptionStyleDefinition> = {
  'christmas-fun': {
    previewSettings: {
      animationSpeed: 750,
      lottieUrl: '',
      displayName: 'Ho Ho Ho',
      scaling: 1.5,
      previewText: ['Ho Ho Ho', 'The Santa 🎅', 'is a creature of joy', 'that brings gifts', 'to the children'],
    },
    fontFamily: 'Christmas',
    fontSize: {
      fontSize: 41,
      lineHeight: 0.8,
    },
    cssClass: '',
    style: {
      border: {
        color: 'darker',
        width: 2,
      },
      dropShadow: {
        color: 'rgba(0,0,0,0.09)',
        offset: { x: 0, y: 0 },
        blur: 5,
      },
      color: 'default',
    },
    colors: ['#e5e7ff', '#fed1ff', '#dfd1ff', '#2cf2a6', '#a4c856', '#ffc547', '#ff8c80'],
    highlightColor: '#fed1ff',
    tags: ['christmas'],
    effects: {},
    disableHighlight: false,
  },
  'holy-night': {
    previewSettings: {
      animationSpeed: 750,
      lottieUrl: '',
      displayName: 'Holy Night',
      scaling: 1.5,
      previewText: [
        'Holy Night',
        'The angel 🧚‍♀️',
        'is a creature of light',
        'that brings good tidings',
        'and joy to the world',
      ],
    },
    fontFamily: 'Christmas Holiday',
    fontSize: {
      fontSize: 31,
      lineHeight: 1.1,
    },
    cssClass: '',
    style: {
      border: {
        color: 'darker',
        width: 1,
      },
      dropShadow: {
        color: '#000000dd',
        offset: { x: 0, y: 0 },
        blur: 5,
      },
      color: 'default',
    },
    colors: ['#ff8c80', '#fed1ff', '#dfd1ff', '#2cf2a6', '#a4c856', '#ffc547', '#e5e7ff'],
    highlightColor: '#fed1ff',
    tags: ['christmas'],
    effects: {},
    disableHighlight: false,
  },
}
