import {streamLadderAxios} from './axios'
import {useUserInfoStore} from '@/store/user/userInfo'
import {useUserTemplatesStore} from '@/store/user/userTemplates'
import { getLayout } from '@/data/layoutData'
import { useLayoutsStore } from '@/store/entity-system/useLayoutsStore'
import type { Template, Sticker } from '@/apis/streamladder-api/model'
import type { Template as UserTemplate } from '@/store/user/userTemplates'
import type { AxiosResponse } from 'axios'
import { updateCustomLayoutsStoresAsync } from '@/store/entity-system/queries/customLayoutsQueries'
import * as Sentry from '@sentry/vue'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'

let promise = null as Promise<AxiosResponse<Template[]>> | null
export default {
    async getTemplates() {

        const userInfo = useUserInfoStore()
        const templatesStore = useUserTemplatesStore()

        if (!userInfo.hasSubscription) {
            templatesStore.savedTemplates = []
            useLayoutsStore().isLoading = false // This is a hack to prevent the loading spinner from showing forever
            return
        }

        if (promise) {
            await promise
            return
        }

        templatesStore.isLoading = true
        promise = streamLadderAxios.get<Template[]>('/api/templates')

        const res = await promise

        if (res.status === 200) {
            const templates: UserTemplate[] = []

            const useNewEditor = useFeatureFlagEnabled('new-editor-v2')
            for (const template of res.data) {

                // Old templates had 'freeform' as the ratio, which is now 'Free'
                if (template.ratio === 'freeform') {
                    template.ratio = 'Free'
                }

                if (!template.stickers) {
                    template.stickers = []
                } else {
                    template.stickers = await Promise.all(template.stickers.map(async (s) => ({
                        ...await upgradeSticker(s),
                        animationStyle: s.animationStyle == null ? undefined : s.animationStyle,
                        animationTime: s.animationTime == null ? undefined : s.animationTime,
                    })));
                }

                if (useNewEditor.value) {
                    templates.push(template)
                } else {
                    const upgradedTemplate = upgradeCustomLayout(template)
                    if (upgradedTemplate) {
                        templates.push(upgradedTemplate)
                    }
                }
            }

            templatesStore.savedTemplates = templates
        } else {
            throw new Error(`GetTemplates for user failed with code '${res.status}', message: ${res.data}`)
        }

        templatesStore.isLoading = false

        promise = null
    },
    async deleteTemplate(templateId: string) {
        const res = await streamLadderAxios.delete(`/api/templates/${templateId}`)
        if (res.status === 200) {
            await this.getTemplates()
        } else {
            throw new Error(`DeleteTemplate for user failed with code '${res.status}', message: ${res.data}`)
        }
    },
    async saveTemplate(template: Template, update = false) {
        const command = update ? streamLadderAxios.put : streamLadderAxios.post
        const res = await command('/api/templates', template)
        if (res.status === 200) {
            await this.getTemplates()
            return res.data
        } else {
            throw new Error(`SaveTemplate for user failed with code '${res.status}', message: ${res.data}`)
        }
    },
}

const upgradeSticker = async (sticker: Sticker) => ({
    ...upgradeTextToHtmlContent(sticker),
    ...await upgradeImageStickerToHaveNaturalSize(sticker),
})

const upgradeTextToHtmlContent = (sticker: Sticker) => {
    if (sticker.text) {
        return {
            ...sticker,
            htmlContent: sticker.text,
            text: null,
        }
    } else {
        return sticker
    }
}

const upgradeImageStickerToHaveNaturalSize = async (sticker: Sticker) => {
    if (sticker.imageUrl && !sticker.imageUrl.includes('blob:')) {
        const img = new Image()
        img.src = sticker.imageUrl
        await new Promise((resolve) => img.onload = resolve)
        return {
            ...sticker,
            naturalWidth: img.naturalWidth,
            naturalHeight: img.naturalHeight,
        }
    } else {
        return sticker
    }
}

function upgradeCustomLayout(template: Template): UserTemplate | null {

    if (!template.layoutName) {
        return null
    }

    if (getLayout(template.layoutName)) {
        return {
            ...template,
            displayName: template.layoutName
        } as UserTemplate
    } else {
        const layoutsStore = useLayoutsStore()
        const layout = layoutsStore.selectById(template.layoutName)
        if (layout) {
            return {
                ...template,
                layoutId: layout.id,
                displayName: layout.emoji + ' ' + layout.name,
            } as UserTemplate
        }
    }

    return null
}
