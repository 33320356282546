import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'

export function useCaptionMethods() {
  
  const captionsStore = useEditorCaptionsStore()
  const videoStore = useVideoStore()
  
  function isAt(timestamp: number, timespan: { start: number, end: number }): boolean {
    return timestamp >= timespan.start && timestamp <= timespan.end
  }

  function isCurrentCaption(caption: { start: number, end: number }) {
    return isAt(videoStore.currentTimeMs, caption)
  }

  function isCurrentWord(word: { start: number, end: number }) {
    return isAt(videoStore.currentTime, word)
  }

  function findCaptionAt(timestamp: number) {
    return captionsStore.captions.find(caption => isAt(timestamp, caption))
  }

  function findCaptionIndexAt(timestamp: number) {
    return captionsStore.captions.findIndex(caption => isAt(timestamp, caption))
  }

  function findCaptionAtCurrentTime() {
    return findCaptionAt(videoStore.currentTimeMs)
  }

  function findCaptionIndexAtCurrentTime() {
    return findCaptionIndexAt(videoStore.currentTimeMs)
  }

  function findLastCaptionBefore(timestamp: number) {
    return captionsStore.captions.findLast(caption => caption.end < timestamp)
  }

  function findLastCaptionIndexBefore(timestamp: number) {
    return captionsStore.captions.findLastIndex(caption => caption.end < timestamp)
  }

  function findFirstCaptionAfter(timestamp: number) {
    return captionsStore.captions.find(caption => caption.start > timestamp)
  }

  function findFirstCaptionIndexAfter(timestamp: number) {
    return captionsStore.captions.findIndex(caption => caption.start > timestamp)
  }

  function findWordAt(timestamp: number) {
    const caption = findCaptionAt(timestamp)
    return caption?.words.find(word => isAt(timestamp, word))
  }

  function findWordIndexAt(timestamp: number) {
    const caption = findCaptionAt(timestamp)
    return caption ? caption.words.findIndex(word => isAt(timestamp, word)) : -1
  }

  function findWordAtCurrentTime() {
    return findWordAt(videoStore.currentTimeMs)
  }

  function findWordIndexAtCurrentTime() {
    return findWordIndexAt(videoStore.currentTimeMs)
  }
  
  return {
    isCurrentCaption,
    isCurrentWord,
    findCaptionAt,
    findCaptionIndexAt,
    findCaptionAtCurrentTime,
    findCaptionIndexAtCurrentTime,
    findLastCaptionBefore,
    findLastCaptionIndexBefore,
    findFirstCaptionAfter,
    findFirstCaptionIndexAfter,
    findWordAtCurrentTime,
    findWordIndexAtCurrentTime,
  }
}
