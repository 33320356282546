<script lang="ts" setup>
import { onMounted } from 'vue'

defineProps<{ color: 'pumpkin' | 'ghost' | 'witch' }>()
const emit = defineEmits(['stickerLoaded'])

onMounted(() => emit('stickerLoaded', this))
</script>

<template>
  <svg height="75" viewBox="0 0 38 50" width="57" xmlns="http://www.w3.org/2000/svg">
    <path
      :class="{
        'fill-[#F98728]': color === 'pumpkin',
        'fill-zinc-100': color === 'ghost',
        'fill-[#0FA688]': color === 'witch',
      }"
      d="M5.59747 1.27743C12.342 -1.43658 17.7882 2.41501 23.3794 12.8346C30.0091 25.1897 39.0239 30.8607 37.9045 34.7063C36.8733 38.2484 32.8585 38.46 32.8585 38.46C32.8585 38.46 33.3577 43.0732 30.2569 44.9833C27.156 46.8946 23.956 45.5913 23.956 45.5913C23.956 45.5913 22.8934 48.7067 18.6731 49.4442C14.4528 50.1816 12.4847 47.2729 12.4847 47.2729C12.4847 47.2729 8.06487 49.0682 5.2614 46.7966C2.45914 44.5251 2.39869 28.0005 1.55366 23.0947C0.70863 18.1889 -3.08977 4.7736 5.59747 1.27743Z"
    />
    <path
      :class="{
        'fill-[#8E3817]': color === 'pumpkin',
        'fill-zinc-200': color === 'ghost',
        'fill-[#0B7162]': color === 'witch',
      }"
      d="M12.4834 47.2727C10.8877 44.9818 10.3013 33.1018 10.3013 33.1018C11.3603 38.6012 12.2416 45.1426 12.4834 47.2727ZM19.2823 36.0854C19.2823 36.0854 22.6129 44.7956 23.9548 45.5899C23.5897 44.2927 20.3861 36.876 19.2823 36.0854ZM26.4524 32.501C26.4524 32.501 31.5202 38.4343 32.8572 38.4585C31.6918 37.1094 27.5791 33.0933 26.4524 32.501Z"
    />
    <path
      :class="{
        'fill-[#8E3817]': color === 'pumpkin',
        'fill-zinc-900': color === 'ghost',
        'fill-[#074E51]': color === 'witch',
      }"
      d="M6.90658 11.4397C7.20639 12.1119 6.87152 12.9146 6.15826 13.2326C5.445 13.5505 4.62415 13.2628 4.32434 12.5906C4.02453 11.9185 4.3594 11.1157 5.07266 10.7978C5.78592 10.4799 6.60677 10.7676 6.90658 11.4397ZM12.9681 7.27745C12.2548 7.59539 11.9199 8.39811 12.2198 9.07026C12.5196 9.74242 13.3404 10.0301 14.0537 9.7122C14.7669 9.39425 15.1018 8.59154 14.802 7.91938C14.5022 7.24722 13.6813 6.9595 12.9681 7.27745ZM11.6661 15.4654C10.8053 15.8498 10.4644 16.8363 10.9637 17.957C11.463 19.0776 12.6151 19.9082 13.4758 19.5237C14.3366 19.1393 14.4611 17.5423 13.9606 16.4217C13.4613 15.301 12.5268 15.0822 11.6661 15.4654Z"
    />
  </svg>
</template>

<style lang="scss" scoped></style>
