import { onUnmounted, ref, toValue, watch } from 'vue'
import type { MaybeRef } from '@vueuse/shared'
import { watchImmediate } from '@vueuse/core'
import type { WSChannel } from '@/modules/WebsocketService/WebsocketAdapter'
import { PusherAdapter } from '@/modules/WebsocketService/adapters/PusherAdapter'

const adapter = new PusherAdapter('ef0a10b651ed4adf46eb', {
  cluster: 'us3',
})

const listeners = ref(0)

export const getWebsocketClient = () => {
  if (!adapter.isConnected()) {
    adapter.connect()
  }
  listeners.value++
  const release = () => {
    listeners.value--
  }
  return [adapter, release] as const
}

function disconnectAdapter() {
  if (listeners.value === 0) {
    adapter.disconnect()
  }
}

watch(listeners, (newValue, oldValue) => {
  if (newValue === 0 && oldValue > 0) {
    setTimeout(disconnectAdapter, 2000)
  }
})

export const useWebsocketClient = () => {
  const [client, release] = getWebsocketClient()
  onUnmounted(() => {
    release()
  })
  return client
}

export const useWebsocketGlobalListener = (callback: (data: any) => void) => {
  const client = useWebsocketClient()
  client.bind_global(callback)
  onUnmounted(() => {
    client.unbind_global(callback)
  })
}

export const useWebsocketChannel = (
  channelName: MaybeRef<string>,
  onEvent?: (eventName: string, data: any) => void
) => {
  const client = useWebsocketClient()
  const chanel = ref<WSChannel>()
  watchImmediate([() => channelName], (newChannel, oldChannel) => {
    if (newChannel !== oldChannel) {
      if (chanel.value) {
        chanel.value?.disconnect()
      }
      chanel.value = client.subscribe(toValue(channelName))
      if (onEvent) {
        chanel.value?.bind_global(onEvent)
      }
    }
  })
  return chanel
}
