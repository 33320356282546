import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { usePresets } from '@/areas/editor/@data/layouts'
import { useCropsStore } from '@/areas/editor/store/useCropsStore'
import { useIsMobile } from '@/Hooks/useIsMobile'
import { useEditorFocusStore, FocusTypes } from '@/store/editor/editorFocus'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { v4 as uuid } from 'uuid'
import { useLocalStorage } from '@vueuse/core'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import type { StartupConfig } from '@/areas/editor/startup/StartupConfig'
import { handleStartupError } from '@/areas/editor/startup/handleStartupError'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { editorRouteNames } from '@/areas/editor/routeNames'

export async function prepareStores(config: StartupConfig) {

  const editorClipInfoStore = useEditorClipInfoStore()
  editorClipInfoStore.isLoadingClip = true

  cleanupCaptionStorage()
  
  // Push all we know about the clip to the history store, so we can resume from here on reload.
  const historyStore = useHistoryStore()
  await historyStore.push()

  config.signal.value?.throwIfAborted()

  try {
    await startupVideo(config)
    config.signal.value?.throwIfAborted()

    await startupWithDefaultLayout(config)
    config.signal.value?.throwIfAborted()

    // If the startup process was successful, replace the history state.
    await historyStore.replace()

    editorClipInfoStore.isLoadingClip = false
    editorClipInfoStore.loadingState = null
    return { error: null }
  } catch (error) {
    return handleStartupError(error)
  }
}

export async function startupVideo(config: StartupConfig) {

  const editorClipInfoStore = useEditorClipInfoStore()
  editorClipInfoStore.loadingState = {
    state: 'loading',
    description: 'Importing video...'
  }
  
  const videoExists = await existsAsync(editorClipInfoStore.mp4Url)
  config.signal.value?.throwIfAborted()

  if (!videoExists) {
    throw fileNotFoundErrorFor(editorClipInfoStore.source)
  } else {

    editorClipInfoStore.loadingState = {
      state: 'loading',
      description: 'Loading video...'
    }
  
    const videoStore = useVideoStore()
  
    const video = document.createElement('video')
    video.src = editorClipInfoStore.mp4Url
    video.muted = true
    video.autoplay = false
    video.loop = true
    video.controls = true
    video.crossOrigin = 'anonymous'
    video.playsInline = true
    video.disablePictureInPicture = true
    video.disableRemotePlayback = true
  
    await videoStore.loadVideo(video, editorClipInfoStore.mp4Url).catch((reason) => {
      throw new Error(reason)
    })
    config.signal.value?.throwIfAborted()
    videoStore.playing = true
  }
}

export async function startupLayoutsStore() {

  const { prepareLayouts, layouts } = usePresets()
  
  const editorClipInfoStore = useEditorClipInfoStore()
  editorClipInfoStore.loadingState = {
    state: 'loading',
    description: 'Preparing layouts and saved templates...'
  }
  
  await prepareLayouts()
  await new Promise((resolve) => setTimeout(resolve, 0))

  return layouts
}

export async function startupWithDefaultLayout(config: StartupConfig) {

  await startupLayoutsStore()
  config.signal.value?.throwIfAborted()

  const { layouts } = usePresets()
  const videoStore = useVideoStore()

  const { width, height } = videoStore.videoSize!
  if (Math.abs((width / height) - (9 / 16)) < 0.01) {

    const layoutId = startupWithLayout('full')
    const cropsStore = useCropsStore()
    for (const crop of cropsStore.entities.filter(c => c.layoutId === layoutId)) {
      cropsStore.updateCropAreaById(crop.id, { x: 0, y: 0, width: 1, height: 1 })
    }
    
    const { currentStep } = useEditorStep()
    currentStep.value = editorRouteNames.text
  } else {

    const defaultPreset = useLocalStorage('defaultPreset', 'split')

    const preset = layouts.value.find((l) => l?.id === defaultPreset.value)
    if (!preset) {
      defaultPreset.value = 'split'
    }

    startupWithLayout(preset?.id ?? 'split')
  }
}

function startupWithLayout(presetId: string) {

  const { applyPreset } = usePresets()
  const layoutId = applyPreset(presetId)

  const isMobile = useIsMobile()

  if (!isMobile.value) {

    const cropsStore = useCropsStore()
    const crops = cropsStore.whereLayoutIdIs(layoutId).value

    const editorFocusStore = useEditorFocusStore()
    editorFocusStore.setFocus(FocusTypes.CROP, crops[0].id)
  }

  const segmentsStore = useSegmentsStore()
  segmentsStore.$reset()

  const videoStore = useVideoStore()
  const id = uuid()
  segmentsStore.createById(id, {
    type: 'layout',
    layoutId: layoutId,
    startMs: 0,
    endMs: videoStore.duration,
  })
  
  return layoutId
}

function fileNotFoundErrorFor(source: string) {
  
  switch (source) {
    case 'twitch-clip':
      return 'Twitch Clip could not be imported.'
    case 'local-file':
      return 'File could not be found.'
    case 'youtube-clip':
      return 'YouTube Clip could not be imported.'
    case 'twitch-vod':
      return 'Twitch VOD could not be imported.'
    case 'kick-clip':
      return 'Kick Clip could not be imported.'
    default:
      return 'Clip could not be imported.'
  }
}

export async function existsAsync(url: string): Promise<boolean> {

  if (!url) {
    return false
  }

  try {
    const response = await fetch(url, { method: url.startsWith('blob:') ? 'GET' : 'HEAD' })
    return response.ok
  } catch (e) {
    return false
  }
}

export function cleanupCaptionStorage() {
  for (const key in { ...localStorage }) {
    if (key.startsWith('captions-')) {
      const object = JSON.parse(localStorage.getItem(key)!)
      if (new Date(object.date).getTime() + 1000 * 60 * 60 * 24 * 7 < new Date().getTime()) {
        localStorage.removeItem(key)
      }
    }
  }
}
