<script lang="ts" setup>
import { onMounted, computed } from 'vue'

const props = defineProps<{ color: 'pumpkin' | 'blood' | 'witch' }>()
const emit = defineEmits(['stickerLoaded'])

onMounted(() => emit('stickerLoaded', this))

const shadowColor = computed(() => {
  return {
    pumpkin: '#8E3817',
    blood: '#70002D',
    witch: '#074E51',
  }[props.color]
})
</script>

<template>
  <svg
    :class="{
      'text-[#F98728]': color === 'pumpkin',
      'text-[#DA0062]': color === 'blood',
      'text-[#0FA688]': color === 'witch',
    }"
    fill="none"
    height="150"
    viewBox="0 0 44 50"
    width="132"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.781 6.75917C24.4468 1.67092 20.6953 -1.0403 16.9816 1.48495C16.1146 1.95476 16.2391 0.370538 16.9816 0C13.3055 0.706119 13.1196 5.38468 16.0909 5.12461C19.0622 4.86453 19.7082 6.16491 18.1225 7.6135C16.5369 9.06209 16.7103 9.99053 16.7355 10.2129C16.7592 10.4352 26.2296 9.24666 26.2296 9.24666C25.8954 7.57575 24.781 6.75777 24.781 6.75777V6.75917Z"
      fill="#83BF6D"
    />
    <path
      d="M22.8502 8.54727C22.8432 8.54727 22.8362 8.54727 22.8278 8.54727C22.7229 8.53468 22.6474 8.4396 22.6586 8.33473C22.6642 8.28999 23.1326 3.90786 20.6465 2.4313C19.7139 1.87619 18.6666 2.45787 17.7423 2.96963C17.0069 3.37792 16.3707 3.73028 15.8715 3.46041C15.4981 3.25767 15.294 2.74031 15.2297 1.83424C15.2227 1.72798 15.3024 1.63709 15.4073 1.6287C15.5149 1.62311 15.6044 1.70141 15.6128 1.80628C15.6645 2.54875 15.8141 2.992 16.0546 3.12204C16.3679 3.29262 16.9453 2.97242 17.5564 2.63265C18.5198 2.09851 19.7209 1.43294 20.8423 2.09991C23.5423 3.70511 23.0613 8.18792 23.0389 8.37668C23.0277 8.47456 22.9452 8.54727 22.8488 8.54727H22.8502Z"
      fill="#333333"
      opacity="0.5"
    />
    <path
      d="M21.1414 7.87913H21.1358C21.0296 7.87633 20.9457 7.78824 20.9485 7.68197C20.996 5.92297 20.7555 4.85051 20.234 4.49395C19.7544 4.16676 19.0343 4.46878 18.3981 4.73585C18.0289 4.89106 17.6794 5.03787 17.3885 5.05605C17.2823 5.06444 17.1914 4.98194 17.1844 4.87707C17.1774 4.77081 17.2585 4.67992 17.3634 4.67293C17.5899 4.65894 17.9101 4.52471 18.2485 4.38209C18.9378 4.09265 19.7963 3.7319 20.4493 4.17794C21.1023 4.62399 21.3819 5.74119 21.3302 7.69176C21.3274 7.79663 21.2421 7.87913 21.1386 7.87913H21.1414Z"
      fill="#333333"
      opacity="0.5"
    />
    <path
      d="M41.9405 39.5407C39.9466 44.7813 35.9168 48.2057 29.51 47.0283C25.2523 51.7083 17.65 50.2234 13.5643 46.9556C5.58168 47.7456 1.68054 42.0492 0.766086 34.7936C-0.833519 22.0835 6.73664 4.5871 17.6025 8.80703C17.6179 8.81262 17.6346 8.82101 17.65 8.82661C17.7339 8.71475 17.8318 8.60848 17.9437 8.50641C17.9451 8.50501 17.9478 8.50221 17.9492 8.50081C18.0094 8.44908 18.0681 8.39734 18.1296 8.34561C18.131 8.34561 18.131 8.34421 18.1338 8.34141C19.6859 7.10955 23.1326 6.56703 25.6397 8.48963C25.6397 8.48963 25.6397 8.49102 25.6411 8.49102C25.7767 8.5945 25.9109 8.70775 26.0424 8.82661C39.8599 4.20397 46.6946 27.0403 41.9391 39.5407H41.9405Z"
      fill="currentColor"
    />
    <path
      d="M13.5657 47.1464C13.5153 47.1464 13.4636 47.1268 13.4259 47.0863C11.0712 44.5932 9.47019 41.5506 8.66619 38.0424C8.64242 37.9389 8.70674 37.8368 8.81021 37.813C8.91368 37.7893 9.01715 37.8536 9.03953 37.9571C9.82814 41.3982 11.397 44.3806 13.7041 46.8234C13.7768 46.9003 13.774 47.022 13.6957 47.0947C13.658 47.1296 13.6118 47.1464 13.5643 47.1464H13.5657Z"
      fill="#333333"
      opacity="0.5"
    />
    <path
      d="M29.51 47.2201C29.4611 47.2201 29.4121 47.2019 29.3744 47.1642C29.2989 47.0886 29.2989 46.9684 29.3744 46.8929C32.2198 44.0432 34.2417 40.8063 35.3855 37.2729C35.4176 37.1722 35.5267 37.1177 35.6274 37.1498C35.7281 37.182 35.784 37.2911 35.7504 37.3917C34.5885 40.9839 32.5344 44.2712 29.6456 47.1642C29.6079 47.2019 29.5589 47.2201 29.51 47.2201Z"
      fill="#333333"
      opacity="0.5"
    />
    <path
      :fill="shadowColor"
      d="M10.0002 23.8792C12.1171 22.225 14.2341 23.0989 15.4226 24.9195C15.4226 24.9195 15.3835 19.6452 12.9519 19.5348C10.5203 19.4229 10.0002 23.8806 10.0002 23.8806V23.8792Z"
    />
    <path
      :fill="shadowColor"
      d="M32.2086 23.8792C30.0917 22.225 27.9747 23.0989 26.7862 24.9195C26.7862 24.9195 26.8253 19.6452 29.2569 19.5348C31.6885 19.4229 32.2086 23.8806 32.2086 23.8806V23.8792Z"
    />
    <path
      :fill="shadowColor"
      d="M20.8073 23.3589L19.4328 27.5187C19.4328 27.5187 22.3663 27.7788 22.33 27.5187C22.2936 27.2586 20.8073 23.3589 20.8073 23.3589Z"
    />
    <path
      :fill="shadowColor"
      d="M33.2755 36.0692L32.7791 34.7716C31.8115 35.0653 31.0844 35.5491 30.6985 36.3069L31.0019 38.2868C30.0805 38.9608 28.9982 39.5439 27.7398 39.9983L27.2896 37.1375C25.964 37.2829 24.7853 37.4982 24.0386 37.9163V40.8764C23.0738 41.0051 22.0335 41.0722 20.9191 41.0722C20.4968 41.0722 20.0858 41.0624 19.6873 41.0414L19.3615 38.4169C18.0695 37.9988 16.6572 37.8967 15.2017 37.9163L15.115 40.2877C13.6426 39.8431 12.3954 39.2279 11.3467 38.4952L11.4697 37.1724C10.8265 36.43 10.0267 35.8721 9.14861 35.4092L8.94166 36.23C5.88368 32.354 6.01931 27.2211 7.21901 25.0649C7.21901 25.0649 7.53362 25.5753 8.31245 26.266L7.73637 28.8165C7.51405 29.5729 10.2378 31.0691 10.4112 30.637L11.4334 28.2446C12.3646 28.6654 13.4748 29.0556 14.7934 29.3632V31.6395C16.0196 32.1597 17.6528 32.1597 18.8427 31.9374V29.9421C19.4887 29.984 20.1711 30.0078 20.8842 30.0078C21.7511 30.0078 22.5663 29.9756 23.3367 29.9141V32.1611C23.3689 32.5484 27.4644 31.9765 27.4588 31.3823C27.4574 31.3109 27.4588 30.1406 27.4588 29.2499C28.527 28.9759 29.4541 28.6459 30.2525 28.2949L30.9488 30.2776C31.9947 29.8092 32.8462 29.1283 33.5118 28.2432L33.1133 26.6044C34.1956 25.7487 34.6248 25.0677 34.6248 25.0677C35.9993 27.4797 36.1335 32.3359 33.2783 36.0706L33.2755 36.0692Z"
    />
    <path
      d="M12.0178 21.3052C12.3121 21.3052 12.5506 21.0667 12.5506 20.7725C12.5506 20.4783 12.3121 20.2397 12.0178 20.2397C11.7236 20.2397 11.4851 20.4783 11.4851 20.7725C11.4851 21.0667 11.7236 21.3052 12.0178 21.3052Z"
      fill="#FFFFFF"
    />
    <path
      d="M28.966 21.3052C29.2602 21.3052 29.4988 21.0667 29.4988 20.7725C29.4988 20.4783 29.2602 20.2397 28.966 20.2397C28.6718 20.2397 28.4333 20.4783 28.4333 20.7725C28.4333 21.0667 28.6718 21.3052 28.966 21.3052Z"
      fill="#FFFFFF"
    />
    <path
      d="M41.9405 39.541C39.9466 44.7816 35.9168 48.2059 29.51 47.0286C25.2523 51.7086 17.65 50.2236 13.5643 46.9559C5.58164 47.7459 1.68051 42.0494 0.766052 34.7939C0.767451 34.7939 0.767451 34.7925 0.767451 34.7925C2.60895 40.482 9.00457 38.6223 9.00457 38.6223C9.00457 38.6223 12.38 48.2437 20.9932 48.3542C29.6065 48.466 34.4039 40.2261 34.4039 40.2261C33.0238 43.2659 35.9587 49.1805 41.9391 39.541H41.9405Z"
      fill="#333333"
      opacity="0.3"
    />
    <path
      d="M13.5293 9.50658C6.58001 13.1938 2.8201 22.9494 2.4244 28.6137C2.32512 16.8978 10.1651 10.7482 13.5293 9.50658ZM25.641 8.48865C23.1326 6.56605 19.6873 7.10857 18.1352 8.34044C18.1338 8.34183 18.1338 8.34463 18.131 8.34463C18.0695 8.39637 18.0107 8.4481 17.9506 8.49984C17.9492 8.50123 17.9464 8.50403 17.945 8.50543C17.8276 8.60471 17.7143 8.70678 17.6039 8.80605C16.716 9.59607 16.0112 10.2966 15.919 10.3917C17.471 9.0116 23.7422 8.13909 27.1903 9.83098C26.5135 9.2479 25.8717 8.6886 25.6438 8.49005C25.6424 8.49005 25.6424 8.48865 25.6424 8.48865H25.641Z"
      fill="#FFFFFF"
      opacity="0.5"
    />
    <path
      d="M27.1889 9.83203C27.6643 10.0641 28.0852 10.3438 28.4347 10.6808C30.2525 12.4314 28.6347 11.0737 27.1889 9.83203Z"
      fill="#FFFFFF"
      opacity="0.5"
    />
    <path
      d="M12.3801 16.1135C12.3507 16.1135 12.3228 16.1065 12.2948 16.0939C12.1997 16.0464 12.162 15.9317 12.2081 15.8366C13.9322 12.3745 15.9792 9.62556 17.5508 8.66356C17.6403 8.60763 17.7592 8.6356 17.8151 8.72648C17.871 8.81737 17.8417 8.93482 17.7522 8.99075C16.2393 9.9178 14.2468 12.6066 12.5521 16.0072C12.5185 16.0743 12.45 16.1135 12.3801 16.1135Z"
      fill="#333333"
      opacity="0.3"
    />
    <path
      d="M32.6938 16.1132C32.6281 16.1132 32.5652 16.0797 32.5288 16.0196C30.616 12.8022 28.2334 10.3357 26.1528 9.42399C26.0563 9.38204 26.0115 9.26878 26.0535 9.1709C26.0954 9.07303 26.2101 9.02968 26.3066 9.07163C28.4571 10.0154 30.9068 12.5393 32.8588 15.8238C32.9133 15.9147 32.8826 16.0321 32.7917 16.0867C32.7609 16.1049 32.7274 16.1132 32.6938 16.1132Z"
      fill="#333333"
      opacity="0.3"
    />
  </svg>
</template>

<style lang="scss" scoped></style>
