<script setup lang="ts">
import type { PrimitiveProps } from 'radix-vue'
import { Button, type ButtonVariants } from '@/components/ui/button'
import { type HTMLAttributes, computed } from 'vue'
import { omit } from 'lodash-es'
import { cn } from '@/lib/utils'

interface Props extends PrimitiveProps {
  variant?: ButtonVariants['variant']
  size?: ButtonVariants['size']
  class?: HTMLAttributes['class']
}

const model = defineModel<boolean>({ required: true })
const props = withDefaults(defineProps<Props>(), { variant: 'toggle' })

const delegatedProps = computed(() => omit(props, ['class', 'modelValue', 'value']))
</script>

<template>
  <Button
    :class="cn('hover:border-brand-state-hover-border', props.class)"
    :data-state="model ? 'active' : 'inactive'"
    type="button"
    v-bind="delegatedProps"
    @click="model = !model"
  >
    <slot />
  </Button>
</template>

<style scoped lang="scss">

</style>
