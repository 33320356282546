import type { Sticker, StickerVariant } from '@/store/entity-system/useStickersStore'
import type { FontLabel } from '@/data/fonts'
import { captionStylesSettings, type CaptionStyle } from '@/components/Captions/styles/CaptionStyleManager'

export function selectStickerFont(sticker: Sticker): FontLabel | null {
  if ('fontFamily' in sticker) {
    return sticker.fontFamily;
  } else if ('variant' in sticker) {
    return selectStickerFontByVariant(sticker.variant);
  } else {
    return null;
  }
}

export function selectStickerFontByVariant(variant: StickerVariant): FontLabel {
  if (isCaptionStyle(variant)) {
    return captionStylesSettings[variant].fontFamily;
  } else if (variant === 'outline') {
    return 'Bangers';
  } else {
    return 'Metropolis';
  }
}

function isCaptionStyle(variant: StickerVariant): variant is CaptionStyle {
  return variant in captionStylesSettings;
}
