import type { Zone } from '@/modules/SLVideoplayer/types'

export function getZoneWithFixedAspectRatioBasedOnExistingZone(
  zone: Zone,
  screen: { width: number; height: number },
  targetAspectRatio: number
): Zone {
  const { x, y, width, height } = zone

  const newZone = {
    x: x * screen.width,
    y: y * screen.height,
    width: width * screen.width,
    height: height * screen.height,
  }

  const currentAspectRatio = newZone.width / newZone.height
  if (currentAspectRatio > targetAspectRatio) {
    const newWidth = newZone.height * targetAspectRatio
    newZone.x += (newZone.width - newWidth) / 2
    newZone.width = newWidth
  } else {
    const newHeight = newZone.width / targetAspectRatio
    newZone.y += (newZone.height - newHeight) / 2
    newZone.height = newHeight
  }

  return {
    x: newZone.x / screen.width,
    y: newZone.y / screen.height,
    width: newZone.width / screen.width,
    height: newZone.height / screen.height,
  }
}
