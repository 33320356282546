import type { Logging, LoggingAdapter } from '@/logging/index'
import VueGtag, { event, type GtagEvent } from 'vue-gtag'
import { useUserInfoStore } from '@/store/user/userInfo'
import unwrap from '@/helpers/unwrap'

const config = {
  id: import.meta.env.VITE_GA_ID,
}

const trackEvent: GtagEvent = (eventName, params) => {
  if (import.meta.env.PROD) {
    event(toSnakeCase(eventName), keysToSnakeCase(params))
  } else {
    console.log('[Google Analytics]', toSnakeCase(eventName), keysToSnakeCase(params))
  }
}

export const GA: LoggingAdapter<Record<string, unknown>> = {
  initialize(app) {
    if (import.meta.env.PROD) {
      app.use(VueGtag, { config })
    }
    console.log('[Google Analytics] initialized')
  },

  trackEvent(eventName, properties) {
    const userInfoStore = useUserInfoStore()
    if (userInfoStore.userId) {
      properties.userId = userInfoStore.userId
    }

    trackEvent(eventName, properties)
  },

  reset() {
    // Not implemented, but is called
  },

  pageView(to) {
    trackEvent('page_view', {
      page_title: to.name?.toString(),
      page_path: to.path,
    })
  },

  identifyUser(id: string) {
    trackEvent('login', { id })
  },

  registerUser() {
    // Not implemented, but is called
  },
}

type EventParams = Parameters<GtagEvent>[1]

function keysToSnakeCase(from: EventParams): EventParams {
  if (from === undefined) return from

  const to = {} as typeof from
  for (const key of unwrap.keys(from)) {
    to[toSnakeCase(key)] = from[key]
  }
  return to
}

const pascalCaseRegExp = /([a-z0-9])([A-Z])|\s+/g

function toSnakeCase<T extends string | symbol | number>(from: T): T {
  return from
    .toString()
    .replace(pascalCaseRegExp, '$1_$2')
    .toLowerCase() as T
}
