<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M1.50637 1.66016L14.2461 1.66016"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.85938 4.66016C5.09938 4.66016 2.85938 6.90016 2.85938 9.66016C2.85938 12.4202 5.09938 14.6602 7.85938 14.6602C10.6194 14.6602 12.8594 12.4202 12.8594 9.66016C12.8594 6.90016 10.6244 4.66016 7.85938 4.66016ZM10.5744 11.8752C9.95937 12.7602 8.94938 13.2852 7.87437 13.2852C6.79937 13.2852 5.78938 12.7602 5.17438 11.8752C5.05438 11.7052 5.09937 11.4702 5.26937 11.3552C5.43937 11.2352 5.67437 11.2802 5.78937 11.4502C6.26437 12.1302 7.04437 12.5402 7.87437 12.5402C8.70437 12.5402 9.48438 12.1352 9.95937 11.4502C10.0794 11.2802 10.3094 11.2402 10.4794 11.3552C10.6544 11.4702 10.6944 11.7052 10.5744 11.8752Z"
      fill="currentColor"
    />
  </svg>
</template>
