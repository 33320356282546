import { acceptHMRUpdate, defineStore } from 'pinia'
import { type FontLabel, fontsData } from '@/data/fonts'
import type { Font } from '@/data/fonts'

interface FontsState {
  // Array of the actual Font objs. TODO can probably be removed? the data is in fonts, the already loaded data is in 'loaded'
  fonts: Font[]
  // Array of labels, so we can easily check which fonts are loaded
  loaded: FontLabel[]
}

export const useFontsStore = defineStore('fonts', {
  state: (): FontsState => {
    return {
      fonts: [],
      loaded: [],
    }
  },
  getters: {
    getFontByLabel: (state: FontsState) => (label: FontLabel) => {
      return state.fonts.find((f) => f.label === label)
    },
    hasFinishedLoadedFonts: (state: FontsState) => {
      return state.fonts.length === state.loaded.length
    },
  },
  actions: {
    async initialize() {
      await Promise.all(
        fontsData.fonts.map(async (font: Font) => {
          // If a font is already loaded in the store, return
          if (this.loaded.includes(font.label)) return

          // If the font is marked as already loaded in, add the font and return.
          if (font.preloaded) {
            this.fonts.push(font)
            this.loaded.push(font.label)
            return
          }

          await this.tryLoadFont(font)
        })
      )

      this.sortByLabel()
    },
    async loadFontByLabel(label: FontLabel) {
      //If a font is already loaded, just return.
      if (this.loaded.includes(label)) {
        return
      }

      const font: Font | undefined = fontsData.fonts.find((f: Font) => f.label === label)

      if (font && font.preloaded) return

      if (!font) return

      await this.tryLoadFont(font)
    },
    async tryLoadFont(font: Font) {
      const ff = new FontFace(font.label, font.url)
      try {
        const f = await ff.load()
        this.loaded.push(font.label)
        // @ts-ignore
        document.fonts.add(f)
        this.fonts.push(font)
      } catch (e) {
        if (import.meta.env.PROD) {
          throw e
        } else {
          console.warn(`Loading ${ff.family} failed`, e, ff)
        }
      }
    },
    sortByLabel() {
      this.fonts.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
    },
  },
})

// Allows hot-reloading of the store
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useFontsStore, import.meta.hot))
}
