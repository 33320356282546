<script setup lang="ts">
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import IconSaxDocumentUpload from '@/components/Icons/iconsax/IconSaxDocumentUpload.vue'
import { ref, onMounted } from 'vue'
import { getApiCustomStickers, deleteApiCustomStickersCustomStickerId, postApiCustomStickers } from '@/apis/streamladder-api/custom-stickers/custom-stickers'
import { ScrollArea } from '@/components/ui/scroll-area'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import * as Sentry from '@sentry/vue'
import Tile from '@/components/Tile.vue'
import type { CustomSticker as ApiCustomSticker } from '@/apis/streamladder-api/model'
import { Skeleton } from '@/components/ui/skeleton'
import AppImage from '@/areas/editor/components/AppImage.vue'
import { IconDots } from '@tabler/icons-vue'
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover'
import IconSaxAddSquare from '@/components/Icons/iconsax/IconSaxAddSquare.vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import uploadService from '@/services/uploadService'
import { mbToBytes, formatFileSize } from '@/helpers/fileSize'
import { round } from 'lodash-es'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { Progress } from '@/components/ui/progress'

const tab = defineModel<'library' | 'upload'>('tab', { required: true })


const stickers = ref<ApiCustomSticker[]>([])
const isFetching = ref(false)
async function fetchStickers() {
  isFetching.value = true
  stickers.value = await getApiCustomStickers()
  isFetching.value = false
}

function findStickersByImageUrl(imageUrl: string) {
  const ids = stickersStore.idsWhere(s => s.imageUrl === imageUrl)
  return ids.value
}

const isDeleting = ref<string[]>([])
async function deleteSticker(sticker: ApiCustomSticker) {

  isDeleting.value.push(sticker.id)
  await deleteApiCustomStickersCustomStickerId(sticker.id)

  for (const id of findStickersByImageUrl(sticker.fileUrl)) {
    stickersStore.removeById(id)
  }

  await fetchStickers()
  isDeleting.value = isDeleting.value.filter(id => id !== sticker.id)
}

onMounted(async () => {
  await fetchStickers()
})

const stickersStore = useStickersStore()

async function loadImageByUrl(url: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = () => reject()
    img.src = url
  })
}

async function addStickerToProject(sticker: ApiCustomSticker) {

  try {

    const image = await loadImageByUrl(sticker.fileUrl)
    const drawWidth = 0.8 * 1080
    const drawHeight = image.naturalHeight * (drawWidth / image.naturalWidth)
    
    stickersStore.createCustomSticker({
      imageUrl: sticker.fileUrl,
      naturalHeight: image.naturalHeight,
      naturalWidth: image.naturalWidth,
      area: {
        x: 0.1,
        y: 0.1 + Math.random() * (0.9 - drawHeight / 1920),
        width: drawWidth / 1080,
        height: drawHeight / 1920,
      }
    })
  } catch (e) {
    console.error(e)
  }
}

const isProgressDialogOpen = ref(false)
const error = ref<string | null>()

async function uploadImageFile(event: HTMLInputElement) {

  const inputElement = event.target as HTMLInputElement
  const result = await tryUploadImageFile(inputElement.files?.item(0))
  
  if (result.error) {
    console.error(result.error)
    error.value = result.error
  } else {
    await fetchStickers()
  }

  inputElement.value = null
}

const uploadPercentage = ref(0)
const maxFileSize = mbToBytes(5)
async function tryUploadImageFile(file: File | null) {

  if (!file) {
    return { error: 'No file selected' }
  }

  if (file.size > maxFileSize) {
    return { error: `File is too big. Max file size is ${formatFileSize(maxFileSize, 2)} MB.` }
  }

  try {
    uploadPercentage.value = 0
    isProgressDialogOpen.value = true

    const result = await uploadService.getUploadCustomStickerSignedUrl()
    await uploadService.uploadFileS3(result.signedUrl, file, (progress) => {
      uploadPercentage.value = round(progress, 2)
    })

    await postApiCustomStickers({ fileUrl: result.resultUrl })

    await addStickerToProject(result.resultUrl)
    isProgressDialogOpen.value = false

    return { error: null }
  } catch (e) {
    console.error(e)
    return { error: `File upload failed with error "${e}".` }
  }
}
</script>

<template>
  <section class="flex flex-col gap-4">
    <div class="flex flex-col align-start gap-1 mb-2">
      <header class="hidden md:block">
        <h2 class="text-xl font-semibold leading-snug">Upload local file</h2>
        <p class="font-light">
          Here you can upload your own stickers and they will be added to your clip.
        </p>
      </header>
  
      <Button variant="primary" as="label" class="cursor-pointer">
        <IconSaxDocumentUpload class="w-4 h-4" />
        Upload an image
        <Input
          class="hidden" ref="input" id="custom-sticker-input" 
          type="file" name="custom-sticker" accept="image/png, image/jpeg, image/gif" 
          @input="uploadImageFile"
        />
      </Button>
    </div>
  
    <template v-if="stickers.length > 0 || isFetching">
      <div class="flex flex-col align-start gap-2 min-h-0">
        <h2 class="text-lg font-medium leading-snug">My images</h2>
        <ScrollArea class="min-h-0 overflow-y-auto -mx-4 px-4 2xl:-mx-8 2xl:px-8">
          <div class="grid gap-2 grid-cols-2 lg:grid-cols-[repeat(auto-fill,_minmax(120px,_1fr))]">
            <template v-if="isFetching">
              <Skeleton v-for="i in 3" :key="i" class="aspect-square w-full border border-surface-panel-border" />
            </template>
            <template v-else>
              <template v-for="sticker in stickers" :key="sticker.id">
                <Tile 
                  @click="() => addStickerToProject(sticker)"
                  class="active:scale-90 transition-[transform,_border-color,_background-color] group"
                  v-if="!isDeleting.includes(sticker.id)"
                >
                  <div class="aspect-square max-w-[80%]">
                    <AppImage class="h-full w-full cursor-pointer object-contain" :src="sticker.fileUrl" alt="Custom sticker preview" />
                  </div>
      
                  <Popover>
                    <PopoverTrigger>
                      <div class="rounded px-2 py-0 text-cyan-400 hover:text-cyan-300 absolute right-0 bottom-0 opacity-0 group-hover:opacity-100 transition-all">
                        <IconDots />
                      </div>
                    </PopoverTrigger>
                    <PopoverContent class="flex flex-col p-0 w-auto overflow-hidden border-surface-panel-border layer-2">
                      <Button
                        variant="ghost"
                        class="rounded-none flex items-center hover:bg-zinc-100 font-light cursor-pointer"
                        @click="addStickerToProject(sticker)"
                      >
                        <IconSaxAddSquare class="w-4 h-4" />
                        <span class="mt-0.5">Add to video</span>
                      </Button>
                      <Button
                        variant="ghostDestructive"
                        class="rounded-none flex items-center font-light cursor-pointer"
                        @click.stop="deleteSticker(sticker)"
                      >
                        <IconSaxTrash class="w-4 h-4" />
                        <span class="mt-0.5">Delete from library</span>
                      </Button>
                    </PopoverContent>
                  </Popover>
                </Tile>
                <Skeleton v-else class="aspect-square w-full border border-surface-panel-border" />
              </template>
            </template>
          </div>
        </ScrollArea>
      </div>
    </template>
    <div v-else class="flex flex-col align-start gap-2 min-h-0 justify-center items-center text-center text-sm font-light h-full shadow-inner rounded">
      No stickers found 🙁 Upload a file to get started!
    </div>
    
    <Dialog v-model:open="isProgressDialogOpen">
      <DialogContent>
        <template v-if="error">
          <LottieAnimation url="/lottie/error.json" class="w-20" />
          <h2 class="text-center text-2xl font-bold">Oops!</h2>
          <p class="text-center text-lg font-light opacity-75">{{ error}}</p>
        </template>
        <template v-else>
          <LottieAnimation url="/lottie/rocketLaunch.json" class="w-20" />
          <h2 class="text-center text-2xl font-bold">Hold tight! ✨</h2>
          <p class="text-center text-lg font-light opacity-75">We're saving your custom sticker!</p>
          <Progress :model-value="uploadPercentage" />
        </template>
      </DialogContent>
    </Dialog>
  </section>
</template>

<style scoped lang="scss">

</style>
