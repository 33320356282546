import { ref, computed, toValue } from 'vue'
import type { Word, StoredCaption, StoredWord } from '@/components/Captions/captionTypes'
import type { CaptionStyleSettings } from '@/components/Captions/styles/captionStyles'
import { v4 as uuid } from 'uuid'
import type { MaybeRef } from '@vueuse/shared'

export function useCaptionSettings(
  baseSettings: MaybeRef<CaptionStyleSettings>,
  baseSize? = {
    width: 250,
    height: 100,
  }
) {

  const containerSize = ref<{ width: number; height: number }>({
    width: baseSize.width,
    height: baseSize.height,
  })

  const scale = computed(() => {
    return Math.min(1, containerSize.value?.width / baseSize.width, containerSize.value?.height / baseSize.height)
  })

  const resize = (rect: DOMRect) => {
    containerSize.value = {
      width: rect.width - 20,
      height: rect.height - 20,
    }
  }

  const captionSettings = computed<CaptionStyleSettings>(() => {
    return {
      ...toValue(baseSettings),
      fontSize: {
        ...toValue(baseSettings).fontSize,
        fontSize: toValue(baseSettings).fontSize.fontSize * scale.value || 1,
        scale: toValue(baseSettings).previewSettings?.scaling || 1,
      },
    }
  })

  const word: Word = {
    text: toValue(baseSettings).previewSettings?.displayName || "I'm a caption",
    start: -1,
    end: -1,
    confidence: 1,
    speaker: '',
    Highlighted: false,
  }

  const storedCaption: StoredCaption = {
    id: uuid(),
    words: word.text.split(' ').map(toAnonymousStoredCaptionWord),
    randomizer: Math.random(),
    color: toValue(baseSettings).colors[0],
    ...word,
  }

  const captions = toValue(baseSettings).previewSettings?.previewText?.map(
    (text): StoredCaption => ({
      ...storedCaption,
      words: text.split(' ').map(toAnonymousStoredCaptionWord),
    })
  ) || [storedCaption]

  const animationSpeed = toValue(baseSettings).previewSettings?.animationSpeed ?? 500

  return { captionSettings, captions, animationSpeed, resize }
}

function toAnonymousStoredCaptionWord(text: string): StoredWord {
  return {
    text,
    start: -1,
    end: -1,
    confidence: 1,
    speaker: 'A',
    id: uuid(),
    Highlighted: false,
  }
}
