import { AudioEncoderConfig, getEncoderConfig } from "./get-encoder-config";


export interface WebcodecTestResult {
    supported: boolean;
    hardwareAccelerationWorking?: boolean;
    softwareEncoderWorking?: boolean;
    message?: string;
    timeout?: boolean;
}

let result: WebcodecTestResult | null = null;


export async function runWebcodecTest() : Promise<WebcodecTestResult> {
    if (result) return result;

    if (!globalThis.VideoEncoder || !globalThis.VideoDecoder) {
        return {
            supported: false,
            message: 'VideoEncoder or VideoDecoder api does not exist in this browser',
        }
    }

    // @ts-ignore
    if (!globalThis.AudioEncoder) {
        return {
            supported: false,
            message: 'AudioEncoder api does not exist in this browser',
        }
    }


    //@ts-ignore
    if (!globalThis.OffscreenCanvas || globalThis.OffscreenCanvasPolyfilled) {
        return {
            supported: false,
            message: 'OffscreenCanvas does not exist in this browser',
        }
    }

    const timeoutPromise = new Promise(resolve => {
        setTimeout(() => {
            resolve(null);
        }, 5_000);
    })

    let testResults = null;

    const testPromse = new Promise<WebcodecTestResult>((resolve) => {
        const worker = new Worker(new URL('./webcodec-test-worker.ts', import.meta.url), { type: "module" });
        worker.addEventListener('message', message => {
            if (message.data.type === 'result') {
                testResults = message.data.result;
                resolve(message.data.result);
            }
        })
    });


    await Promise.race([timeoutPromise, testPromse]);


    if (!testResults) {
        return {
            supported: false,
            hardwareAccelerationWorking: false,
            softwareEncoderWorking: false,
            timeout: true,
        }
    }

    return testResults;

}



export async function webCodecTestForWorker() {
    const audioEncoderWorking = await new Promise((resolve) => {

        let resolved = false;
        try {
            //@ts-ignore
            const audioEncoder = new globalThis.AudioEncoder({
                output: () => {
                    if (resolved) return;

                    resolved = true;
                    audioEncoder.flush().then(() => {
                        audioEncoder.close();
                        resolve(true);
                    });
                },
                error: (error: DOMException) => {
                    console.error(error);
                    resolve(false);
                }
            });

            audioEncoder.configure(AudioEncoderConfig);
            // @ts-ignore
            const audioData = new globalThis.AudioData({
                timestamp: 0,
                data: new Float32Array(AudioEncoderConfig.sampleRate * 2),
                format: 'f32-planar',
                sampleRate: AudioEncoderConfig.sampleRate,
                numberOfFrames: AudioEncoderConfig.sampleRate,
                numberOfChannels: 2,
            });
            audioEncoder.encode(audioData)

            audioData.close();
            
        } catch(e) {
            resolve(false);
        }
    })

    const width = 1080;
    const height = 1920;

    const encoderConfig = getEncoderConfig(width, height);


    const canvas = new OffscreenCanvas(width, height);

    const ctx = canvas.getContext('2d');

    if (ctx) {
        ctx.fillStyle = 'red';
        ctx.fillRect(0, 0, width, height)
    }

    const testEncoder = async () => await new Promise<boolean>((resolve) => {
        try {
            const videoEncoder = new VideoEncoder({
                output: () => {
                    videoEncoder.flush().then(() => {
                        videoEncoder.close();
                        resolve(true);
                    });
                },
                error: (error: DOMException) => {
                    console.error(error);
                    resolve(false);
                }
            });


            // @ts-ignore
            videoEncoder.configure(encoderConfig);
            const frame = new VideoFrame(canvas, { timestamp: 0 });

            videoEncoder.encode(frame);
            frame.close();
        } catch(e) {
            resolve(false);
        }
    });

    encoderConfig.hardwareAcceleration = 'prefer-hardware';
    const hardwareAccelerationWorking = await testEncoder();
    encoderConfig.hardwareAcceleration = 'prefer-software';
    const softwareEncoderWorking = await testEncoder();

    result = { supported: hardwareAccelerationWorking || softwareEncoderWorking, hardwareAccelerationWorking, softwareEncoderWorking, audioEncoderWorking };

    postMessage({ type: 'result', result })

    close();
}