<script setup lang="ts">
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import type { CaptionStyle } from '@/components/Captions/styles/CaptionStyleManager'
import CaptionStyleSelector from '@/areas/editor/pages/captions/CaptionStyleSelector.vue'
import CaptionStyleSettings from '@/areas/editor/pages/captions/CaptionStyleSettings.vue'
import { ref } from 'vue'
import CaptionsGenerator from '@/areas/editor/pages/captions/CaptionsGenerator.vue'
import { canGuardWithPopup } from '@/Hooks/useGuard'
import { AlertDialog, AlertDialogContent } from '@/components/ui/alert-dialog'

const editorCaptionsStore = useEditorCaptionsStore()

const isGenerateDialogOpen = ref(false)

function onSelect(style: CaptionStyle) {
  if (canGuardWithPopup('captions')) {
    editorCaptionsStore.captionStyle = style
    isGenerateDialogOpen.value = true
  }
}
</script>

<template>
  <div class="flex flex-col gap-4 p-4 pb-0 2xl:p-8 h-full sm:h-screen">
    <div class="relative h-full">
      <Transition
        enter-from-class="opacity-0 translate-x-6" leave-to-class="opacity-0 -translate-x-6"
        enter-active-class="motion-safe:transition-[transform,_opacity]"
        leave-active-class="motion-safe:transition-[transform,_opacity]"
      >
        <div class="flex flex-col gap-4 absolute inset-0" :key="!editorCaptionsStore.captionsGenerated ? 'style' : 'edit'">
          <CaptionStyleSelector v-if="!editorCaptionsStore.captionsGenerated" @select="onSelect" />
          <CaptionStyleSettings v-else />
        </div>
      </Transition>
    </div>
    <AlertDialog v-model:open="isGenerateDialogOpen">
      <AlertDialogContent>
        <CaptionsGenerator @done="isGenerateDialogOpen = false" />
      </AlertDialogContent>
    </AlertDialog>
  </div>
</template>

<style scoped lang="scss">

</style>
