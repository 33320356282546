<template>
  <div :class="color" class="generic-b">
    <div class="logo">
      <sticker-icons :icon="icon" />
    </div>

    <div class="text-wrapper">
      <TextSticker
        ref="sticker"
        variant="none"
        font-family="Staatliches"
        @update-rect="(e) => $emit('updateRect', e)"
        @update-content="(e) => $emit('updateContent', e)"
        @update="(e) => $emit('update', e)"
        :html-content="htmlContent"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const sticker = ref()
defineExpose({
  setEditMode: () => {
    sticker.value.setEditMode()
  },
})
</script>

<script>
import StickerIcons from '../../StickerIcons.vue'
import TextSticker from '@/components/Stickers/TextSticker.vue'

export default {
  components: {
    TextSticker,
    StickerIcons,
  },
  props: {
    htmlContent: {
      type: String,
      default: '',
    },
    // Either twitch or youtube
    icon: {
      type: String,
      default: 'twitch',
    },
    color: {
      type: String,
      default: 'black',
    },
    setEditMode: {
      type: Function,
      required: false,
    },
  },
  mounted() {
    this.$emit('stickerLoaded', this)
  },
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'Staatliches';
  font-style: normal;
  font-weight: 400;
  src: local('Staatliches'), url('/fonts/Staatliches/Staatliches-Regular.woff') format('woff');
}

.black {
  --background-color: #000;
  --font-color: #fff;
  --drop-shadow: drop-shadow(-3px 3px 1px #ff2a2a) drop-shadow(3px -3px 1px #2a4cff);
}

.blue {
  --background-color: #223ecf;
  // --drop-shadow: drop-shadow(-3px 3px 1px #000000) drop-shadow(3px -3px 1px #000000);
  --drop-shadow: drop-shadow(-3px 3px 1px rgba(0, 0, 0, 0.45)) drop-shadow(2px -2px 0.5px rgba(0, 0, 0, 1));
  --font-color: #fff;
}

.red {
  --background-color: #ff0000;
  --drop-shadow: drop-shadow(-3px 3px 1px rgba(0, 0, 0, 0.45)) drop-shadow(2px -2px 0.5px rgba(0, 0, 0, 1));
  --font-color: #fff;

  // > .text-wrapper {
  //   filter: drop-shadow(-3px 3px 1px #FF2A2A) drop-shadow(3px -3px 1px #2A4CFF);
  //   filter: drop-shadow(-3px 3px 1px #0000) drop-shadow(3px -3px 1px #0000);
  // }

  // > .logo {
  //   filter: drop-shadow(-3px 3px 1px #0000) drop-shadow(3px -3px 1px #0000);
  // }
}

.generic-b {
  display: inline-flex;
  white-space: nowrap;
  line-height: 0;
  user-select: none;
  background: var(--background-color);

  width: auto;
  padding: 0.5em;
  color: var(--font-color);

  * {
    box-sizing: content-box;
  }

  > .text-wrapper {
    display: flex;
    align-items: center;
    margin-left: 0.3em;

    font-family: 'Staatliches', sans-serif;
    filter: var(--drop-shadow);

    .none {
      padding-left: 2px;
      font-weight: bold;
      font-style: normal;
      font-size: 23px;
      line-height: 23px;
    }
  }

  > .logo {
    display: flex;
    align-items: center;

    > svg {
      width: 1.5em;

      filter: var(--drop-shadow);
    }
  }
}
</style>
