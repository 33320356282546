<template>
  <div :class="color" class="generic-d">
    <div class="logo">
      <sticker-icons :icon="icon" />
    </div>
    <div class="text-wrapper">
      <TextSticker
        ref="sticker"
        variant="none"
        @update-rect="(e) => $emit('updateRect', e)"
        @update-content="(e) => $emit('updateContent', e)"
        @update="(e) => $emit('update', e)"
        :html-content="htmlContent"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const sticker = ref()
defineExpose({
  setEditMode: () => {
    sticker.value.setEditMode()
  },
})
</script>

<script>
import StickerIcons from '../../StickerIcons.vue'
import TextSticker from '@/components/Stickers/TextSticker.vue'

export default {
  components: {
    TextSticker,
    StickerIcons,
  },
  props: {
    htmlContent: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'purple',
    },
    icon: {
      type: String,
      default: 'twitch',
    },
    setEditMode: {
      type: Function,
      required: false,
    },
  },
  mounted() {
    this.$emit('stickerLoaded', this)
  },
}
</script>

<style lang="scss" scoped>
.purple {
  --background: #9d38fe;
  --font-color: #fff;
}

.aqua {
  --background: #1bbdf2;
  --font-color: #fff;
}

.black {
  --background: #000;
  --font-color: #fff;
}

.blue {
  --background: #4267b2;
  --font-color: #fff;
}

.red {
  --background: #ff0000;
  --font-color: #fff;
}

.generic-d {
  display: inline-flex;
  white-space: nowrap;
  line-height: 0;
  user-select: none;

  background: var(--background);
  border-radius: 10px;

  width: auto;
  padding: 0.5em;

  * {
    box-sizing: content-box;
  }

  > .text-wrapper {
    display: flex;
    align-items: center;
    margin-left: 0.3em;

    font-family: 'Metropolis';

    .none {
      font-weight: bold;
      font-style: normal;
      font-size: 23px;
      line-height: 23px;
      padding-left: 2px;
      color: var(--font-color);
    }
  }

  > .logo {
    display: flex;
    align-items: center;
    color: var(--font-color);

    > svg {
      width: 1.5em;
      height: auto;
    }
  }
}
</style>
