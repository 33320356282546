import EventBus from '@/eventBus'
import ToastEvents from '@/events/toastEvents'
import logging from '@/logging'

export type ToastEventType = typeof ToastEvents[keyof typeof ToastEvents];

export type Toast = {
  type: ToastEventType
  title: string
  subtitle?: string
  view?: () => void
  viewTitle?: string
  retry?: () => void
  retryTitle?: string
  timeout?: number | null
};

export const useToast = () => {

  const showToast = (props: Toast) => {
    EventBus.$emit(props.type, {
      title: props.title,
      subtitle: props.subtitle,
      view: props.view,
      viewTitle: props.viewTitle,
      retry: props.retry,
      retryTitle: props.retryTitle,
      timeout: props.timeout
    });
  }

  const dismissPremiumToasts = () => {
    EventBus.$emit(ToastEvents.DISMISS_PREMIUM_TOASTS);
  }

  return {
    showToast,
    dismissPremiumToasts
  }
}
