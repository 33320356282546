import type { LayoutWithCrops, Crop } from '@/areas/editor/@type/Project'
import { baseColors, calculateFeedSize } from '@/areas/editor/@data/prefabs/_helpers'
import { contain } from '@/modules/SLMovable/helpers/fit'

export function circle(videoWidth: number, videoHeight: number): LayoutWithCrops {
  return {
    id: 'circle',
    name: 'Circle facecam',
    presetId: 'circle',
    crops: [
      facecam(videoWidth, videoHeight),
      gameplay(videoWidth, videoHeight),
    ]
  }
}

function facecam(videoWidth: number, videoHeight: number): Omit<Crop, 'layoutId'> {

  const size = 1 / 3
  const aspectRatio = 1

  const { width, height } = contain(
    { width: 1, height: 1 / aspectRatio },
    { width: videoWidth * size, height: videoHeight * size })


  const { width: feedWidth, height: feedHeight } = calculateFeedSize(width, height)
  const scaledFeedWidth = feedWidth * 0.5
  const scaledFeedHeight = feedHeight * 0.5
  
  return {
    id: 'circle:facecam',
    x: (videoWidth - width - 20) / videoWidth,
    y: (videoHeight - height - 20) / videoHeight,
    width: width / videoWidth,
    height: height / videoHeight,
    z: 2,
    feedData: { 
      x: 0.5 - 0.5 * scaledFeedWidth,
      y: 0.25 - 0.5 * scaledFeedHeight,
      width: scaledFeedWidth,
      height: scaledFeedHeight,
    },
    input: {
      name: 'Facecam',
      color: baseColors.facecamColor,
      shape: 'circle'
    }
  }
}

function gameplay(videoWidth: number, videoHeight: number): Omit<Crop, 'layoutId'> {

  const size = 0.9
  const aspectRatio = 1

  const { width, height } = contain(
    { width: 1, height: 1 / aspectRatio },
    { width: videoWidth * size, height: videoHeight * size })

  const { width: feedWidth, height: feedHeight } = calculateFeedSize(width, height)

  return {
    id: 'circle:gameplay',
    x: (0.5 * videoWidth - 0.5 * width) / videoWidth,
    y: (0.5 * videoHeight - 0.5 * height) / videoHeight,
    width: width / videoWidth,
    height: height / videoHeight,
    z: 1,
    feedData: {
      x: 0.5 - 0.5 * feedWidth,
      y: 0.5 - 0.5 * feedHeight,
      width: feedWidth,
      height: feedHeight,
    },
    input: {
      name: 'Gameplay',
      color: baseColors.gameplayColor,
      shape: 'freeform'
    }
  }
}
