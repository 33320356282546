import { SocialMedia } from '@/apis/streamladder-publisher/model'

const connectionTypes = Object.freeze({
  YOUTUBE: 'youtube',
  TIKTOK: 'tiktok',
  INSTAGRAM: 'instagram',
} as const)
export type ConnectionType = (typeof connectionTypes)[keyof typeof connectionTypes]

export const connectionApiTypes = Object.freeze({
  YOUTUBE: 'youTubeShorts',
  TIKTOK: 'tikTok',
  INSTAGRAM: 'instagram',
} as const)
export type ConnectionApiType = (typeof connectionApiTypes)[keyof typeof connectionApiTypes]

export const mapConnectionTypeToApi = (ct: ConnectionType) => {
  switch (ct) {
    case connectionTypes.TIKTOK:
      return connectionApiTypes.TIKTOK
    case connectionTypes.YOUTUBE:
      return connectionApiTypes.YOUTUBE
    case connectionTypes.INSTAGRAM:
      return connectionApiTypes.INSTAGRAM

    default:
      throw new Error(`Unknown 'connectionTypes': ${ct}`)
  }
}

export const mapApiToConnectionType = (ct: ConnectionApiType) => {
  switch (ct) {
    case connectionApiTypes.TIKTOK:
      return connectionTypes.TIKTOK
    case connectionApiTypes.YOUTUBE:
      return connectionTypes.YOUTUBE
    case connectionApiTypes.INSTAGRAM:
      return connectionTypes.INSTAGRAM

    default:
      throw new Error(`Unknown 'connectionApiTypes': ${ct}`)
  }
}

export const mapNewApiToConnectionType = (sm: (typeof SocialMedia)[keyof typeof SocialMedia]) => {
  switch (sm) {
    case SocialMedia.Tiktok:
      return connectionTypes.TIKTOK
    case SocialMedia.Youtube:
      return connectionTypes.YOUTUBE
    case SocialMedia.Instagram:
      return connectionTypes.INSTAGRAM

    default:
      throw new Error(`Unknown 'SocialMedia': ${sm}`)
  }
}

const connectionTypeToSocialMediaMapper = {
  instagram: 'Instagram',
  tiktok: 'Tiktok',
  youtube: 'Youtube',
} as const satisfies Record<ConnectionType, SocialMedia>
type ConnectionTypeToSocialMediaMapper = typeof connectionTypeToSocialMediaMapper
export const mapConnectionTypeToSocialMedia = <T extends keyof ConnectionTypeToSocialMediaMapper>(
  type: T
): ConnectionTypeToSocialMediaMapper[T] => {
  return connectionTypeToSocialMediaMapper[type]
}

export default connectionTypes
