/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/vue-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  QueryKey,
  UseQueryReturnType,
} from '@tanstack/vue-query'
import { unref } from 'vue'
import type { MaybeRef } from '@tanstack/vue-query/build/lib/types'
import type {
  CreateUploadedVideoResponseDtoApiResponse,
  CreateUploadedVideoDto,
  UploadedVideoApiResponse,
  UpdateUploadedVideoDto,
  UploadedVideoListApiResponse,
} from '.././model'
import { streamLadderAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never

export const postApiUploadedVideosVideoId = (
  videoId: MaybeRef<string>,
  createUploadedVideoDto: MaybeRef<CreateUploadedVideoDto>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  return streamLadderAxiosInstance<CreateUploadedVideoResponseDtoApiResponse>(
    {
      url: `/api/UploadedVideos/${unref(videoId)}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createUploadedVideoDto,
    },
    options
  )
}

export const getPostApiUploadedVideosVideoIdMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiUploadedVideosVideoId>>,
    TError,
    { videoId: string; data: CreateUploadedVideoDto },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiUploadedVideosVideoId>>,
  TError,
  { videoId: string; data: CreateUploadedVideoDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiUploadedVideosVideoId>>,
    { videoId: string; data: CreateUploadedVideoDto }
  > = (props) => {
    const { videoId, data } = props ?? {}

    return postApiUploadedVideosVideoId(videoId, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiUploadedVideosVideoIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiUploadedVideosVideoId>>
>
export type PostApiUploadedVideosVideoIdMutationBody = CreateUploadedVideoDto
export type PostApiUploadedVideosVideoIdMutationError = void

export const usePostApiUploadedVideosVideoId = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiUploadedVideosVideoId>>,
    TError,
    { videoId: string; data: CreateUploadedVideoDto },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const mutationOptions = getPostApiUploadedVideosVideoIdMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiUploadedVideosVideoId = (
  videoId: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  return streamLadderAxiosInstance<UploadedVideoApiResponse>(
    { url: `/api/UploadedVideos/${unref(videoId)}`, method: 'get', signal },
    options
  )
}

export const getGetApiUploadedVideosVideoIdQueryKey = (videoId: MaybeRef<string>) =>
  ['api', 'UploadedVideos', videoId] as const

export const getGetApiUploadedVideosVideoIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiUploadedVideosVideoId>>,
  TError = void
>(
  videoId: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiUploadedVideosVideoId>>, TError, TData>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getApiUploadedVideosVideoId>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiUploadedVideosVideoIdQueryKey(videoId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUploadedVideosVideoId>>> = ({ signal }) =>
    getApiUploadedVideosVideoId(videoId, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!videoId, staleTime: 60000, ...queryOptions }
}

export type GetApiUploadedVideosVideoIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiUploadedVideosVideoId>>
>
export type GetApiUploadedVideosVideoIdQueryError = void

export const useGetApiUploadedVideosVideoId = <
  TData = Awaited<ReturnType<typeof getApiUploadedVideosVideoId>>,
  TError = void
>(
  videoId: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiUploadedVideosVideoId>>, TError, TData>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiUploadedVideosVideoIdQueryOptions(videoId, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}

export const putApiUploadedVideosVideoId = (
  videoId: MaybeRef<string>,
  updateUploadedVideoDto: MaybeRef<UpdateUploadedVideoDto>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  return streamLadderAxiosInstance<UploadedVideoApiResponse>(
    {
      url: `/api/UploadedVideos/${unref(videoId)}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateUploadedVideoDto,
    },
    options
  )
}

export const getPutApiUploadedVideosVideoIdMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiUploadedVideosVideoId>>,
    TError,
    { videoId: string; data: UpdateUploadedVideoDto },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiUploadedVideosVideoId>>,
  TError,
  { videoId: string; data: UpdateUploadedVideoDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiUploadedVideosVideoId>>,
    { videoId: string; data: UpdateUploadedVideoDto }
  > = (props) => {
    const { videoId, data } = props ?? {}

    return putApiUploadedVideosVideoId(videoId, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutApiUploadedVideosVideoIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiUploadedVideosVideoId>>
>
export type PutApiUploadedVideosVideoIdMutationBody = UpdateUploadedVideoDto
export type PutApiUploadedVideosVideoIdMutationError = void

export const usePutApiUploadedVideosVideoId = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiUploadedVideosVideoId>>,
    TError,
    { videoId: string; data: UpdateUploadedVideoDto },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const mutationOptions = getPutApiUploadedVideosVideoIdMutationOptions(options)

  return useMutation(mutationOptions)
}
export const deleteApiUploadedVideosVideoId = (
  videoId: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  return streamLadderAxiosInstance<void>({ url: `/api/UploadedVideos/${unref(videoId)}`, method: 'delete' }, options)
}

export const getDeleteApiUploadedVideosVideoIdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiUploadedVideosVideoId>>,
    TError,
    { videoId: string },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiUploadedVideosVideoId>>,
  TError,
  { videoId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiUploadedVideosVideoId>>,
    { videoId: string }
  > = (props) => {
    const { videoId } = props ?? {}

    return deleteApiUploadedVideosVideoId(videoId, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteApiUploadedVideosVideoIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiUploadedVideosVideoId>>
>

export type DeleteApiUploadedVideosVideoIdMutationError = unknown

export const useDeleteApiUploadedVideosVideoId = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiUploadedVideosVideoId>>,
    TError,
    { videoId: string },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const mutationOptions = getDeleteApiUploadedVideosVideoIdMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiUploadedVideos = (
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  return streamLadderAxiosInstance<UploadedVideoListApiResponse>(
    { url: `/api/UploadedVideos`, method: 'get', signal },
    options
  )
}

export const getGetApiUploadedVideosQueryKey = () => ['api', 'UploadedVideos'] as const

export const getGetApiUploadedVideosQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiUploadedVideos>>,
  TError = void
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiUploadedVideos>>, TError, TData>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof getApiUploadedVideos>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiUploadedVideosQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUploadedVideos>>> = ({ signal }) =>
    getApiUploadedVideos(requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions }
}

export type GetApiUploadedVideosQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUploadedVideos>>>
export type GetApiUploadedVideosQueryError = void

export const useGetApiUploadedVideos = <
  TData = Awaited<ReturnType<typeof getApiUploadedVideos>>,
  TError = void
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiUploadedVideos>>, TError, TData>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiUploadedVideosQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}
