<script setup lang="ts">
import { type ImgHTMLAttributes, ref } from 'vue'
import IconSaxWarning2 from '@/components/Icons/iconsax/IconSaxWarning2.vue'
import { clsx } from 'clsx'

interface Props extends /* @vue-ignore */ ImgHTMLAttributes {
  src: string
}

const props = defineProps<Props>()
const isLoaded = ref(false)
const isError = ref(false)
</script>

<template>
  <img ref="image" v-if="!isError"
    :src="src" v-bind="props"
    :class="clsx('transition-[transform,_opacity]', { 'opacity-0 scale-75': !isLoaded && !isError }, props.class)"
    @load="isLoaded = true"
    @error="isError = true"
    loading="lazy"
  />
  <IconSaxWarning2 v-else
    :class="clsx('w-8 h-8 text-red-500 transition-[transform,_opacity]', props.class)" 
  />
</template>

<style scoped lang="scss">

</style>
