import type { Area } from '@/modules/SLMovable/@types/Movable'
import { defineStore } from 'pinia'
import { useEntityStore } from '@/areas/editor/store/useEntityStore'
import { computed } from 'vue'
import { clamp, omit, sortBy } from 'lodash-es'
import { v4 as uuid } from 'uuid'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { useEditorFocusStore, FocusTypes } from '@/store/editor/editorFocus'
import type { Sticker as ApiSticker } from '@/apis/streamladder-api/model'
import type { TypedSticker, Sticker } from '@/areas/editor/@type/Project'
import { selectStickerFont } from '@/areas/editor/store/selectors/selectStickerFont'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import type { StickerData } from '@/store/editor/editorStickers'

export const useStickersStore = defineStore('stickers', () => {

  const historyStore = useHistoryStore()
  const { state, ids, entities, operations } 
    = useEntityStore<TypedSticker>()

  const videoStore = useVideoStore()
  const durationMs = computed(() => videoStore._duration * 1000)
  
  const editorFocusStore = useEditorFocusStore()
  
  return {
    state: state,
    ids: ids,
    entities: entities,
    
    ...operations,

    duplicateById(id: string) {

      const sticker = state[id]
      const randomDistance = Math.random() * 0.15

      function clampX(area: Area) {
        if (area.x + area.width + randomDistance <= 1) {
          return area.x + randomDistance
        } else if (area.x - randomDistance >= 0) {
          return area.x - randomDistance
        } else {
          return area.x
        }
      }

      function clampY(area: Area) {
        if (area.y + area.height + randomDistance <= 1) {
          return area.y + randomDistance
        } else if (area.y - randomDistance >= 0) {
          return area.y - randomDistance
        } else {
          return area.y
        }
      }

      const duplicateId = uuid()
      this.createById(duplicateId, {
        ...omit(sticker, 'id'),
        area: {
          x: clampX(sticker.area),
          y: clampY(sticker.area),
          width: sticker.area.width,
          height: sticker.area.height,
        }
      })

      return duplicateId
    },

    async shift(id: string, amount: number) {

      const stickers = sortBy(entities.value, 'z')

      const from = state[id].z
      const to = clamp(from + amount, 0, stickers.length - 1)

      if (from === to) return

      for (const id of ids.value) {
        if (Math.sign(amount) === -1) {
          if (state[id].z >= to && state[id].z <= from) {
            state[id].z++
          }
        } else {
          if (state[id].z <= to && state[id].z >= from) {
            state[id].z--
          }
        }
      }

      state[id].z = to
      
      await historyStore.push()
    },

    moveToBackground(id: string) {
      this.shift(id, -Infinity)
    },

    moveToForeground(id: string) {
      this.shift(id, Infinity)
    },
    
    createTextSticker(payload: PartialBy<Omit<TypedSticker<'text'>, | 'id' | 'z' | 'type'>, 'scale' | 'area' | 'naturalWidth' | 'naturalHeight' | 'startMs' | 'endMs' | 'editing'>) {
      const id = uuid()
      this.createById<TypedSticker<'text'>>(id, {
        type: 'text',
        scale: undefined,
        area: { x: 0.25, y: 0.5, width: 0.5, height: 0.25 },
        naturalWidth: 0,
        naturalHeight: 0,
        z: ids.value.length,
        startMs: 0,
        endMs: durationMs.value,
        editing: true,
        ...payload
      })

      return id
    },

    createSocialSticker(payload: PartialBy<Omit<TypedSticker<'social'>, | 'id' | 'z' | 'type'>, 'scale' | 'area' | 'naturalWidth' | 'naturalHeight' | 'startMs' | 'endMs' | 'editing'>) {
      const id = uuid()
      this.createById<TypedSticker<'social'>>(id, {
        type: 'social',
        scale: undefined,
        area: { x: 0.125, y: 0.5, width: 0.75, height: 0.25 },
        naturalWidth: 0,
        naturalHeight: 0,
        z: ids.value.length,
        startMs: 0,
        endMs: durationMs.value,
        editing: false,
        imageUrl: '',
        ...payload
      })

      return id
    },
    
    createCustomSticker(payload: PartialBy<Omit<TypedSticker<'custom'>, | 'id' | 'z' | 'key' | 'type'>, 'scale' | 'area' | 'naturalWidth' | 'naturalHeight' | 'startMs' | 'endMs'>) {
      const id = uuid()
      this.createById<TypedSticker<'custom'>>(id, {
        type: 'custom',
        key: 'custom-sticker',
        scale: undefined,
        area: { x: 0.25, y: 0.5, width: 0.5, height: 0.25 },
        naturalWidth: 0,
        naturalHeight: 0,
        z: ids.value.length,
        startMs: 0,
        endMs: durationMs.value,
        ...payload
      })

      return id
    },
    
    createBrandKitSticker(payload: PartialBy<Omit<TypedSticker<'brand-kit'>, | 'id' | 'z' | 'type'>, 'scale' | 'area' | 'naturalWidth' | 'naturalHeight' | 'startMs' | 'endMs' | 'editing'>) {
      const id = uuid()
      this.createById<TypedSticker<'brand-kit'>>(id, {
        type: 'brand-kit',
        scale: undefined,
        area: { x: 0.25, y: 0.5, width: 0.5, height: 0.25 },
        naturalWidth: 0,
        naturalHeight: 0,
        z: ids.value.length,
        startMs: 0,
        endMs: durationMs.value,
        editing: false,
        ...payload
      })

      return id
    },

    createGiphySticker(payload: PartialBy<Omit<TypedSticker<'giphy'>, | 'id' | 'z' | 'type'>, 'scale' | 'area' | 'naturalWidth' | 'naturalHeight' | 'startMs' | 'endMs'>) {
      const id = uuid()
      this.createById(id, {
        type: 'giphy',
        area: { x: 0.25, y: 0.5, width: 0.5, height: 0.25 },
        naturalWidth: 0,
        naturalHeight: 0,
        z: ids.value.length,
        startMs: payload.startMs ?? 0,
        endMs: payload.endMs ?? durationMs.value,
        ...payload,
      })
      return id
    },

    updateTimingsById(id: string, startMs: number, endMs: number) {
      state[id].startMs = Math.round(startMs)
      state[id].endMs = Math.round(endMs)
    },

    listImageUrls() {
      return computed(() => {
        const urls = [] as string[]
        for (const sticker of entities.value) {
          if ('imageUrl' in sticker && sticker.imageUrl) {
            urls.push(sticker.imageUrl)
          }
        }
        return urls
      })
    },

    splitStickerById(id: string | undefined, ms: number) {

      if (!id) return

      const sticker = state[id]

      // Sticker must begin 500ms before the split time and end 500ms after the split time.
      if (!sticker || ms - sticker.startMs < 100 || sticker.endMs - ms < 100) return

      const newSticker = { ...sticker, startMs: ms, id: uuid() }
      sticker.endMs = ms
      this.createById(newSticker.id, newSticker)
    },
    

    selectFocusTypeById(id: string) {
      const sticker = state[id]
      return computed(() => isTextSticker(sticker) ? FocusTypes.TEXTSTICKER : FocusTypes.STICKER)
    },
    
    whereFocusTypeIs(type: 'sticker' | 'textsticker') {
      return computed(() => {
        if (type === 'sticker') {
          return entities.value.filter(s => !isTextSticker(s))
        } else {
          return entities.value.filter(s => isTextSticker(s))
        }
      })
    },

    selectFocusById(id: string) {
      const focusType = this.selectFocusTypeById(id)
      return computed(() => {
        if (editorFocusStore.focus) {
          return editorFocusStore.focus.type === focusType.value && editorFocusStore.focus.key === id
        } else {
          return false
        }
      })
    },

    setEditModeById(id: string, editing = true) {
      const sticker = state[id]
      if (sticker && 'editing' in sticker) {
        this.focus(id)
        sticker.editing = editing
      }
    },

    focus(id: string) {
      const focusType = this.selectFocusTypeById(id)
      editorFocusStore.setFocus(focusType.value, id)
    },

    selectStickersForStorage(settings: { text: boolean, stickers: boolean }): ApiSticker[] {
      return entities.value
        .filter(s => (settings.stickers && !isGiphySticker(s)) || (settings.stickers && !isTextSticker(s)) || (settings.text && isTextSticker(s)))
        .map(toApiSticker)
    },

    selectFontById(id: string) {
      const sticker = state[id]
      return sticker ? selectStickerFont(sticker) : null
    },

    pullSticker(id: string, direction: 'left' | 'right', ms: number) {

      const sticker = state[id]
      if (!sticker) return

      if (direction === 'left') {
        sticker.endMs = Math.min(sticker.endMs, ms)
      } else if (direction === 'right') {
        sticker.startMs = Math.max(sticker.startMs, ms)
      }
    },
  }
})

const toApiSticker = (sticker: Sticker): ApiSticker & { width: number, height: number } => ({
  x: sticker.area.x,
  y: sticker.area.y,
  width: sticker.area.width,
  height: sticker.area.height,
  key: sticker.id,
  scale: (sticker.area.width * 1080 / sticker.naturalWidth) / 1080,
  ...animationTimesForStorage(sticker),
  animationStyle: undefined,
  color: 'color' in sticker ? sticker.color : undefined,
  icon: 'icon' in sticker ? sticker.icon : undefined,
  isTextSticker: isTextSticker(sticker), 
  imageUrl: 'imageUrl' in sticker ? sticker.imageUrl : undefined,
  text: 'textContent' in sticker ? sticker.textContent : undefined,
  visible: true,
  componentName: sticker.key
})


function animationTimesForStorage(sticker: Sticker): Pick<StickerData, 'animationTime' | 'animationDuration'>{

  const videoStore = useVideoStore()
  const videoDurationMs = videoStore._duration * 1000

  const start = sticker.startMs ?? 0
  const end = sticker.endMs ?? videoDurationMs
  const duration = Math.round(end - start)

  if (start === 0 && end === videoDurationMs) {
    // Sticker should span the full video, so no need to store any data.
    return {
      animationTime: undefined,
      animationDuration: undefined,
    }
  } else if (start < (1 / 3) * videoDurationMs) {
    // Sticker is close to the start of the video, so mark it as a start animation.
    return {
      animationTime: 'start',
      animationDuration: duration,
    }
  } else if (start < (2 / 3) * videoDurationMs) {
    // Sticker is in the middle of the video, so mark it as a middle animation.
    return {
      animationTime: 'middle',
      animationDuration: duration,
    }
  } else {
    // Sticker is close to the end of the video, so mark it as an end animation.
    return {
      animationTime: 'end',
      animationDuration: duration,
    }
  }
}

export function isTextSticker(sticker: Sticker) {
  return 'textContent' in sticker && sticker.type !== 'social'
}

export function isGiphySticker(sticker: Sticker) {
  return sticker.imageUrl?.includes('webp')
}

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
