<script setup lang="ts">
import { computed } from 'vue'
import SimpleTooltip from "@/components-v2/data-display/SimpleTooltip.vue";

const props = defineProps<{
  isNew: boolean
  tags?: string[] | null
}>()

const seasonEmoji = computed(() => {
  const seasonalOptions = {
    halloween: '🎃',
    christmas: '🎄',
  }

  if (!props.tags?.includes('seasonal')) return null

  for (const tag of props.tags) {
    if (tag in seasonalOptions) {
      return seasonalOptions[tag as keyof typeof seasonalOptions]
    }
  }

  return null
})

const isGlobal = computed(() => props.tags?.includes('global'))
</script>

<template>
  <span v-if="isNew || seasonEmoji || isGlobal" class="absolute left-2 top-2 flex gap-2">
<!--    <span v-if="isNew" class="badge-success badge text-xs font-bold uppercase">New</span>-->
    <span v-if="seasonEmoji" class="font-bold uppercase drop-shadow">{{ seasonEmoji }}</span>
    <SimpleTooltip v-if="isGlobal">
      <template v-slot:trigger>
       <span class="font-bold uppercase drop-shadow">
         🌍
       </span>
      </template>
      <template v-slot:content>
        <p class="text-center">
          This font supports:<br /> Chinese, Hangul, Kana, Latin, Hindi, Greek and Cyrillic scripts.
        </p>
      </template>
    </SimpleTooltip>
  </span>
</template>

<style scoped lang="scss"></style>
