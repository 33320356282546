import { markRaw, defineAsyncComponent } from 'vue'
import type { Sticker } from '@/components/Stickers/stickerLibrary/stickerLibrary'
import HappyHalloween from '@/components/Stickers/stickerLibrary/seasonal/halloween/components/HappyHalloween.vue'
import Bat from '@/components/Stickers/stickerLibrary/seasonal/halloween/components/Bat.vue'
import Pumpkin from '@/components/Stickers/stickerLibrary/seasonal/halloween/components/Pumpkin.vue'
import Ghost from '@/components/Stickers/stickerLibrary/seasonal/halloween/components/Ghost.vue'
import TrickOrTreat from '@/components/Stickers/stickerLibrary/seasonal/halloween/components/TrickOrTreat.vue'
import Cobweb from '@/components/Stickers/stickerLibrary/seasonal/halloween/components/Cobweb.vue'

const areHalloweenStickersAvailable = () => {
  return new Date().getMonth() === 9
}

const halloweenStickersCreatedAt = new Date()

export const halloweenStickers = areHalloweenStickersAvailable()
  ? ([
      {
        key: 'happy-halloween',
        colors: {
          pumpkin: '#F98728',
          blood: '#DA0062',
          witch: '#0FA688',
        },
        tags: ['halloween'],
        component: markRaw(HappyHalloween),
        createdAt: halloweenStickersCreatedAt,
      },
      {
        key: 'bat',
        colors: {
          pumpkin: '#F98728',
          blood: '#DA0062',
          witch: '#0FA688',
        },
        tags: ['halloween'],
        component: markRaw(Bat),
        createdAt: halloweenStickersCreatedAt,
      },
      {
        key: 'pumpkin',
        colors: {
          pumpkin: '#F98728',
          blood: '#DA0062',
          witch: '#0FA688',
        },
        tags: ['halloween'],
        component: markRaw(Pumpkin),
        createdAt: halloweenStickersCreatedAt,
      },
      {
        key: 'ghost',
        colors: {
          pumpkin: '#F98728',
          ghost: '#FFFFFF',
          witch: '#0FA688',
        },
        tags: ['halloween'],
        component: markRaw(Ghost),
        createdAt: halloweenStickersCreatedAt,
      },
      {
        key: 'trick-or-treat',
        colors: {
          pumpkin: '#F98728',
          ghost: '#FFFFFF',
          witch: '#0FA688',
        },
        tags: ['halloween'],
        component: markRaw(TrickOrTreat),
        createdAt: halloweenStickersCreatedAt,
      },
      {
        key: 'cobweb',
        colors: {
          midnight: '#000000',
          ghost: '#FFFFFF',
          witch: '#0FA688',
        },
        tags: ['halloween'],
        component: markRaw(Cobweb),
        createdAt: halloweenStickersCreatedAt,
      },
    ] as const satisfies Readonly<Sticker[]>)
  : ([] as const satisfies Readonly<Sticker[]>)
