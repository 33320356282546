import * as Sentry from '@sentry/browser'

/**
 * Copy from https://github.com/suvro404/video-to-audio but is made compatible with newer JS syntax Vite wants
 */
export default function (videoFileData, targetAudioFormat, trimmedOffset, trimmedDuration) {
  trimmedOffset = Math.max(trimmedOffset, 0)
  targetAudioFormat = targetAudioFormat.toLowerCase()
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onload = function (event) {
      const contentType = 'audio/' + targetAudioFormat
      const audioContext = new AudioContext()
      let myBuffer
      const sampleRate = 16000
      const numberOfChannels = 1
      const videoFileAsBuffer = reader.result
      audioContext
        .decodeAudioData(videoFileAsBuffer)
        .then(function (decodedAudioData) {
          const offlineAudioContext = new OfflineAudioContext(
            numberOfChannels,
            sampleRate * trimmedDuration,
            sampleRate
          )
          const soundSource = offlineAudioContext.createBufferSource()
          myBuffer = decodedAudioData
          soundSource.buffer = myBuffer
          soundSource.connect(offlineAudioContext.destination)
          soundSource.start(undefined, trimmedOffset, trimmedDuration)
          offlineAudioContext
            .startRendering()
            .then(function (renderedBuffer) {
              const UintWave = createWaveFileData(renderedBuffer)
              const b64Data = btoa(uint8ToString(UintWave))
              const blob = getBlobFromBase64Data(b64Data, contentType)

              resolve(blob) // Changed to resolve the blob
            })
            .catch(function (err) {
              Sentry.captureException(err, {
                extra: {
                  targetAudioFormat,
                  trimmedOffset,
                  trimmedDuration,
                },
              })
              reject(err)
            })
        })
        .catch(function (err) {
          Sentry.captureException(err, {
            extra: {
              targetAudioFormat,
              trimmedOffset,
              trimmedDuration,
            },
          })
          reject(err)
        })
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(videoFileData)
  })
}

function getBlobFromBase64Data(b64Data, contentType, sliceSize = 512) {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

function createWaveFileData(audioBuffer) {
  const frameLength = audioBuffer.length
  const numberOfChannels = audioBuffer.numberOfChannels
  const sampleRate = audioBuffer.sampleRate
  const bitsPerSample = 16
  const byteRate = (sampleRate * numberOfChannels * bitsPerSample) / 8
  const blockAlign = (numberOfChannels * bitsPerSample) / 8
  const wavDataByteLength = frameLength * numberOfChannels * 2
  const headerByteLength = 44
  const totalLength = headerByteLength + wavDataByteLength

  const waveFileData = new Uint8Array(totalLength)

  const subChunk1Size = 16
  const subChunk2Size = wavDataByteLength
  const chunkSize = 4 + (8 + subChunk1Size) + (8 + subChunk2Size)

  writeString('RIFF', waveFileData, 0)
  writeInt32(chunkSize, waveFileData, 4)
  writeString('WAVE', waveFileData, 8)
  writeString('fmt ', waveFileData, 12)

  writeInt32(subChunk1Size, waveFileData, 16)
  writeInt16(1, waveFileData, 20)
  writeInt16(numberOfChannels, waveFileData, 22)
  writeInt32(sampleRate, waveFileData, 24)
  writeInt32(byteRate, waveFileData, 28)
  writeInt16(blockAlign, waveFileData, 32)
  writeInt32(bitsPerSample, waveFileData, 34)

  writeString('data', waveFileData, 36)
  writeInt32(subChunk2Size, waveFileData, 40)

  writeAudioBuffer(audioBuffer, waveFileData, 44)

  return waveFileData
}

function writeString(s, a, offset) {
  for (let i = 0; i < s.length; ++i) {
    a[offset + i] = s.charCodeAt(i)
  }
}

function writeInt16(n, a, offset) {
  n = Math.floor(n)

  const b1 = n & 255
  const b2 = (n >> 8) & 255

  a[offset + 0] = b1
  a[offset + 1] = b2
}

function writeInt32(n, a, offset) {
  n = Math.floor(n)
  const b1 = n & 255
  const b2 = (n >> 8) & 255
  const b3 = (n >> 16) & 255
  const b4 = (n >> 24) & 255

  a[offset + 0] = b1
  a[offset + 1] = b2
  a[offset + 2] = b3
  a[offset + 3] = b4
}

function writeAudioBuffer(audioBuffer, a, offset) {
  const n = audioBuffer.length
  const channels = audioBuffer.numberOfChannels

  for (let i = 0; i < n; ++i) {
    for (let k = 0; k < channels; ++k) {
      const buffer = audioBuffer.getChannelData(k)
      let sample = buffer[i] * 32768.0

      if (sample < -32768) sample = -32768
      if (sample > 32767) sample = 32767

      writeInt16(sample, a, offset)
      offset += 2
    }
  }
}

function uint8ToString(buf) {
  let i,
    length,
    out = ''
  for (i = 0, length = buf.length; i < length; i += 1) {
    out += String.fromCharCode(buf[i])
  }
  return out
}
