import type { Clip } from '@/Hooks/useTwitchClips'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { startupVideo, startupWithDefaultLayout, cleanupCaptionStorage } from '@/areas/editor/startup/generalStartupMethods'
import { useWebsocketChannel } from "@/modules/WebsocketService/WebSocketService";
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import type { StartupConfig } from '@/areas/editor/startup/StartupConfig'
import { handleStartupError } from '@/areas/editor/startup/handleStartupError'
import { throwIfBrowserUnsupported } from '@/areas/editor/startup/UnsupportedBrowserError'
import { getApiKickClipsClipId } from '@/apis/streamladder-api/kick-clips/kick-clips';

export async function startupFromKickClipByClipId(clipId: string, config: StartupConfig) {


  const editorClipInfoStore = useEditorClipInfoStore()
  try {
    throwIfBrowserUnsupported()
    editorClipInfoStore.loadingState = {
      state: 'loading',
      description: 'Fetching clip info...'
    }

    const response = await getApiKickClipsClipId(clipId) as unknown as Clip
    config.signal.value?.throwIfAborted()
    if (response.id === null) {
      return await startupFromKickClip({ ...response, id: clipId }, config)
    } else {
      return await startupFromKickClip(response, config)
    }
  } catch (error) {
    return handleStartupError(error)
  }
}

export async function startupFromKickClip(clip: Clip, config: StartupConfig) {
  
  console.log(clip)

  const editorClipInfoStore = useEditorClipInfoStore()
  editorClipInfoStore.id = clip.id
  editorClipInfoStore.title = clip.title ?? 'Kick clip'
  editorClipInfoStore.viewCount = clip.viewCount
  editorClipInfoStore.mp4Url = clip.mp4Url
  editorClipInfoStore.thumbnailUrl = clip.thumbnailUrl
  editorClipInfoStore.source = 'kick-clip'
  editorClipInfoStore.languageCode = clip.languageCode?.toLowerCase() ?? 'en_us'
  editorClipInfoStore.isLocalFile = false

  cleanupCaptionStorage()
  
  // Push all we know about the clip to the history store, so we can resume from 
  // here on reload.
  const historyStore = useHistoryStore()
  await historyStore.push()
  
  config.signal.value?.throwIfAborted()

  editorClipInfoStore.isLoadingClip = true

  const response = await tryDownloadClip(clip, config)
  console.log(response)
  config.signal.value?.throwIfAborted()

  if ('error' in response) {
    if (response.error) {
      editorClipInfoStore.loadingState = {
        state: 'error',
        title: 'Could not import clip 🚫',
        description: 'Try another clip or check back later!'
      };
    }
    return {
      error: response.error
    };
  } else {

    editorClipInfoStore.mp4Url = response.mp4Url
    editorClipInfoStore.title = response.title || 'Kick clip'

    try {
      await startupVideo(config)
      config.signal.value?.throwIfAborted()

      await startupWithDefaultLayout(config)
      config.signal.value?.throwIfAborted()

      editorClipInfoStore.isLoadingClip = false
      editorClipInfoStore.loadingState = null

      // If the startup process was successful, replace the history state
      await historyStore.replace()
      return { error: null }
    } catch (error) {
      return handleStartupError(error)
    }
  }
}

async function tryDownloadClip(clip: Clip, config: StartupConfig) {

  const editorClipInfoStore = useEditorClipInfoStore()

  // If we get an empty response from a Kick clip, we have to listen to the pusher client to retrieve the info needed.
  if (clip.mp4Url === '') {

    editorClipInfoStore.loadingState = {
      state: 'loading',
      description: 'Importing Kick Clip. This may take a few minutes...'
    }

    console.log('Empty response, listening to pusher client')
    try {
      return await listenForClipDownload(clip.id, config)
    } catch (e) {
      return { error: e?.toString() ?? null }
    }
  } else {
    return clip
  }
}

async function listenForClipDownload(clipId: string, config: StartupConfig) {

  const response = await getApiKickClipsClipId(clipId, { signal: config.signal.value ?? undefined }) as unknown as { taskId: string }

  return new Promise<Clip>((resolve, reject) => {

    const channelName = `cache-task-status-${response.taskId}`;
    const timeout = setTimeout(() => {
      channel.value?.disconnect()
      reject(new Error('Kick clip download timed out.'))
    }, 20 * 60 * 1000)

    const channel = useWebsocketChannel(channelName, (eventName, data) => {
      if (config.signal.value?.aborted) {
        channel.value?.disconnect()
        reject(new DOMException('Aborted', 'AbortError'))
        clearTimeout(timeout)
        return
      }

      if (eventName === 'progress') {
        if (data.status === 'finished') {
          resolve(data as Clip)
          clearTimeout(timeout)
          return
        } else if (data.status === 'error') {
          reject(data.message)
          clearTimeout(timeout)
        }
      }
    })
  })
}
