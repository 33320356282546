/**
 * Execute a function for every item in a list. The function is executed in groups of a certain size. This is useful 
 * when you want to limit the amount of concurrent requests. Resolves when all items have been processed.
 */
export async function batchAsync<T>(items: T[], callback: (item: T) => Promise<void>, options?: { batchSize?: number }) {
  const bundleSize = options?.batchSize ?? 3
  const amountOfGroups = Math.ceil(items.length / bundleSize)
  for (let i = 0; i < amountOfGroups; i++) {
    const skip = i * bundleSize
    const group = items.slice(skip, skip + bundleSize)
    await Promise.all(group.map(callback))
  }
}
