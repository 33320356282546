<script setup lang="ts">
import { computed, toRaw, onMounted } from 'vue'
import GiphyElement from '@/components/Stickers/GiphyElement.vue'
import textLibrary from '@/components/Stickers/textLibrary'
import stickerLibrary from '@/components/Stickers/stickerLibrary/stickerLibrary'
import type { ClassValue } from 'clsx'
import { omit } from 'lodash-es'
import { useFontsStore } from '@/store/fonts'
import type { Sticker } from '@/areas/editor/@type/Project'
import { selectStickerFont } from '@/store/entity-system/selectors/selectStickerFont'

const props = defineProps<{
  sticker: Sticker & { scale: number }, class?: ClassValue,
  renderWidth: number, renderHeight: number,
  focus?: boolean
}>()

const stickerMetaData = computed(() => {
  if (props.sticker.type === 'giphy') {
    return { component: toRaw(GiphyElement) }
  } else {
    return [...textLibrary, ...stickerLibrary].find(({ key }) => key === props.sticker.key)
  }
})

const stickerBinding = computed(() => {
  return {
    ...omit(props.sticker, ['key', 'area', 'id', 'z', 'type', 'textContent', 'scale']),
    ...omit(stickerMetaData.value, ['component', 'key', 'colors', 'createdAt', 'tags', 'title']),
  }
})

const fontsStore = useFontsStore()
onMounted(async () => {
  const font = selectStickerFont(props.sticker)
  if (font) {
    console.time(`Loading font "${font}"`)
    await fontsStore.loadFontByLabel(font)
    console.timeEnd(`Loading font "${font}"`)
  }
})
</script>

<template>
  <div class="origin-top-left absolute light" :class="props.class"
    v-if="stickerMetaData?.component"
    :style="{ 
      transform: `scale(${(sticker.scale ?? 0) * props.renderWidth})`,
      top: sticker.area.y * 100 + '%',
      left: sticker.area.x * 100 + '%', 
    }"
  >
    <component ref="componentRef"
      class="whitespace-pre [&_*]:!whitespace-pre pointer-events-none"
      :is="stickerMetaData.component"
      :htmlContent="'textContent' in sticker ? sticker.textContent : null"
      v-bind="stickerBinding"
    />
  </div>
</template>

<style scoped lang="scss">

</style>
