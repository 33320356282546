import type { Bounds, Point } from '@/modules/SLMovable/@types/Movable'

export function keepPointInsideBounds(source: Point, bounds: Bounds): Point {
  return {
    x: keepPointInsideBoundsX(source.x, bounds),
    y: keepPointInsideBoundsY(source.y, bounds),
  }
}

export function keepPointInsideBoundsX(
  source: number,
  bounds: Omit<NonNullable<Bounds>, 'top' | 'bottom'> | null
): number {

  let result = source

  if (bounds !== null && bounds.left !== null) {
    result = Math.max(bounds.left, result)
  }

  if (bounds !== null && bounds.right !== null) {
    result = Math.min(bounds.right, result)
  }

  return result
}

export function keepPointInsideBoundsY(
  source: number,
  bounds: Omit<NonNullable<Bounds>, 'left' | 'right'> | null
): number {

  let result = source

  if (bounds !== null && bounds.top !== null) {
    result = Math.max(bounds.top, result)
  }

  if (bounds !== null && bounds.bottom !== null) {
    result = Math.min(bounds.bottom, result)
  }

  return result
}
