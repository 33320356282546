import type { LayoutWithCrops, Crop } from '@/areas/editor/@type/Project'
import { baseColors, calculateFeedSize } from '@/areas/editor/@data/prefabs/_helpers'
import { contain } from '@/modules/SLMovable/helpers/fit'

export function dual(videoWidth: number, videoHeight: number): LayoutWithCrops {
  return {
    id: 'dual',
    name: 'Dual facecam',
    presetId: 'dual',
    crops: [
      left(videoWidth, videoHeight),
      right(videoWidth, videoHeight),
      gameplay(videoWidth, videoHeight),
    ]
  }
}

function left(videoWidth: number, videoHeight: number): Omit<Crop, 'layoutId'> {

  const size = 1 / 3
  const aspectRatio = 1

  const { width, height } = contain(
    { width: 1, height: 1 / aspectRatio },
    { width: videoWidth * size, height: videoHeight * size })

  const { width: feedWidth, height: feedHeight } = calculateFeedSize(width, height)
  const scaledFeedWidth = feedWidth * 0.4
  const scaledFeedHeight = feedHeight * 0.4
  
  return {
    id: 'dual:facecam_left',
    x: 20 / videoWidth,
    y: (videoHeight - height - 20) / videoHeight,
    width: width / videoWidth,
    height: height / videoHeight,
    z: 3,
    feedData: {
      x: 0.275 - 0.5 * scaledFeedWidth,
      y: 0.05,
      width: scaledFeedWidth,
      height: scaledFeedHeight,
    },
    input: {
      name: 'Facecam Left',      
      color: baseColors.facecamColor,
      shape: 'circle'
    }
  }
}

function right(videoWidth: number, videoHeight: number): Omit<Crop, 'layoutId'> {

  const size = 1 / 3
  const aspectRatio = 1

  const { width, height } = contain(
    { width: 1, height: 1 / aspectRatio },
    { width: videoWidth * size, height: videoHeight * size })

  const { width: feedWidth, height: feedHeight } = calculateFeedSize(width, height)
  const scaledFeedWidth = feedWidth * 0.4
  const scaledFeedHeight = feedHeight * 0.4

  return {
    id: 'dual:facecam_right',
    x: (videoWidth - width - 20) / videoWidth,
    y: (videoHeight - height - 20) / videoHeight,
    width: width / videoWidth,
    height: height / videoHeight,
    z: 2,
    feedData: {
      x: 0.725 - 0.5 * scaledFeedWidth,
      y: 0.05,
      width: scaledFeedWidth,
      height: scaledFeedHeight,
    },
    input: {
      name: 'Facecam Right',
      color: baseColors.thirdCropColor,      
      shape: 'circle'
    }
  }
}

function gameplay(videoWidth: number, videoHeight: number): Omit<Crop, 'layoutId'> {

  const size = 0.9
  const aspectRatio = 9 / 16

  const { width, height } = contain(
    { width: 1, height: 1 / aspectRatio },
    { width: videoWidth * size, height: videoHeight * size })


  const { width: feedWidth, height: feedHeight } = calculateFeedSize(width, height)

  return {
    id: 'dual:gameplay',
    x: (0.5 * videoWidth - 0.5 * width) / videoWidth,
    y: (0.5 * videoHeight - 0.5 * height) / videoHeight,
    width: width / videoWidth,
    height: height / videoHeight,
    z: 1,
    feedData: {
      x: 0,
      y: 1 - feedHeight,
      width: feedWidth,
      height: feedHeight,
    },
    input: {
      name: 'Gameplay',
      color: baseColors.gameplayColor,
      shape: 'freeform'
    }
  }
}
