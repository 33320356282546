<script setup lang="ts">
import RadialProgress from '@/components/Dialog/RadialProgress.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { ref } from 'vue'
import { useFileUploads } from '@/components/Dialog/MultiUploadDialog/file-uploads/useFileUploads'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'

const showModal = ref(false)

defineProps<{
  title: string
  loadingMessage: string
  successMessage: string
  errorMessage: string
}>()

defineExpose({ 
  open: () => (showModal.value = true), 
  close: () => (showModal.value = false)
})

const size = 'small'

const _onOverlayClick = (event: MouseEvent) => {
  if (!showModal.value) return

  if (!(event.target instanceof HTMLElement)) return

  if (event.target.classList.contains('modal')) {
    showModal.value = false
  }
}

const clipInfoStore = useEditorClipInfoStore()
const fileUploads = useFileUploads()
const upload = fileUploads.selectById(clipInfoStore.id)
</script>

<template>
  <Teleport to="body">
    <div v-if="showModal && upload" :class="[$attrs.class]" class="modal modal-open" @click.stop="_onOverlayClick">
      <div
        :class="{ 'max-w-sm': size === 'small', 'max-w-4xl': size === 'large' }"
        class="layer-1 modal-box max-h-[80vh] w-11/12"
      >
        <div class="flex flex-col items-center gap-4 py-4">
          <template v-if="upload.status === 'in-progress'">
            <LottieAnimation url="/lottie/rocketLaunch.json" class="w-20" />
            <h2 class="text-center text-2xl font-bold">Hold tight! ✨</h2>
            <p class="text-center text-lg font-normal text-gray-600">
              {{ loadingMessage }}
            </p>
            <radial-progress :progress="upload.progress" />
          </template>
          <template v-else-if="upload.status === 'finished'">
            <LottieAnimation url="/lottie/rocketLaunch.json" class="w-20" />
            <h2 class="text-center text-2xl font-bold">Success! 🚀</h2>
            <p class="text-center text-lg font-normal text-gray-600">
              {{ successMessage }}
            </p>
          </template>
          <template v-else-if="upload.status === 'error'">
            <LottieAnimation url="/lottie/error.json" class="w-20" />
            <h2 class="text-center text-2xl font-bold">Oops!</h2>
            <p class="text-center text-lg font-normal text-gray-600">{{ errorMessage }}</p>
            <pre class="p-1 rounded bg-zinc-200 text-xs text-gray-600">{{ JSON.stringify(upload, null, 2) }}</pre>
          </template>
        </div>
      </div>
    </div>
  </Teleport>
</template>
