<script lang="ts" setup>
import type { Component } from 'vue'
import { computed } from 'vue'
import LockIcon from '@/components/Icons/LockIcon.vue'

const props = defineProps<{
  content: string | (() => Component)
  disabled?: boolean
  size?: number
  tooltip?: string
}>()

const iconSize = computed(() => props.size ?? 24)
const lockSize = computed(() => iconSize.value * (2 / 3))

const isString = computed(() => typeof props.content === 'string')
</script>

<template>
  <label
    :class="{
      'px-3 py-1': isString,
      'p-2': !isString,
      'tooltip before:px-4 before:py-3': tooltip !== undefined,
    }"
    :data-tip="tooltip"
    class="flex cursor-pointer select-none items-center gap-2 text-center"
  >
    <template v-if="isString">{{ content }}</template>
    <component :is="content" v-else :size="iconSize" />
    <LockIcon v-if="disabled" :size="lockSize" />
    <span class="absolute opacity-0">
      <slot />
    </span>
  </label>
</template>

<style lang="scss" scoped>
.tooltip {
  --tooltip-tail: 5px;
}

.tooltip::before {
  max-width: var(--max-width);
  left: var(--bounds-left);
  bottom: calc(calc(100% + 10px) + var(--tooltip-tail));
}

.tooltip::after {
  bottom: calc(calc(100% + 10px) - var(--tooltip-tail));
}

.tooltip:hover::before,
.tooltip:hover::after {
  transition-delay: 100ms;
}
</style>
