<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import type { Component } from 'vue'
import LightSwitchLabel from '@/components-v2/data-input/boolean/LightSwitchLabel.vue'
import { useResizeObserver } from '@vueuse/core'

const emit = defineEmits<{
  (e: 'update:value', selectedOption: string): void
}>()

export type LightSwitchOption = {
  label: string | Component
  value: string
  disabled?: boolean
  tooltip?: string
}

const props = withDefaults(
  defineProps<{
    options: LightSwitchOption[]
    value: string
    background?: string
    size?: number,
    labelClasses?: string
  }>(),
  {
    background: 'bg-primary',
  }
)

const labels = ref([])

const indexedLabels = computed(() => {
  const result = {} as Record<string, HTMLElement>
  for (const label of labels.value) {
    // @ts-expect-error TS2339
    const element = label.$el
    result[element.dataset.key as string] = element
  }
  return result
})

const container = ref<HTMLElement>()

const labelStyles = ref<Record<string, Record<string, string>>>({})

const containerStyle = ref<Record<string, string> | null>(null)
const updateStyle = () => {
  const containerRect = container.value?.getBoundingClientRect()

  if (!props.value || !indexedLabels.value[props.value] || !containerRect) {
    return
  }

  const targetIndex = props.options.findIndex((o) => o.value === props.value)

  let insetLeft = 0
  let insetRight = 0

  for (const [index, option] of props.options.entries()) {
    const clientRect = indexedLabels.value[option.value].getBoundingClientRect()

    if (index < targetIndex) {
      insetLeft += clientRect.width
    } else if (index > targetIndex) {
      insetRight += clientRect.width
    }

    const tooltipWidth = Math.min(containerRect.width, 250)

    const topLeftOrigin = {
      x: clientRect.left - containerRect.left,
      y: clientRect.top - containerRect.top,
    }

    const centerOrigin = {
      x: topLeftOrigin.x + 0.5 * clientRect.width,
      y: topLeftOrigin.y + 0.5 * clientRect.height,
    }

    const left = centerOrigin.x - 0.5 * tooltipWidth
    const right = centerOrigin.x + 0.5 * tooltipWidth

    labelStyles.value[option.value] = {
      '--bounds-left': '50%',
      '--max-width': tooltipWidth + 'px',
    }

    if (left < -5) {
      labelStyles.value[option.value]['--bounds-left'] = centerOrigin.x - left + 'px'
    } else if (right > containerRect.width) {
      labelStyles.value[option.value]['--bounds-left'] = centerOrigin.x + clientRect.width - right + 'px'
    }
  }

  containerStyle.value = {
    '--inset-left': insetLeft + 'px',
    '--inset-right': insetRight + 'px',
  }
}

watch([() => props.value, indexedLabels], updateStyle)
useResizeObserver(container, updateStyle)

const update = (value: string) => {
  emit('update:value', value)
}

const enabledOptions = computed(() => props.options.filter((o) => !o.disabled).map((o) => o.value))

const isCheckbox = computed(() => {
  return enabledOptions.value.length === 2
})

const toggle = (event: MouseEvent) => {
  if (isCheckbox.value) {
    const option = enabledOptions.value.find((o) => o !== props.value)
    if (option) {
      update(option)
      event.preventDefault()
    }
  }
}

const disabledOptions = computed(() => props.options?.map((o) => !o.disabled))
const disabled = computed(() => disabledOptions.value.length <= 1)

watch(
  disabledOptions,
  () => {
    const selectedOption = props.options.find((o) => o.value === props.value)
    if (selectedOption?.disabled) {
      const availableOption = props.options.find((o) => !o.disabled)!
      update(availableOption.value)
    }
  },
  { deep: true }
)
</script>

<template>
  <div class="inline-flex w-auto rounded-full">
    <div
      :class="{
        'pointer-events-none': disabled,
        'focus-within:outline-px focus-within:outline focus-within:outline-offset-2 focus-within:outline-primary':
          isCheckbox,
      }"
      :style="containerStyle"
      class="light-switch rounded-full border border-zinc-800 p-1 transition-colors active:bg-zinc-100"
      @click="toggle"
    >
      <div ref="container" class="relative">
        <span class="absolute flex items-center justify-between">
          <LightSwitchLabel
            v-for="option in options"
            :key="option.value"
            ref="labels"
            :tooltip="option.tooltip"
            :content="option.label"
            :data-key="option.value"
            :disabled="option.disabled"
            :style="labelStyles[option.value]"
            :size="size"
            class=""
          >
            <input
              :checked="option.value === value"
              :disabled="option.disabled"
              type="radio"
              v-bind:value="option.value"
              @change="update(option.value)"
            />
          </LightSwitchLabel>
        </span>

        <span
          :class="{ 'clip-path': containerStyle }"
          :style="containerStyle"
          class="pointer-events-none flex items-center justify-between"
        >
          <span class="backdrop absolute -z-[1] block" :class="background" />
          <LightSwitchLabel
            v-for="option in options"
            :key="option.value"
            :content="option.label"
            :disabled="option.disabled"
            :size="size"
            :class="labelClasses"
            class="text-white"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.clip-path {
  --inset-top: 0;
  --inset-right: 0;
  --inset-bottom: 0;
  --inset-left: 0;

  transition: clip-path 100ms ease-in;
  clip-path: inset(var(--inset-top) var(--inset-right) var(--inset-bottom) var(--inset-left) round 100px) !important;

  // move background with clip path, in case you choose a gradient background
  .backdrop {
    transition: inherit;
    transition-property: inset, background;
    inset: var(--inset-top) var(--inset-right) var(--inset-bottom) var(--inset-left) !important;
  }
}
</style>
