<template>
  <div :class="color" class="generic-a">
    <div class="logo">
      <sticker-icons :icon="icon" />
    </div>

    <div class="text-wrapper">
      <TextSticker
        ref="sticker"
        variant="none"
        @update-rect="(e) => $emit('updateRect', e)"
        @update-content="(e) => $emit('updateContent', e)"
        @update="(e) => $emit('update', e)"
        :html-content="htmlContent"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const sticker = ref()
defineExpose({
  setEditMode: () => {
    sticker.value.setEditMode()
  },
})
</script>

<script>
import StickerIcons from '../../StickerIcons.vue'
import TextSticker from '@/components/Stickers/TextSticker.vue'

export default {
  components: {
    TextSticker,
    StickerIcons,
  },
  props: {
    htmlContent: {
      type: String,
      default: '',
    },
    // Either blue, red, green
    color: {
      type: String,
      default: 'blue',
    },
    // Either twitch or youtube
    icon: {
      type: String,
      default: 'twitch',
    },
    setEditMode: {
      type: Function,
      required: false,
    },
  },
  mounted() {
    this.$emit('stickerLoaded', this)
  },
}
</script>

<style lang="scss" scoped>
.generic-a {
  --twitch-color: white;
  display: inline-flex;
  // overflow: hidden;
  white-space: nowrap;
  line-height: 0;
  user-select: none;

  border-radius: 10px;

  width: auto;
  padding: 0.5em;
  color: #ffffff;

  * {
    box-sizing: content-box;
  }

  > .text-wrapper {
    display: flex;
    align-items: center;
    margin-left: 0.3em;

    font-family: 'Metropolis';

    .none {
      padding-left: 2px;
      font-weight: bold;
      font-style: normal;
      font-size: 23px;
      line-height: 23px;
    }
  }

  > .logo {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    > svg {
      width: 1.5em;
    }
  }
}

.blue {
  background: linear-gradient(90deg, #37c2fe -0.67%, #8050fe 100%);
  border: 1px solid #37c2fe;
}

.red {
  background: linear-gradient(180deg, #e306a5 14.84%, #830760 100%);
  border: 1px solid #e306a5;
}

.green {
  background: linear-gradient(180deg, #06e3d6 14.84%, #07836c 100%);
  border: 1px solid #06e3d6;
}

.rainbow {
  background: linear-gradient(
    90deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 10%,
    rgba(208, 222, 33, 1) 20%,
    rgba(79, 220, 74, 1) 30%,
    rgba(63, 218, 216, 1) 40%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 60%,
    rgba(95, 21, 242, 1) 70%,
    rgba(186, 12, 248, 1) 80%,
    rgba(251, 7, 217, 1) 90%,
    rgba(255, 0, 0, 1) 100%
  );
  border: 1px solid white;
}
</style>
