import type { LayoutWithCrops, Crop } from '@/areas/editor/@type/Project'
import { calculateRemainingAspectRatio, calculateFeedSize, baseColors } from '@/areas/editor/@data/prefabs/_helpers'
import { contain } from '@/modules/SLMovable/helpers/fit'

export function split(videoWidth: number, videoHeight: number): LayoutWithCrops {
  return {
    id: 'split',
    name: 'Split',
    presetId: 'split',
    crops: [
      facecam(videoWidth, videoHeight),
      gameplay(videoWidth, videoHeight)
    ]
  }
}

function facecam(videoWidth: number, videoHeight: number): Omit<Crop, 'layoutId'> {

  const size = 1 / 3
  const aspectRatio = 16 / 9

  const { width, height } = contain(
    { width: 1, height: 1 / aspectRatio },
    { width: videoWidth * size, height: videoHeight * size })

  const { width: feedWidth, height: feedHeight } = calculateFeedSize(width, height)
  
  return {
    id: 'split:facecam',
    x: (videoWidth - width - 20) / videoWidth,
    y: (videoHeight - height - 20) / videoHeight,
    width: width / videoWidth,
    height: height / videoHeight,
    z: 2,
    feedData: { 
      x: 0,
      y: 0,
      width: feedWidth,
      height: feedHeight,
    },
    input: {
      name: 'Facecam',
      color: baseColors.facecamColor,
      shape: 'freeform'
    }
  }
}

function gameplay(videoWidth: number, videoHeight: number): Omit<Crop, 'layoutId'> {

  const aspectRatio = calculateRemainingAspectRatio(16, 9)
  const size = 0.9

  const { width, height } = contain(
    { width: 1, height: 1 / aspectRatio },
    { width: videoWidth * size, height: videoHeight * size })

  const { width: feedWidth, height: feedHeight } = calculateFeedSize(width, height)

  return {
    id: 'split:gameplay',
    x: (0.5 * videoWidth - 0.5 * width) / videoWidth,
    y: (0.5 * videoHeight - 0.5 * height) / videoHeight,
    width: width / videoWidth,
    height: height / videoHeight,
    z: 1,
    feedData: {
      x: 0,
      y: 1 - feedHeight,
      width: feedWidth,
      height: feedHeight,
    },
    input: {
      name: 'Gameplay',
      color: baseColors.gameplayColor,
      shape: 'freeform'
    }
  }
}
