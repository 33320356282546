import { ref, computed } from 'vue'
import { retryAsync } from '@/helpers/retry'
import { streamLadderAxiosInstance, publicAxios } from '@/services/axios'
import logging from '@/logging'
import * as Sentry from '@sentry/vue'
import { MD5 } from 'jscrypto/es6/MD5'
import { Word32Array } from 'jscrypto/es6/Word32Array'
import uploadService from '@/services/uploadService'
import Pusher from 'pusher-js'
import type { Sentence } from '@/components/Captions/captionTypes'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import VideoToAudioService from '@/services/videoToAudioService'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { tryOnMounted } from '@vueuse/core'

export function useGenerateCaptions() {
  
  const editorClipInfoStore = useEditorClipInfoStore()
  const editorCaptionsStore = useEditorCaptionsStore()
  const editorFocusStore = useEditorFocusStore()

  const supportedLocales = [
    { code: 'en_us', label: 'English' }, { code: 'es', label: 'Spanish' }, { code: 'fr', label: 'French' },
    { code: 'de', label: 'German' }, { code: 'it', label: 'Italian' }, { code: 'nl', label: 'Dutch' },
    { code: 'pt', label: 'Portuguese' }, { code: 'hi', label: 'Hindi' }, { code: 'ja', label: 'Japanese' },
    { code: 'zh', label: 'Chinese' }, { code: 'fi', label: 'Finnish' }, { code: 'ko', label: 'Korean' },
    { code: 'pl', label: 'Polish' }, { code: 'ru', label: 'Russian' }, { code: 'tr', label: 'Turkish' },
    { code: 'uk', label: 'Ukrainian' }, { code: 'vi', label: 'Vietnamese' }]

  const captionsLanguageCode = ref<(typeof supportedLocales)[number]['code']>('en_us')
  
  const isOverDurationLimit = computed(() => durationMs.value > 3 * 60 * 1000)

  // Overly lengthy videos and videos without a remotely accessible URL should be trimmed and stored before sending
  // them to the generation API.
  const shouldTrimBeforeGenerating = computed(() => {
    const isLocalFile = editorClipInfoStore.mp4Url.startsWith('blob:')
    return isOverDurationLimit.value || isLocalFile
  })

  async function selectFileUrl() {
    if (shouldTrimBeforeGenerating.value) {
      const blob = await fetch(editorClipInfoStore.mp4Url)
        .then((r) => r.blob())
        .catch((e) => {
          console.error(e)
          throw new Error('Could not fetch video file. Please try again.')
        })
      return await extractAndUploadAudioFile(blob)
    } else {
      return editorClipInfoStore.mp4Url
    }
  }
  
  const segmentsStore = useSegmentsStore()
  const segments = segmentsStore.flat()

  const startMs = computed(() => Math.min(...segments.value.map(s => s.startMs)))
  const endMs = computed(() => Math.max(...segments.value.map(s => s.endMs)))
  const durationMs = computed(() => endMs.value - startMs.value)

  function generateCaptionsAsync() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      try {
        const fileUrl = await selectFileUrl()

        editorCaptionsStore.selectedLanguage = captionsLanguageCode.value
        editorCaptionsStore.resetPositionScale()

        let retry = 0
        const result = await retryAsync(async () => {
        const timeout = 30 * Math.max(1000, durationMs.value / 60) * Math.pow(2, retry)

          const abortController = new AbortController()
          const timeoutId = setTimeout(() => {
            abortController.abort('Captions generation timed out')
            throw new Error('Captions generation timed out')
          }, timeout)

          console.log(`Request will timeout in ${timeout / 1000}s`)

          retry++

          try {
            const result = await streamLadderAxiosInstance<{ fileName: string; fullFilePath?: string; taskId: string }>(
              {
                url: `/api/Captions/GenerateCaptions`,
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                data: { fileUrl, languageCode: captionsLanguageCode.value },
              },
              { signal: abortController.signal }
            )

            clearTimeout(timeoutId)
            return result
          } catch (e) {
            clearTimeout(timeoutId)
            throw e
          }
        })

        if (!result) return

        logging.trackEvent('Editor Captions Added', {
          language: captionsLanguageCode.value,
          localFile: editorClipInfoStore.isLocalFile,
          style: editorCaptionsStore.captionStyle,
          tags: editorCaptionsStore.captionStyleSettings.tags,
        })

        if (result.fullFilePath) {
          await emitCaptionsFileContent(result.fullFilePath)
        } else {
          await getCaptionsFileByPusherTask(result.taskId)
        }

        resolve()
      } catch (e) {
        Sentry.captureException(e)
        reject(e)
      }
    })
  }

  const uploadFilePercentage = ref(0)

  async function extractAndUploadAudioFile(file: Blob) {
    try {
      const audioFile = await VideoToAudioService(file, 'mp3', startMs.value / 1000, endMs.value / 1000)
        .catch((e: ProgressEvent) => {
          const target = e.target as FileReader
          if (target?.error?.message) {
            throw new Error(target.error.message)
          } else {
            throw e
          }
        })

      const buff = await new Response(audioFile).arrayBuffer()
      const md5Hash = MD5.hash(new Word32Array(buff)).toString()

      const result = await uploadService.getClipAudioUploadSignedUrl(md5Hash)

      // Check if we need to upload the file. Otherwise, the file already exists
      if (result.signedUrl) {
        await uploadService.uploadFileS3(
          result.signedUrl,
          audioFile,
          (p) => (uploadFilePercentage.value = p),
          'audio/mp3'
        )
      }

      return result.resultUrl
    } catch (e) {
      Sentry.captureException(e)
      throw e
    }
  }

  async function getCaptionsFileByPusherTask(taskId: string) {
    const pusherClient = new Pusher('ef0a10b651ed4adf46eb', {
      cluster: 'us3',
    })

    const channelName = `task-status-${taskId}`
    const channel = pusherClient.subscribe(channelName)

    return new Promise<void>((resolve, reject) => {
      channel.bind('finished', async (data: { FullFilePath: string }) => {
        await emitCaptionsFileContent(data.FullFilePath)
        pusherClient.disconnect()
        resolve()
      })

      channel.bind('error', (data: unknown) => {
        console.error(data)
        pusherClient.disconnect()
        reject(new Error(`Generating captions failed: ${data}`))
      })
    })
  }

  async function emitCaptionsFileContent(url: string) {

    const result = await publicAxios.get(url)

    if (shouldTrimBeforeGenerating.value) {
      // If it was a local-file, and these can be trimmed before we send them to AssemblyAI, we need to add an extra offset to all data before we return it
      const addOffset = (e: { start: number; end: number }) => {
        e.start += startMs.value
        e.end += startMs.value
      }

      result.data.sentences.forEach((sentence: Sentence) => {
        addOffset(sentence)
        sentence.words.forEach(addOffset)
      })

      result.data.words.forEach(addOffset)
    }

    editorCaptionsStore.setCaptionsDocument(result.data)
    editorFocusStore.setFocus(FocusTypes.CAPTION)
  }

  function guessLanguage() {
    // Attempt to get the language from the ClipInfo store
    const clipLanguage = editorClipInfoStore.languageCode?.toLowerCase() ?? ''
    const lang: (typeof supportedLocales)[number]['code'] = clipLanguage === 'en' ? 'en_us' : clipLanguage

    if (supportedLocales.find((l) => l.code === lang)) {
      return lang
    } else {
      // If not supported, try browser-language. Use english as fallback
      const lang = navigator.language.toLowerCase().split('-')[0]
      return supportedLocales.find((l) => l.code === lang) ? lang : editorCaptionsStore.selectedLanguage
    }
  }

  tryOnMounted(() => {
    captionsLanguageCode.value = guessLanguage()
    editorCaptionsStore.selectedLanguage = guessLanguage()
  })
  
  return {
    captionsLanguageCode,
    supportedLocales,
    generateCaptionsAsync,
    uploadFilePercentage,
    shouldTrimBeforeGenerating,
    isOverDurationLimit
  }
}
