import type { Sticker } from '@/components/Stickers/stickerLibrary/stickerLibrary'
import Sprinkle from '@/components/Stickers/Sprinkle.vue'
import GenericSquare from '@/components/Stickers/stickerLibrary/components/GenericSquare.vue'
import GenericRound from '@/components/Stickers/stickerLibrary/components/GenericRound.vue'
import GenericSquareRounded from '@/components/Stickers/stickerLibrary/components/GenericSquareRounded.vue'
import GenericTranslucent from '@/components/Stickers/stickerLibrary/components/GenericTranslucent.vue'
import GenericA from '@/components/Stickers/stickerLibrary/components/GenericA.vue'
import GenericB from '@/components/Stickers/stickerLibrary/components/GenericB.vue'
import GenericC from '@/components/Stickers/stickerLibrary/components/GenericC.vue'
import GenericD from '@/components/Stickers/stickerLibrary/components/GenericD.vue'
import ComfyCatA from '@/components/Stickers/stickerLibrary/components/ComfyCatA.vue'
import ComfyCatB from '@/components/Stickers/stickerLibrary/components/ComfyCatB.vue'
import { markRaw } from 'vue'

export const youtubeStickers = [
  {
    key: 'youtube-generic-square',
    component: markRaw(GenericSquare),
    tags: ['youtube'],
    icon: 'youtube',
    colors: {
      color: '#ff0000',
      dark: 'black',
      light: 'white',
    },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'youtube-generic-round',
    component: markRaw(GenericRound),
    tags: ['youtube'],
    icon: 'youtube',
    colors: {
      color: '#ff0000',
      light: '#000000',
      dark: '#ffffff',
    },
    createdAt: new Date(1970, 0, 1),
  },

  {
    key: 'youtube-generic-square-rounded',
    component: markRaw(GenericSquareRounded),
    tags: ['youtube'],
    icon: 'youtube',
    colors: {
      color: '#ff0000',
      dark: '#000000',
      light: '#ffffff',
    },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'youtube-generic-translucent',
    component: markRaw(GenericTranslucent),
    tags: ['youtube'],
    icon: 'youtube',
    colors: { dark: '#000000', youtube: '#ff0000' },
    createdAt: new Date(2023, 9, 1),
  },

  {
    key: 'youtube-generic-a',
    component: markRaw(GenericA),
    tags: ['youtube'],
    icon: 'youtube',
    colors: {
      blue: '#37C2FE',
      red: '#DA0062',
      green: '#0FA688',
      // rainbow: RAINBOW_GRADIENT
    },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'youtube-generic-b',
    icon: 'youtube',
    component: markRaw(GenericB),
    colors: { black: '#000', blue: '#223ECF', red: '#ff0000' },
    tags: ['youtube'],
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'youtube-generic-c',
    component: markRaw(GenericC),
    icon: 'youtube',
    tags: ['youtube'],
    colors: { red: '#ff0000', orange: '#ff9500', pink: '#de21c8', green: '#42b25a' },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'youtube-generic-d',
    component: markRaw(GenericD),
    icon: 'youtube',
    tags: ['youtube'],
    colors: { red: '#ff0000', aqua: '#1BBDF2', black: '#000' },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'sprinkle',
    component: markRaw(Sprinkle),
    tags: ['twitch'],
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'comfy-cat-a',
    component: markRaw(ComfyCatA),
    tags: ['just-chatting', 'twitch'],
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'comfy-cat-b',
    component: markRaw(ComfyCatB),
    tags: ['just-chatting', 'twitch'],
    createdAt: new Date(1970, 0, 1),
  },
] as const satisfies Readonly<Sticker[]>
