/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/vue-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  QueryKey,
  UseQueryReturnType,
} from '@tanstack/vue-query'
import { unref } from 'vue'
import type { MaybeRef } from '@tanstack/vue-query/build/lib/types'
import type { CustomSticker, SaveCustomStickerRequest } from '.././model'
import { streamLadderAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never

export const postApiCustomStickers = (
  saveCustomStickerRequest: MaybeRef<SaveCustomStickerRequest>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  return streamLadderAxiosInstance<CustomSticker>(
    {
      url: `/api/CustomStickers`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: saveCustomStickerRequest,
    },
    options
  )
}

export const getPostApiCustomStickersMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiCustomStickers>>,
    TError,
    { data: SaveCustomStickerRequest },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiCustomStickers>>,
  TError,
  { data: SaveCustomStickerRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiCustomStickers>>,
    { data: SaveCustomStickerRequest }
  > = (props) => {
    const { data } = props ?? {}

    return postApiCustomStickers(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiCustomStickersMutationResult = NonNullable<Awaited<ReturnType<typeof postApiCustomStickers>>>
export type PostApiCustomStickersMutationBody = SaveCustomStickerRequest
export type PostApiCustomStickersMutationError = unknown

export const usePostApiCustomStickers = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiCustomStickers>>,
    TError,
    { data: SaveCustomStickerRequest },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const mutationOptions = getPostApiCustomStickersMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiCustomStickers = (
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  return streamLadderAxiosInstance<CustomSticker[]>({ url: `/api/CustomStickers`, method: 'get', signal }, options)
}

export const getGetApiCustomStickersQueryKey = () => ['api', 'CustomStickers'] as const

export const getGetApiCustomStickersQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiCustomStickers>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiCustomStickers>>, TError, TData>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof getApiCustomStickers>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiCustomStickersQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiCustomStickers>>> = ({ signal }) =>
    getApiCustomStickers(requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions }
}

export type GetApiCustomStickersQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCustomStickers>>>
export type GetApiCustomStickersQueryError = unknown

export const useGetApiCustomStickers = <
  TData = Awaited<ReturnType<typeof getApiCustomStickers>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiCustomStickers>>, TError, TData>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiCustomStickersQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}

export const deleteApiCustomStickersCustomStickerId = (
  customStickerId: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  return streamLadderAxiosInstance<void>(
    { url: `/api/CustomStickers/${unref(customStickerId)}`, method: 'delete' },
    options
  )
}

export const getDeleteApiCustomStickersCustomStickerIdMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiCustomStickersCustomStickerId>>,
    TError,
    { customStickerId: string },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiCustomStickersCustomStickerId>>,
  TError,
  { customStickerId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiCustomStickersCustomStickerId>>,
    { customStickerId: string }
  > = (props) => {
    const { customStickerId } = props ?? {}

    return deleteApiCustomStickersCustomStickerId(customStickerId, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteApiCustomStickersCustomStickerIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiCustomStickersCustomStickerId>>
>

export type DeleteApiCustomStickersCustomStickerIdMutationError = unknown

export const useDeleteApiCustomStickersCustomStickerId = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiCustomStickersCustomStickerId>>,
    TError,
    { customStickerId: string },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const mutationOptions = getDeleteApiCustomStickersCustomStickerIdMutationOptions(options)

  return useMutation(mutationOptions)
}
