import type { Sticker } from '@/components/Stickers/stickerLibrary/stickerLibrary'
import MultipleGradient from '@/components/Stickers/stickerLibrary/multiple/components/MultipleGradient.vue'
import MultipleRounded from '@/components/Stickers/stickerLibrary/multiple/components/MultipleRounded.vue'
import MultipleGlow from '@/components/Stickers/stickerLibrary/multiple/components/MultipleGlow.vue'
import { markRaw } from 'vue'

export const multiStickers = [
  {
    key: 'multiple-rounded',
    component: markRaw(MultipleRounded),
    tags: ['multiple'],
    colors: {
      dark: '#000000',
      light: '#ffffff',
    },
    createdAt: new Date(2023, 9, 1),
  },
  {
    key: 'multiple-glow',
    component: markRaw(MultipleGlow),
    tags: ['multiple'],
    colors: {
      'dark-cyan': '#008e7d',
      neutral: '#333333',
      crimson: '#da0062',
      'dark-violet': '#c900da',
      'sea-green': '#00af46',
    },
    createdAt: new Date(2023, 9, 1),
  },
  {
    key: 'multiple-gradient',
    component: markRaw(MultipleGradient),
    tags: ['multiple'],
    colors: {
      'celestial-blue': 'linear-gradient(94deg, #315DF8, #26B1FF)',
      'aurora-green': 'linear-gradient(94deg, #31F8BF, #02A571)',
      'stellar-gold': 'linear-gradient(94deg, #FEDD00, #FF9400)',
      'lavender-nebula': 'linear-gradient(94deg, #AD00FE, #FF00A8)',
      'galactic-slate': 'linear-gradient(94deg, #000, #585858)',
      'selunite-silver': 'linear-gradient(94deg, #FFF, #AFAFAF)',
      'jupiter-storm': 'linear-gradient(94deg, #EB3F3F, #DA7023)',
    },
    createdAt: new Date(2023, 9, 1),
  },
] as const satisfies Readonly<Sticker[]>
