<script setup lang="ts">
import { useEditorMainStore } from '@/store/editor/editorMain'
import { useUserInfoStore } from '@/store/user/userInfo'
import { ref, computed, h, watch } from 'vue'
import settings from '@/data/settings'
import { canGuard, canGuardWithPopup, useGuard } from '@/Hooks/useGuard'
import DynamicPlanButtonWithTooltip from '@/components/Account/Upgrade/DynamicPlanButtonWithTooltip.vue'
import { useFileUploads } from '@/components/Dialog/MultiUploadDialog/file-uploads/useFileUploads'
import { useRoute } from 'vue-router'
import LightSwitch from '@/components-v2/data-input/boolean/LightSwitch.vue'
import ProgressDialog from '@/components/Dialog/ProgressDialog.vue'
import { Button } from '@/components/ui/button'
import IconSaxTickCircle from '@/components/Icons/iconsax/IconSaxTickCircle.vue'
import { ScrollArea } from '@/components/ui/scroll-area'
import IconSave from '@/components/Icons/IconSave.vue'
import ExportButton from '@/areas/editor/workspaces/ExportButton.vue'
import LoginDialog from '@/pages/auth/LoginDialog.vue'
import IconSaxLoginCurve from '@/components/Icons/iconsax/IconSaxLoginCurve.vue'
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import SaveTemplateForm from '@/areas/editor/pages/export/SaveTemplateForm.vue'
import { watchImmediate } from '@vueuse/core'
import IconSaxExportCircle from '@/components/Icons/iconsax/IconSaxExportCircle.vue'
import templateService from '@/services/templateService'

const editorMainStore = useEditorMainStore()
const userInfoStore = useUserInfoStore()

const selectedResolution = ref(localStorage.getItem(settings.outputResolution) ?? '720')
const isHighQuality = computed(() => selectedResolution.value === '1080')

const outputResolutions = computed(() => [
  {
    key: '720p',
    value: '720',
    label: '720p / 60fps',
  },
  {
    key: '1080p',
    value: '1080',
    disabled: !canGuard('resolution-1080'),
    label: () =>
      h('div', { class: 'flex items-center gap-2' }, [
        h('span', ['1080p / 60fps']),
        h(DynamicPlanButtonWithTooltip, { feature: 'resolution-1080' }),
      ]),
  },
])

watch(selectedResolution, (resolution) => {
  if (resolution == '1080') {
    editorMainStore.setVideoOutputDimensions({
      width: 1080,
      height: 1920,
    })
  } else {
    editorMainStore.setVideoOutputDimensions({
      width: 720,
      height: 1280,
    })
  }
  localStorage.setItem(settings.outputResolution, resolution)
}, { immediate: true })

const fileUploads = useFileUploads()
const route = useRoute()
const activeUpload = fileUploads.selectById(route.params.clipId as string)
const progressShouldBeVisible = computed(() => {
  return activeUpload.value && activeUpload.value.status === 'in-progress' && props.startRender
})

const fileUploadDialog = ref<InstanceType<typeof ProgressDialog> | null>(null)

watch(progressShouldBeVisible, (visible) => {
  if (visible) {
    fileUploadDialog.value?.open()
  } else {
    fileUploadDialog.value?.close()
  }
})

const isSaveTemplateDialogOpen = ref(false)

const showUpgradeDialog = () => {
  if (userInfoStore.tier === 0) {
    canGuardWithPopup('resolution-1080')
    activeQuality.value = '720p30';
  }
}

const canHighQuality = useGuard('resolution-1080');
const activeQuality = ref<'720p30' | '720p60' | '1080p60'>('720p30');

watchImmediate([canHighQuality], () => {
  if (canHighQuality.value) {
    activeQuality.value = '1080p60';
  } else {
    activeQuality.value = '720p30';
  }
});

async function onCloseForm() {
  isSaveTemplateDialogOpen.value = false
  await templateService.getTemplates()
}
</script>

<template>
  <div class="h-full sm:h-screen">
    <ScrollArea class="p-4 2xl:p-8">
      <div class="flex flex-col overflow-y-auto gap-2 md:gap-4">
        <template v-if="userInfoStore.tier > 0">
          <header>
            <h2 class="text-xl font-semibold leading-snug">Select video quality</h2>
            <p class="font-light" v-if="isHighQuality">Best quality for Social Media.</p>
            <p class="font-light" v-else>Good quality for Social Media.</p>
          </header>

          <div class="flex flex-col gap-2">
          <span class="cursor-pointer" @click="() => canGuardWithPopup('resolution-1080')">
            <LightSwitch
              class="whitespace-nowrap text-sm outline-1 outline-offset-2 outline-[#059669] focus-within:outline"
              :options="outputResolutions"
              v-model:value="selectedResolution"
              labelClasses="text-white"
            />
          </span>

            <ExportButton class="mt-4" />

            <div class="hidden md:block rounded-xl border-2 border-surface-panel-border bg-surface-bg mt-4">
              <div class="relative max-w-full overflow-hidden rounded-xl">
                <img
                  class="max-w-full overflow-hidden rounded-xl"
                  src="/images/quality-1080.jpg"
                  alt="video quality comparison"
                />

                <div
                  class="absolute -bottom-px -left-px origin-bottom-left overflow-hidden rounded-xl border-2 border-white shadow-[0_0_999px_999px_rgba(0,0,0,0.75)] transition-all duration-500"
                  :class="isHighQuality ? 'scale-100' : 'scale-[0.6]'"
                >
                  <img
                    draggable="false"
                    :style="{ imageRendering: isHighQuality ? 'auto' : 'pixelated' }"
                    class="max-w-full transition-all select-none"
                    width="1920"
                    height="1080"
                    :src="`/images/quality-${isHighQuality ? '1080' : '720'}.jpg`"
                    alt="video quality comparison"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="h-px bg-surface-panel-border shrink-0 my-2 md:my-4" />
        </template>

        <template v-else-if="!userInfoStore.isAuthenticated">
          <header class="flex flex-col gap-2 my-5">
            <h2 class="text-xl font-semibold leading-snug">Please login to render your video</h2>
            <LoginDialog>
              <Button class="w-full ml-auto flex flex-nowrap items-center justify-center gap-2 whitespace-nowrap rounded-lg bg-indigo-500 px-3 py-1 text-white outline-none hover:bg-indigo-600 focus:bg-indigo-600 transition-all">
                <IconSaxLoginCurve class="h-4 w-4 shrink-0 [&>path]:stroke-1" />
                Login
              </Button>
            </LoginDialog>
          </header>
          <div class="h-px bg-surface-panel-border shrink-0 my-2 md:my-4" />
        </template>

        <template v-else>
          <div class="flex flex-col gap-1 md:gap-2">
            <div class="flex w-full justify-between flex-row py-3 md:py-3 items-center">
              <p class="font-semibold text-xl cursor-default">Select your quality</p>
            </div>
            <label class="cursor-pointer p-4 flex gap-4 items-center py-2 md:py-3 hover:bg-gray-200 dark:hover:bg-gray-700 transition-[background-color] rounded-lg" :class="{'bg-gray-200 dark:bg-gray-700 pointer-events-none': activeQuality === '720p30'}">
              <input
                value="720p30"
                type="radio"
                name="quality"
                autocomplete="off"
                v-model="activeQuality"
              />
              <span class="flex flex-col justify-center">
              <span class="font-bold mb-0.5 w-16 text-sm">720p</span>
              <span class="font-extralight text-gray-400 leading-none text-sm">30 fps</span>
            </span>
              <span class="flex flex-col justify-center">
              <span class="font-bold mb-0.5">Standard 720p</span>
              <span class="font-extralight text-gray-400 leading-none text-sm">Content creation starts here</span>
            </span>
            </label>
            <label class="cursor-pointer p-4 gap-4 flex items-center py-2 md:py-3 hover:bg-gray-200 dark:hover:bg-gray-700 transition-[background-color] rounded-lg" :class="{'bg-gray-200 dark:bg-gray-700 pointer-events-none': activeQuality === '720p60'}">
              <input
                value="720p60"
                type="radio"
                name="quality"
                autocomplete="off"
                v-model="activeQuality"
                @change="showUpgradeDialog"
              />
              <span class="flex flex-col justify-center">
                <span class="font-bold mb-0.5 w-16 text-sm">720p</span>
                <span class="font-extralight text-gray-400 leading-none text-sm">60 fps</span>
              </span>
              <span class="flex flex-col justify-center">
                <span class="font-bold mb-0.5">720p High Frame Rate</span>
                <span class="font-extralight text-gray-400 leading-none text-sm">Enhanced smoothness</span>
              </span>
              <DynamicPlanButtonWithTooltip class="w-4 h-4" feature="resolution-1080" />
            </label>
            <label class="cursor-pointer flex flex-col gap-2 p-4 py-2 md:py-3 hover:bg-gray-200 dark:hover:bg-gray-700 transition-[background-color] rounded-lg" :class="{'bg-gray-200 dark:bg-gray-700 pointer-events-none': activeQuality === '1080p60'}">
            <span class="flex gap-4 items-center">
              <input
                value="1080p60"
                type="radio"
                name="quality"
                id="1080p"
                autocomplete="off"
                v-model="activeQuality"
                @change="showUpgradeDialog"
              />
              <span class="flex flex-col justify-center">
                <span class="font-bold mb-0.5 w-16 text-sm">1080p</span>
                <span class="font-extralight text-gray-400 leading-none text-sm">60 fps</span>
              </span>
              <span class="flex flex-col justify-center">
                <span class="font-bold mb-0.5">Full HD 1080p</span>
                <span class="font-extralight text-gray-400 leading-none text-sm">Best for creators</span>
              </span>
              <DynamicPlanButtonWithTooltip class="w-4 h-4" feature="resolution-1080" />
            </span>
              <span class="ml-7 px-2 py-0 md:py-1 bg-green-100 text-green-700 text-xs rounded-lg max-w-fit">
                The best choice for 23% more engagement! 🎉
              </span>
            </label>
            <div class="flex items-center w-full justify-center p-0 md:pt-2">
              <ExportButton class="w-full" variant="gradient">
                Export clip
                <IconSaxExportCircle class="w-4 h-4" />
              </ExportButton>
            </div>
          </div>

          <div class="h-px bg-surface-panel-border shrink-0 my-2 md:my-4" />
        </template>

        <header>
          <h2 class="text-xl font-semibold leading-snug">Save Template</h2>
          <p class="font-light">Save your current settings, crops and stickers as a template to save time on your next video.</p>
        </header>

        <ol class="flex flex-col gap-2 text-[14px] font-thin">
          <li
            v-for="feature in [
            'Save your current clip settings as a reusable template.',
            'Instantly apply the template to future clip projects with a single click.',
            'Preserve edits, customizations, and effects for consistent and efficient results.',
          ]"
            :key="feature"
            class="flex items-center gap-2"
          >
            <IconSaxTickCircle class="min-w-6 xl:min-w-4 2xl:min-w-6 -mt-0.5 w-6 flex-shrink-0 flex-grow-0 text-[#6080FE] xl:w-4 2xl:w-6" />
            <span class="opacity-75">{{ feature }}</span>
          </li>
        </ol>

        <LoginDialog v-if="!userInfoStore.isLoggedIn">
          <Button variant="outline" class="w-full">
            <IconSave class="stroke-[1.5]" />
            Save template
            <DynamicPlanButtonWithTooltip size="16" feature="save-template" class="fill-white" />
          </Button>
        </LoginDialog>
        <Dialog v-else v-model:open="isSaveTemplateDialogOpen">
          <DialogTrigger class="w-full" as-child>
            <Button variant="outline" class="w-full">
              <IconSave class="stroke-[1.5]" />
              Save template
              <DynamicPlanButtonWithTooltip size="16" feature="save-template" class="fill-white" />
            </Button>
          </DialogTrigger>
          <DialogContent class="max-w-3xl px-12 py-12 flex flex-row items-stretch justify-between gap-12">
            <SaveTemplateForm @close="onCloseForm" />
          </DialogContent>
        </Dialog>
      </div>

      <ProgressDialog
        ref="fileUploadDialog"
        title="Hold tight! ✨"
        :error-message="'An error occurred while uploading your video'"
        :loading-message="'We\'re zipping your video over to our ultra-fast rendering buddy.'"
        :success-message="'Your video is ready to be rendered. 🚀'"
      />
    </ScrollArea>
  </div>
</template>

<style scoped lang="scss">

</style>
