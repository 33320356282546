import { defineStore } from 'pinia'
import { useEntityStore } from './useEntityStore'
import type { Shape } from '@/modules/CustomLayouts/@data/shapes'
import { computed, watch, type MaybeRefOrGetter, toValue } from 'vue'
import type { Size, Area } from '@/modules/SLMovable/@types/Movable'
import { contain } from '@/modules/SLMovable/helpers/fit'
import { v4 as uuid } from 'uuid'
import { clamp, orderBy, uniq } from 'lodash-es'
import type { Crop } from '@/areas/editor/@type/Project'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'

export const useCropsStore = defineStore('crops', () => {

  const { state, ids, entities, operations }
    = useEntityStore<Crop>()

  function whereLayoutIdIs(layoutId: MaybeRefOrGetter<string>) {
    return computed(() => {
      return entities.value.filter((crop) => crop.layoutId === toValue(layoutId))
    })
  }

  function idsWhereLayoutIdIs(layoutId: MaybeRefOrGetter<string>) {
    const crops = whereLayoutIdIs(layoutId)
    return computed(() => crops.value.map((crop) => crop.id))
  }

  function orderByZ(layoutId: MaybeRefOrGetter<string>) {
    const crops = whereLayoutIdIs(layoutId)
    return computed(() => orderBy(crops.value, 'z', 'asc'))
  }

  watch(() => entities.value.length, () => {
    const layoutIds = uniq(entities.value.map((crop) => crop.layoutId))
    for (const layoutId of layoutIds) {
      const sorted = orderByZ(layoutId).value
      for (let i = 0; i < sorted.length; i++) {
        state[sorted[i].id].z = i + 1
      }
    }
  })

  return {
    state: state,
    ids: ids,
    entities: entities,
    ...operations,

    whereLayoutIdIs: whereLayoutIdIs,
    idsWhereLayoutIdIs: idsWhereLayoutIdIs,
    orderByZ: orderByZ,

    selectShapeById(id: string) {
      return computed({
        get: () => {
          return state[id].input.shape
        },
        set: (shape: Shape) => {
          state[id].input.shape = shape
        },
      })
    },

    selectLabelById(id: string) {
      return computed({
        get: () => {
          return state[id].input.name
        },
        set: (name: string) => {
          state[id].input.name = name
        },
      })
    },

    updateCropAreaById(id: string, area: { x?: number; y?: number; width?: number; height?: number }) {

      const currentCrop = state[id]

      state[id].x = area.x ?? currentCrop.x
      state[id].y = area.y ?? currentCrop.y

      state[id].width = area.width ?? currentCrop.width
      state[id].height = area.height ?? currentCrop.height
    },

    updateCropFeedDataById(
      id: string,
      feedData: { x?: number; y?: number; width?: number; height?: number }
    ) {

      const currentFeedData = state[id].feedData

      state[id].feedData.x = feedData.x ?? currentFeedData.x
      state[id].feedData.y = feedData.y ?? currentFeedData.y

      state[id].feedData.width = feedData.width ?? currentFeedData.width
      state[id].feedData.height = feedData.height ?? currentFeedData.height
    },

    duplicateCropById(id: string, cropDestination: Area | null = null, feedDestination: Area | null = null) {

      const crop = state[id]
      const layoutId = crop.layoutId
      const existingCrops = this.idsWhereLayoutIdIs(layoutId).value.length

      if (existingCrops >= 10) {
        return null
      }

      const randomDistance = Math.random() * 0.15

      function clampX(crop: Area) {
        if (crop.x + crop.width + randomDistance <= 1) {
          return crop.x + randomDistance
        } else if (crop.x - randomDistance >= 0) {
          return crop.x - randomDistance
        } else {
          return crop.x
        }
      }

      function clampY(crop: Area) {
        if (crop.y + crop.height + randomDistance <= 1) {
          return crop.y + randomDistance
        } else if (crop.y - randomDistance >= 0) {
          return crop.y - randomDistance
        } else {
          return crop.y
        }
      }

      cropDestination = cropDestination ?? {
        x: clampX(crop),
        y: clampY(crop),
        width: crop.width,
        height: crop.height,
      }

      const feed = crop.feedData
      feedDestination = feedDestination ?? {
        x: clampX(feed),
        y: clampY(feed),
        width: feed.width,
        height: feed.height,
      }

      const duplicate = newCrop(
        layoutId,
        { width: 1, height: 1 },
        existingCrops,
        cropDestination,
        feedDestination)

      const crops = this.whereLayoutIdIs(layoutId).value
      const inputName = crop.input.name.replace(/ copy( \([0-9]\))?$/, '')
      const copies = crops.filter(c => c.input.name.startsWith(`${inputName} copy`)).length

      this.createById(duplicate.id, {
        ...duplicate,
        input: {
          ...crop.input,
          name: `${inputName} copy` + (copies === 0 ? '' : ` (${copies})`),
        },
      })

      return duplicate.id
    },

    shift(id: string, amount: number) {

      const crops = this.orderByZ(state[id].layoutId).value

      const from = state[id].z
      const to = clamp(from + amount, 0, crops.length - 1)

      if (from === to) return

      for (const id of ids.value) {
        if (Math.sign(amount) === -1) {
          if (state[id].z >= to && state[id].z <= from) {
            state[id].z++
          }
        } else {
          if (state[id].z <= to && state[id].z >= from) {
            state[id].z--
          }
        }
      }

      state[id].z = to
    },

    moveToBackground(id: string) {
      this.shift(id, -Infinity)
    },

    moveToForeground(id: string) {
      this.shift(id, Infinity)
    },

    selectTemplateCropDataByLayoutId(layoutId: MaybeRefOrGetter<string>) {
      const { videoSize } = useVideoStore()
      if (!videoSize) return []

      return orderByZ(layoutId).value.map((crop) => ({
        cropperKey: `${crop.input.shape}:${crop.input.name}:${crop.id}`,
        data: {
          x: crop.x,
          y: crop.y,
          w: crop.width,
          h: crop.height,
          ratio: (crop.width * videoSize.width) / (crop.height * videoSize.height)
        }
      }))
    },
    
    selectTemplateFeedDataByLayoutId(layoutId: MaybeRefOrGetter<string>) {
      return orderByZ(layoutId).value.map((crop) => ({
        feedKey: `${crop.input.shape}:${crop.input.name}:${crop.id}`,
        data: {
          x: crop.feedData.x,
          y: crop.feedData.y,
          w: crop.feedData.width,
          h: crop.feedData.height,
        }
      }))
    }
  }
})

function randomArea() {
  const x = Math.random() * 0.5
  const y = Math.random() * 0.5
  const width = Math.max(0.1, Math.random() * (1 - x))
  const height = Math.max(0.25, Math.random() * (1 - y))
  return { x, y, width, height }
}

function generateDefaultFeedData(crop: Size) {

  const outputHeight = 1920
  const outputWidth = 1080

  const feedData = contain(crop, { width: outputWidth, height: outputHeight })

  return {
    x: (0.5 * outputWidth - 0.5 * feedData.width) / outputWidth,
    y: (0.5 * outputHeight - 0.5 * feedData.height) / outputHeight,
    width: feedData.width / outputWidth,
    height: feedData.height / outputHeight,
  }
}

export function newCrop(layoutId: string, container: Size, existingCrops: number, crop?: Area, feedData?: Area): Crop {

  const zIndex = existingCrops + 1

  crop = crop ?? randomArea()

  feedData = feedData ?? generateDefaultFeedData({
    width: crop.width * container.width,
    height: crop.height * container.height
  })

  return {
    ...crop,
    id: uuid(),
    layoutId: layoutId,
    z: zIndex,
    feedData,
    input: {
      shape: 'freeform',
      name: 'New Crop',
      color: `oklch(70% 0.15 ${Math.random() * 360})`,
    },
  }
}
