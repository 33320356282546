import type { StickerData } from '@/store/editor/editorStickers'
import stickerLibrary from '@/components/Stickers/stickerLibrary/stickerLibrary'
import textLibrary from '@/components/Stickers/textLibrary'
import type { Sticker } from '@/apis/streamladder-api/model'

export const stickerToStorage = (sticker: StickerData, videoDuration: number): Sticker => {
  const { animationDuration, animationTime } = animationTimesForStorage(sticker, videoDuration)
  return {
    ...sticker,
    animationDuration,
    animationTime,
  }
}

function animationTimesForStorage(sticker: StickerData, videoDuration: number): Pick<StickerData, 'animationTime' | 'animationDuration'>{

  const start = sticker.start ?? 0
  const end = sticker.end ?? videoDuration
  const duration = Math.round(end - start)

  if (start === 0 && end === videoDuration) {
    // Sticker should span the full video, so no need to store any data.
    return {
      animationTime: undefined,
      animationDuration: undefined,
    }
  } else if (start < (1 / 3) * videoDuration) {
    // Sticker is close to the start of the video, so mark it as a start animation.
    return {
      animationTime: 'start',
      animationDuration: duration,
    }
  } else if (start < (2 / 3) * videoDuration) {
    // Sticker is in the middle of the video, so mark it as a middle animation.
    return {
      animationTime: 'middle',
      animationDuration: duration,
    }
  } else {
    // Sticker is close to the end of the video, so mark it as an end animation.
    return {
      animationTime: 'end',
      animationDuration: duration,
    }
  }
}

export const storageToSticker = (storedSticker: StickerData, videoDuration: number): StickerData & { naturalWidth: number; naturalHeight: number } => {

  const { start, end } = animationTimesOfStoredSticker(storedSticker, videoDuration)
  const library = storedSticker.isTextSticker ? textLibrary : stickerLibrary
  const librarySticker = library.find((sticker) => sticker.key === storedSticker.componentName)

  return {
    ...librarySticker,
    ...storedSticker,
    component: librarySticker?.component,
    start: start,
    end: end,
  }
}

function animationTimesOfStoredSticker(storedSticker: StickerData, videoDuration: number) {
  if (storedSticker.animationTime === undefined) {
    // Sticker spans the full video, so no need to calculate animation times.
    return { 
      start: 0, 
      end: videoDuration
    }
  } 

  const animationDuration = storedSticker.animationDuration ?? 3000
  if (storedSticker.animationTime === 'start') {
    return { 
      start: 0, 
      end: animationDuration
    }
  } else if (storedSticker.animationTime === 'middle') {
    return {
      start: Math.max(0, 0.5 * videoDuration - (0.5 * animationDuration)),
      end: Math.min(videoDuration, 0.5 * videoDuration + (0.5 * animationDuration)),
    }
  } else {
    return {
      start: Math.max(0, videoDuration - animationDuration),
      end: videoDuration,
    }
  }
}
