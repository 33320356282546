<script setup lang="ts">
import BrandKitStyleDialog from "@/areas/editor/pages/text/brand-kit/BrandKitStyleDialog.vue";
import { Button } from "@/components/ui/button";
import GoldPlanButton from "@/components/Account/Upgrade/GoldPlanButton.vue";
import { ref } from "vue";
import { canGuardWithPopup } from "@/Hooks/useGuard";
import { type BrandKitStyle, useUserBrandkitStore } from "@/store/user/userBrandkit";
import quotes from "@/data/quotes";
import type { FontLabel } from "@/data/fonts";

const props = defineProps<{ style: BrandKitStyle }>()
const isOpen = defineModel<boolean>('open')

const preview = ref(quotes.randomQuote());
const fontFamily = ref<FontLabel>(props.style.fontFamily);
const selected = ref(props.style.variant);
const primary = ref(props.style.primaryColor);
const secondary = ref(props.style.secondaryColor);

const userBrandKitStore = useUserBrandkitStore();

const isSavingStyle = ref(false);
async function saveStyle() {
  const canSaveBrandKit = canGuardWithPopup('brandkit');
  if (!canSaveBrandKit) {
    return
  }

  isSavingStyle.value = true;
  await userBrandKitStore.edit({
    id: props.style.id,
    primaryColor: primary.value,
    secondaryColor: secondary.value,
    fontFamily: fontFamily.value,
    variant: selected.value,
  });

  isSavingStyle.value = false;
  isOpen.value = false;
}
</script>

<template>
  <BrandKitStyleDialog
    v-model:open="isOpen"
    v-model:preview="preview"
    v-model:fontFamily="fontFamily"
    v-model:selected="selected"
    v-model:primary="primary"
    v-model:secondary="secondary"
  >
    <slot />
    <template #save>
      <Button variant="primary" :disabled="isSavingStyle" @click="saveStyle">
        <template v-if="isSavingStyle">
          <div class="w-4 h-4 border border-current border-t-transparent rounded-full animate-spin" />
          Updating...
        </template>
        <template v-else>
          Update custom style <GoldPlanButton feature="brandkit" :small="true" :can-click="false" />
        </template>
      </Button>
    </template>
  </BrandKitStyleDialog>
</template>

<style scoped lang="scss">

</style>
