import { type Component, markRaw } from 'vue'
import { facebookStickers } from './facebook/facebookStickers'
import { youtubeStickers } from './youtube/youtubeStickers'
import { kickStickers } from './kick/kickStickers'
import { twitchStickers } from './twitch/twitchStickers'
import { multiStickers } from './multiple/multiStickers'
import { seasonalStickers } from '@/components/Stickers/stickerLibrary/seasonal/seasonalStickers'
import CustomSticker from '@/components/Stickers/CustomSticker.vue'

export default [
  ...twitchStickers,
  ...facebookStickers,
  ...youtubeStickers,
  ...kickStickers,
  ...multiStickers,
  ...seasonalStickers,
  {
    key: 'custom-sticker',
    component: markRaw(CustomSticker),
    tags: [], // Don't add any tags, so it doesn't get displayed in the StickerPicker
    createdAt: new Date(1920, 0, 1),
  },
]

export type Sticker =
  | {
      key: 'custom-sticker'
      component: Component
      tags: []
      createdAt: Date
    }
  | {
      key: string
      component: Component
      icon: string
      tags: readonly string[]
      colors?: Record<string, string>
      createdAt: Date
    }
  | {
      key: string
      component: Component
      tags: readonly string[]
      colors?: Record<string, string>
      createdAt: Date
    }
