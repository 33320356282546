import type { Size } from '@/modules/SLMovable/@types/Movable'

export function cover(subject: Size, container: Size): Size {

  const scale = Math.max(container.width / subject.width, container.height / subject.height)

  const width = subject.width * scale
  const height = subject.height * scale

  return { width, height }
}

export function contain(crop: Size, container: Size): Size {

  const scale = Math.min(container.width / crop.width, container.height / crop.height)

  const width = crop.width * scale
  const height = crop.height * scale

  return { width, height }
}
