<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
    <path
      d="M7.60938 1.66016C4.84938 1.66016 2.60938 3.90016 2.60938 6.66016C2.60938 9.42016 4.84938 11.6602 7.60938 11.6602C10.3694 11.6602 12.6094 9.42016 12.6094 6.66016C12.6094 3.90016 10.3744 1.66016 7.60938 1.66016ZM10.3244 8.87516C9.70937 9.76016 8.69938 10.2852 7.62437 10.2852C6.54937 10.2852 5.53938 9.76016 4.92438 8.87516C4.80438 8.70516 4.84937 8.47016 5.01937 8.35516C5.18937 8.23516 5.42437 8.28016 5.53937 8.45016C6.01437 9.13016 6.79437 9.54016 7.62437 9.54016C8.45437 9.54016 9.23438 9.13516 9.70937 8.45016C9.82937 8.28016 10.0594 8.24016 10.2294 8.35516C10.4044 8.47016 10.4444 8.70516 10.3244 8.87516Z"
      fill="currentColor"
    />
    <path
      d="M1.25637 14.6602L13.9961 14.6602"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script setup lang="ts"></script>
