import { getApiUploadedVideos, deleteApiUploadedVideosVideoId, putApiUploadedVideosVideoId } from '@/apis/streamladder-api/uploaded-videos/uploaded-videos'
import type { UploadedVideo, UpdateUploadedVideoDto } from '@/apis/streamladder-api/model'
import { createGlobalState } from '@vueuse/core'
import { onLoggedIn } from '@/store/user/userInfo'
import type { DeepRequired, DeepNonNullable } from 'ts-essentials'
import { removeFileFromStorage } from '@/components/Dialog/MultiUploadDialog/file-uploads/_storage'
import { ref } from 'vue'
import { debounce } from 'lodash-es'

export const CLOUDFLARE_STORAGE_DURATION_IN_DAYS = 3
const cloudflareStorageDurationInMs = CLOUDFLARE_STORAGE_DURATION_IN_DAYS * 24 * 60 * 60 * 1000

export type EnhancedUploadedVideo = DeepNonNullable<DeepRequired<UploadedVideo>> & { msRemaining: number }

export const useGetUploadedVideosQuery = createGlobalState(() => {

  const uploadedVideos = ref<EnhancedUploadedVideo[]>([])
  const isLoading = ref(true)
  const error = ref<Error | null>(null)
  const promise = ref<Promise<void> | null>(null)
  const suspense = () => {
    return promise.value
  }

  function refetchSync() {
    if (promise.value) return
    promise.value = new Promise((resolve) => {
      isLoading.value = true
      getUploadedVideosAsync()
        .then((videos) => {
          error.value = null
          isLoading.value = false
          uploadedVideos.value = videos
          promise.value = null
          resolve()
        })
        .catch((reason) => {
          isLoading.value = false
          error.value = reason
          promise.value = null
          resolve()
        })
    })
  }
  
  async function refetch() {
    refetchSync()
    await promise.value
  }

  const debouncedRefetch = debounce(refetch)

  onLoggedIn(debouncedRefetch)
  window.addEventListener('focus', () => onLoggedIn(debouncedRefetch))

  return {
    uploadedVideos,
    refetch,
    isLoading,
    error,
    suspense
  }
})

function differenceInMs(a: Date, b: Date) {
  return a.getTime() - b.getTime()
}

function isInCloudflareStorage(video: UploadedVideo) {
  const createdTimeAgoMs = differenceInMs(new Date(), new Date(video.createdAt!))
  return createdTimeAgoMs < cloudflareStorageDurationInMs
}

export async function getUploadedVideosAsync() {
  const response = await getApiUploadedVideos() as UploadedVideo[]
  const videos = []
  for (const video of response) {
    const enhancedVideo = enhance(video)
    if (isInCloudflareStorage(video)) {
      videos.push(enhancedVideo)
    }
  }
  return videos
}

function enhance(video: UploadedVideo) {
  const validatedVideo = video
  return {
    ...validatedVideo,
    msRemaining: cloudflareStorageDurationInMs - differenceInMs(new Date(), new Date(validatedVideo.createdAt!))
  } as EnhancedUploadedVideo
}

export async function deleteUploadedVideo(videoId: string) {
  const { uploadedVideos, refetch } = useGetUploadedVideosQuery()
  uploadedVideos.value = uploadedVideos.value.filter((video) => video.id !== videoId)
  await deleteApiUploadedVideosVideoId(videoId)
  await removeFileFromStorage(videoId)
  await refetch()
}

export function useDeleteUploadedVideo(videoId: string) {
  const isRemoving = ref(false)
  return { 
    isRemoving,
    async remove() {
      isRemoving.value = true
      await deleteUploadedVideo(videoId)
      isRemoving.value = false
      }
  }
}

export async function updateUploadedVideo(videoId: string, updateUploadedVideoDto: UpdateUploadedVideoDto) {
  await putApiUploadedVideosVideoId(videoId, updateUploadedVideoDto)
  await useGetUploadedVideosQuery().refetch()
}

export function useUpdateUploadedVideo(videoId: string) {
  const isUpdating = ref(false)
  return { 
    isUpdating,
    async update(updateUploadedVideoDto: UpdateUploadedVideoDto) {
      isUpdating.value = true
      await updateUploadedVideo(videoId, updateUploadedVideoDto)
      isUpdating.value = false
    }
  }
}
