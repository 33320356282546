import type { CaptionStyle } from '@/components/Captions/styles/CaptionStyleManager'
import type { CaptionPreset, PartialCaptionPresetVariant, ColorStop } from '@/components/Captions/v3/CaptionPreset'
import { captionStylesSettings } from '@/components/Captions/styles/CaptionStyleManager'
import type { CaptionStyleDefinition } from '@/components/Captions/captionTypes'
import tinyColor from 'tinycolor2'

export function captionStyleToCaptionPreset(style: CaptionStyle, variant?: string, highlightColor?: string): CaptionPreset {
  const captionStyleDefinition = captionStylesSettings[style] as CaptionStyleDefinition
  return captionStyleDefinitionToCaptionPreset(captionStyleDefinition, variant, style, highlightColor)
}

export function captionStyleDefinitionToCaptionPreset(captionStyleDefinition: CaptionStyleDefinition, variant?: string, style?: string, highlightColor?: string) {
  const color = variant ?? captionStyleDefinition.colors[0]
  highlightColor = highlightColor ?? captionStyleDefinition.highlightColor;

  const preset = {
    key: style,
    createdAt: 'createdAt' in captionStyleDefinition
      ? captionStyleDefinition.createdAt
      : new Date(),
    animation: {
      style: 'none',
      speed: 0
    },
    font: toCaptionPresetFont(captionStyleDefinition, color, highlightColor),
    stroke: toCaptionPresetStroke(captionStyleDefinition, color, highlightColor),
    background: toCaptionPresetBackground(captionStyleDefinition, color, highlightColor),
    shadow: toCaptionPresetShadow(captionStyleDefinition, color, highlightColor),
    glow: toCaptionPresetGlow(captionStyleDefinition, color, highlightColor),
    tags: 'tags' in captionStyleDefinition && captionStyleDefinition.tags
      ? captionStyleDefinition.tags
      : [],
  } as Omit<CaptionPreset, 'variants'>

  const variants = captionStyleDefinition.colors
    .filter(c => c !== color)
    .map(c => colorToVariant(c, captionStyleDefinition))

  return {
    ...preset,
    variants: variants
  }
}

function toCaptionPresetFont(captionStyleDefinition: CaptionStyleDefinition, color: string, highlightColor: string) {
  return {
    editable: true,
    fontFamily: captionStyleDefinition.fontFamily,
    fontSize: captionStyleDefinition.fontSize.fontSize,
    fontWeight: tryGetPropertyOf(captionStyleDefinition.fontSize, 'fontWeight') ?? 400,
    leading: tryGetPropertyOf(captionStyleDefinition.fontSize, 'lineHeight') ?? 1.2,
    kerning: 0,
    textTransform: captionStyleDefinition.cssClass.includes('uppercase') ? 'uppercase' : 'none',
    color: selectColor(captionStyleDefinition, color),
    highlightColor: selectColor(captionStyleDefinition, highlightColor)
  }
}

const borderWidthScale = 4
function toCaptionPresetStroke(captionStyleDefinition: CaptionStyleDefinition, color: string, highlightColor: string) {
  const border = tryGetPropertyOf(captionStyleDefinition.style, 'border')
  return {
    editable: true,
    color: border?.color ? transformColor(border.color, color) : color,
    highlightColor: border?.color ? transformColor(border.color, highlightColor) : highlightColor,
    width: border?.width ? border.width * borderWidthScale : 0
  }
}

function toCaptionPresetBackground(captionStyleDefinition: CaptionStyleDefinition, color: string, highlightColor: string) {
  if (captionStyleDefinition.cssClass.includes('kappa')) {
    return {
      editable: true,
      color: transformColor(captionStyleDefinition.style.color, color),
      highlightColor: transformColor(captionStyleDefinition.style.color, highlightColor),
      opacity: 1,
      radius: 8
    }
  } else {
    return {
      editable: true,
      color: transformColor(captionStyleDefinition.style.color, color) === '#FFFFFF' ? '#000000' : '#FFFFFF',
      highlightColor: transformColor(captionStyleDefinition.style.color, highlightColor),
      opacity: 0,
      radius: 0
    }
  }
}

function toCaptionPresetShadow(captionStyleDefinition: CaptionStyleDefinition, color: string, highlightColor: string) {
  const shadow = tryGetPropertyOf(captionStyleDefinition.style, 'dropShadow')
  if (shadow) {
    return {
      editable: true,
      color: transformColor(shadow.color, color),
      highlightColor: transformColor(shadow.color, highlightColor),
      offsetX: shadow.offset?.x ?? 0,
      offsetY: shadow.offset?.y ?? 0,
      blur: shadow.blur,
      opacity: shadow.opacity ?? 1
    }
  } else {
    return {
      editable: true,
      color: color,
      highlightColor: highlightColor,
      offsetX: 0,
      offsetY: 0,
      blur: 0,
      opacity: 0
    }
  }
}

function toCaptionPresetGlow(captionStyleDefinition: CaptionStyleDefinition, color: string, highlightColor: string) {
  const glow = tryGetPropertyOf(captionStyleDefinition.style, 'shadow')
  if (glow) {
    return {
      editable: true,
      color: transformColor(glow.color, color),
      highlightColor: transformColor(glow.color, highlightColor),
      radius: glow.width
    }
  } else {
    return {
      editable: true,
      color: color,
      highlightColor: highlightColor,
      radius: 0
    }
  }
}

function colorToVariant(color: string, captionStyle: CaptionStyleDefinition): PartialCaptionPresetVariant {

  if (captionStyle.cssClass.includes('kappa')) {
    return {
      name: `Variant based on '${ color }'`,
      key: color,
      font: {
        color: color === '#FFFFFF' ? '#000000' : '#FFFFFF'
      },
      stroke: captionStyle.style.border ? {
        color: transformColor(captionStyle.style.border.color, color),
        highlightColor: transformColor(captionStyle.style.border.color, color)
      } : {},
      background: {
        color: transformColor(captionStyle.style.color, color),
        highlightColor: transformColor(captionStyle.style.color, color)
      },
      glow: captionStyle.style.shadow ? {
        color: transformColor(captionStyle.style.shadow.color, color),
        highlightColor: transformColor(captionStyle.style.shadow.color, color)
      } : {},
      shadow: captionStyle.style.dropShadow ? {
        color: transformColor(captionStyle.style.dropShadow.color, color),
        highlightColor: transformColor(captionStyle.style.dropShadow.color, color)
      } : {},
      tags: 'tags' in captionStyle && captionStyle.tags ? captionStyle.tags : []
    }
  }

  return {
    name: `Variant based on '${ color }'`,
    key: color,
    font: {
      color: findGradient(captionStyle, color) ?? transformColor(captionStyle.style.color, color),
      highlightColor: findGradient(captionStyle, color) ?? transformColor(captionStyle.style.color, color)
    },
    stroke: captionStyle.style.border ? {
      color: findBorderColor(captionStyle, color) ?? transformColor(captionStyle.style.border.color, color),
      highlightColor: findBorderColor(captionStyle, color) ?? transformColor(captionStyle.style.border.color, color)
    } : {},
    background: {},
    glow: captionStyle.style.shadow ? {
      color: transformColor(captionStyle.style.shadow.color, color),
      highlightColor: transformColor(captionStyle.style.shadow.color, color),
    } : {},
    shadow: captionStyle.style.dropShadow ? {
      color: transformColor(captionStyle.style.dropShadow.color, color),
      highlightColor: transformColor(captionStyle.style.dropShadow.color, color),
    } : {},
    tags: 'tags' in captionStyle && captionStyle.tags ? captionStyle.tags : []
  }
}

function selectColor(captionStyleDefinition: CaptionStyleDefinition, color: string) {
  const transformedColor = transformColor(captionStyleDefinition.style.color, color)
  if (captionStyleDefinition.cssClass.includes('kappa') || captionStyleDefinition.cssClass.includes('stroke')) {
    return transformedColor === '#FFFFFF' ? '#000000' : '#FFFFFF'
  } else {
    return findGradient(captionStyleDefinition, color) ?? transformedColor
  }
}

export function tryGetPropertyOf<
  T extends Record<string, unknown>,
  TKey extends keyof T | string
>(
  object: T | undefined,
  value: TKey
): T[TKey] | null {

  if (object && value in object && object[value] !== undefined) {
    return object[value]
  } else {
    return null
  }
}

function transformColor(transformer: 'default' | 'darker' | 'lighter' | string, baseColor: string) {

  if (transformer.startsWith('-')) {
    const value = Number(transformer.replace('-', ''))
    return tinyColor(baseColor).darken(value).toString()
  }

  if (transformer.startsWith('+')) {
    const value = Number(transformer.replace('+', ''))
    return tinyColor(baseColor).lighten(value).toString()
  }

  if (transformer === 'complement') return tinyColor(baseColor).complement().toString()
  if (transformer === 'default') return baseColor
  if (transformer === 'darker') return tinyColor(baseColor).darken(30).toString()
  if (transformer === 'lighter') return tinyColor(baseColor).lighten(70).toString()

  return transformer
}

function findBorderColor(captionStyle: CaptionStyleDefinition, color: string) {

  const border = 'borderColors' in captionStyle ? captionStyle.borderColors?.[color] : undefined
  if (border) {
    return border
  }
  
  return null
}

function findGradient(captionStyle: CaptionStyleDefinition, color: string) {

  const gradient = 'gradients' in captionStyle ? captionStyle.gradients?.[color] : undefined
  if (gradient) {

    const gradientRegexp = /(?<variant>linear|radial)-gradient\((?<direction>[a-z0-9 ]+),(?<colorStops>.*)\)/gi
    const match = gradientRegexp.exec(gradient)

    if (match?.groups) {
      const { variant, direction, colorStops } = match.groups
      return {
        variant: variant.trim() as 'linear' | 'radial',
        angle: angleFromDirection(direction.trim()),
        colorStops: colorStopsFromString(colorStops.trim())
      }
    }
  }

  return null
}

function colorStopsFromString(colorStops: string): ColorStop[] {
  const defaultColorStop = { color: '#000000', stop: 0 }
  return colorStops.split(',').map((colorStop, index) => {
    const colorStopMatch = /(?<color>[a-z0-9#]+) ?(?<stop>[0-9.]+)?%?/gi.exec(colorStop)
    if (!colorStopMatch) {
      return defaultColorStop
    } else {
      return {
        color: colorStopMatch.groups?.color ?? '#000000',
        stop: colorStopMatch.groups?.stop ? Number(colorStopMatch.groups.stop) / 100 : index / (colorStops.split(',').length - 1)
      }
    }
  }) ?? [defaultColorStop]
}

function angleFromDirection(direction: string) {
  if (direction.trim().endsWith('deg')) {
    return Number(direction.replace('deg', '').trim())
  }
  return directionToAngle(direction.trim())
}

function directionToAngle(direction: string) {
  return {
    'to right': 0,
    'to bottom right': 45,
    'to bottom': 90,
    'to bottom left': 135,
    'to left': 180,
    'to top left': 225,
    'to top': 270,
    'to top right': 315
  }[direction.trim()] ?? 0
}
