<template>
  <facebook-icon v-if="icon === 'facebook'" />
  <youtube-icon v-else-if="icon === 'youtube'" class="!min-h-0" />
  <kick-icon v-else-if="icon === 'kick'" />
  <instagram-icon v-else-if="icon === 'instagram'" />
  <tik-tok-icon v-else-if="icon === 'tiktok'" />
  <twitch-icon v-else />
</template>

<script>
import KickIcon from '@/components/Icons/SocialMedia/KickIcon.vue'
import TwitchIcon from '@/components/Icons/SocialMedia/TwitchIcon.vue'
import YoutubeIcon from '@/components/Icons/SocialMedia/YoutubeIcon.vue'
import InstagramIcon from '@/components/Icons/SocialMedia/InstagramSimpleIcon.vue'
import FacebookIcon from '@/components/Icons/FacebookIcon.vue'
import TikTokIcon from '@/components/Icons/SocialMedia/TikTokIcon.vue'

export default {
  components: {
    TwitchIcon,
    KickIcon,
    YoutubeIcon,
    InstagramIcon,
    FacebookIcon,
    TikTokIcon
  },
  props: {
    icon: {
      type: String,
      default: 'twitch',
    }
  },
}
</script>
