/**
 * Supplies strict typing to Object methods.
 */

// @ts-ignore
const keys = <T extends { [k: unknown]: unknown }, TKey extends keyof T>(record: T) => {
  return Object.keys(record) as TKey[]
}

// @ts-ignore
const values = <T extends { [k: unknown]: unknown }, TKey extends keyof T, TValue extends T[TKey]>(record: T) => {
  return Object.values(record) as TValue[]
}

// @ts-ignore
const entries = <T extends { [k: unknown]: unknown }, TKey extends keyof T, TValue extends T[TKey]>(record: T) => {
  return Object.entries(record) as [TKey, TValue][]
}

export default { keys, values, entries }
