import type { AsyncComponentLoader, ComputedRef, Raw } from 'vue'
import { computed, defineAsyncComponent, ref } from 'vue'
import { isAfter } from 'date-fns'
import type { UserInfoState } from '@/store/user/userInfo'
import { useUserInfoStore } from '@/store/user/userInfo'
import { tiers } from '@/enums/tiers'

type CampaignLocations = 'upgrade-hero' | 'deal-text' | 'home-hero' | 'home-cta' | 'generate-loader'

type CampaignStrings = 'home-input-placeholder'

type userValidationFunction = (user: UserInfoState) => Raw<AsyncComponentLoader> | undefined

export type Campaign = {
  id: string
  startDate: Date
  endDate: Date
  discountCode?: string
  yearlyDiscountCode?: boolean
  components: Record<CampaignLocations, Raw<AsyncComponentLoader> | userValidationFunction>
  strings?: Record<CampaignStrings, string | (() => string)>
}

export const campaigns = [
  {
    id: 'halloween',
    startDate: new Date(2023, 9, 20, 0, 0, 0),
    endDate: new Date(2023, 10, 2, 23, 59, 59),
    discountCode: 'SPOOKY50',
    yearlyDiscountCode: true,
    components: {
      'upgrade-hero': () => defineAsyncComponent(() => import('../components/Campaigns/Halloween/UpgradePageHeader.vue')),
      'home-hero': () => defineAsyncComponent(() => import('../components/Campaigns/Halloween/UpgradePageHeader.vue')),
      'home-cta': () => defineAsyncComponent(() => import('../components/Campaigns/Halloween/HomePageCTA.vue')),
      'deal-text': () => defineAsyncComponent(() => import('../components/Campaigns/Halloween/DealText.vue')),
    },
  }, {
    id: 'black-friday',
    startDate: new Date(2023, 10, 17, 0, 0, 0),
    endDate: new Date(2023, 10, 27, 23, 59, 59),
    discountCode: 'BLACKFRIDAYSL',
    yearlyDiscountCode: true,
    components: {
      'upgrade-hero': () => defineAsyncComponent(() => import('../components/Campaigns/BlackFriday/UpgradePageHeader.vue')),
      'home-hero': () => defineAsyncComponent(() => import('../components/Campaigns/BlackFriday/UpgradePageHeader.vue')),
      'home-cta': (store) => !store.isAuthenticated || store.tier === tiers.FREE
        ? defineAsyncComponent(() => import('../components/Campaigns/BlackFriday/HomePageCTA.vue'))
        : undefined,
      'deal-text': (store) => !store.isAuthenticated || store.tier === tiers.FREE
        ? defineAsyncComponent(() => import('../components/Campaigns/BlackFriday/UpgradePageCTA.vue'))
        : undefined,
    },
  }, {
    id: 'christmas', startDate: new Date(2023, 11, 0, 0, 0, 0), endDate: new Date(2023, 11, 25, 23, 59, 59), strings: {
      'home-input-placeholder': 'Ho ho ho, enter clip url',
    }, components: {
      'generate-loader': () => defineAsyncComponent(() => import('../components/Campaigns/Christmas/SantaWalking.vue')),
      'home-hero': () => defineAsyncComponent(async () => import('../components/Campaigns/Christmas/PageHeader.vue')),
      'upgrade-hero': () => defineAsyncComponent(async () => import('../components/Campaigns/Christmas/PageHeader.vue')),
    },
  },
] as const satisfies readonly Campaign[]

type CampaignName = (typeof campaigns)[number]['id']

const currentDate = ref(new Date())

export const getCurrentCampaign = (): Campaign | undefined => {
  return campaigns.find((campaign) => isAfter(currentDate.value, campaign.startDate) && isAfter(campaign.endDate, currentDate.value))
}

export const isCampaignActive = (campaignName: CampaignName): boolean => {
  const campaign = campaigns.find((campaign) => campaign.id === campaignName)
  if (!campaign) return false
  return isAfter(currentDate.value, campaign.startDate) && isAfter(campaign.endDate, currentDate.value)
}

export const useCurrentDate = () => {
  return computed({
    get() {
      return currentDate.value.toISOString().slice(0, 19)
    }, set(value: string) {
      currentDate.value = new Date(value)
    },
  })
}

export const useCurrentCampaign = (): ComputedRef<Campaign | undefined> => {
  return computed(() => campaigns.find((campaign) => isAfter(currentDate.value, campaign.startDate) && isAfter(campaign.endDate, currentDate.value)))
}

export const getCampaignString = (str: CampaignStrings): string | undefined => {
  const campaign = getCurrentCampaign()
  if (!campaign) return undefined
  const _str = campaign.strings?.[str]
  if (!_str) return undefined
  if (typeof _str === 'function') return _str()
  return _str
}

export const useCampaignComponent = (location: CampaignLocations) => {
  const userInfo = useUserInfoStore()
  const campaign = useCurrentCampaign()
  const componentOrFunction = computed(() => campaign.value?.components[location])
  return computed(() => {
    const _componentOrFunction = componentOrFunction.value
    if (!_componentOrFunction) return undefined
    if (typeof _componentOrFunction === 'function') {
      return _componentOrFunction(userInfo)
    } else {
      return _componentOrFunction
    }
  })
}
