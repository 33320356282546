<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { DialogContent, Dialog, DialogTrigger, DialogTitle, DialogDescription } from '@/components/ui/dialog'
import LoginForm from '@/pages/auth/LoginForm.vue'

const props = defineProps<{
  open?: boolean
  title?: string
}>();

const emit = defineEmits<{ 
  (event: 'update:open', open: boolean): void
  (event: 'loggedIn'): void
}>()

const localOpen = ref(props.open ?? false)
const isOpenModel = computed({
  get() {
    return localOpen.value
  },
  set(open: boolean) {
    emit('update:open', open)
    localOpen.value = open
  }
})

watch(() => props.open, (value) => localOpen.value = value);

function onLoggedIn() {
  isOpenModel.value = false
  emit('loggedIn')
}
</script>

<template>
  <Dialog v-model:open="isOpenModel">
    <DialogTrigger v-if="$slots.default">
      <slot />
    </DialogTrigger>
    <DialogContent class="app-bg grid md:grid-cols-2 w-full p-0 max-w-[1024px] min-h-[600px] shadow-2xl z-[300]">
      <LoginForm @loggedIn="onLoggedIn">
        <template #title>
          <DialogTitle as="h3" class="text-3xl !text-white font-bold text-center absolute top-1/4 -translate-y-full px-8">
            Convert your clips to viral<br> TikToks, Reels or Shorts!
          </DialogTitle>
        </template>
        <template #description>
          <DialogDescription class="font-light text-center">
            Log in or sign up with your email or Twitch - <br><span class="text-green-600">it's free!</span>
          </DialogDescription>
        </template>
      </LoginForm>
    </DialogContent>
  </Dialog>
</template>

<style lang="scss" scoped>
.streamladder-logo {
  background: linear-gradient(80.58deg, #21d2fe -4.97%, #a236fe 167.75%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
