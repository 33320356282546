import { defineStore } from 'pinia'
import { useEntityStore } from './useEntityStore'
import { computed, readonly, ref } from 'vue'
import { v4 as uuid } from 'uuid'
import { orderBy } from 'lodash-es'

export function useLayoutsStoreSetup(initialState: Record<string, Layout> = {}) {

  const { state, ids, entities, operations } = useEntityStore(initialState)

  return {
    state: state,
    ids: ids,
    entities: entities,

    ...operations,

    selectAvailableColors() {
      return computed(() => {
        const lowestUsage = Math.min(...colorOptions.map((color) => this.countByColor(color).value))
        const leastUsedColors = colorOptions.filter((color) => this.countByColor(color).value === lowestUsage)
        return leastUsedColors.length > 0 ? leastUsedColors : colorOptions
      })
    },

    countByColor(color: string) {
      return computed(() => entities.value.filter((layout) => layout.color === color).length)
    },

    selectNameById(id: string) {
      return computed({
        get() {
          return state[id].name
        },
        set(name: string) {
          state[id].name = name
        },
      })
    },

    sortByRecency() {
      return computed(() => orderBy(entities.value, (layout) => new Date(layout.createdAt).getTime(), 'desc'))
    },

    updateEmojiById(id: string, emoji: string) {
      state[id].emoji = emoji
    },
  }
}

export const useLayoutsStore = defineStore('entity-layouts', () => {

  const { state, ids, entities, ...operations } = useLayoutsStoreSetup()

  return {
    state: readonly(state),
    ids: ids,
    entities: entities,
    isLoading: ref(true),
    ...operations,
  }
})
export type LayoutsStore = ReturnType<typeof useLayoutsStoreSetup>

const colorOptions = [
  '#fbf8cc', '#fde4cf', '#ffcfd2',
  '#f1c0e8', '#cfbaf0', '#a3c4f3',
  '#90dbf4', '#8eecf5', '#98f5e1',
  '#b9fbc0',
] as const

export function newLayout(): Layout {
  const availableColors = useLayoutsStore().selectAvailableColors().value
  return {
    id: uuid(),
    name: 'New Layout',
    emoji: '🔥',
    color: availableColors[Math.floor(Math.random() * availableColors.length)],
    isCustom: true,
    createdAt: new Date().toISOString(),
  }
}

export type Layout = {
  id: string
  name: string
  emoji: string
  color: typeof colorOptions[number]
  isCustom: boolean
  createdAt: string
}
