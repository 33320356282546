<script setup lang="ts">
import { capitalize, ref, computed, watch } from 'vue'
import RadioToggleButton from '@/components-v2/data-input/RadioToggleButton.vue'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import tinyColor from 'tinycolor2'
import { Switch } from '@/components/ui/switch'
import TopEmoji from '@/components/Icons/Normalized/EmojiPlacement/TopEmoji.vue'
import BottomEmoji from '@/components/Icons/Normalized/EmojiPlacement/BottomEmoji.vue'
import { ScrollArea } from '@/components/ui/scroll-area'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import IconSaxSubtitle from '@/components/Icons/iconsax/IconSaxSubtitle.vue'
import IconSaxSetting2 from '@/components/Icons/iconsax/IconSaxSetting2.vue'
import CaptionEditor from '@/areas/editor/pages/captions/CaptionEditor.vue'
import CaptionStylePreview from '@/areas/editor/pages/captions/CaptionStylePreview.vue'
import { getLastUsedCaptionStyle } from '@/components/Captions/useLocalCaptionSettings'
import { captionStyleSettingsArray } from '@/components/Captions/styles/CaptionStyleManager'
import { intersection } from 'lodash-es'
import { isBefore } from 'date-fns'
import ToggleButton from '@/components-v2/data-input/ToggleButton.vue'
import IconSaxArrowDown2 from '@/components/Icons/iconsax/IconSaxArrowDown2.vue'
import IconSaxArrowUp2 from '@/components/Icons/iconsax/IconSaxArrowUp2.vue'
import { AlertDialog, AlertDialogFooter, AlertDialogContent, AlertDialogTitle, AlertDialogDescription, AlertDialogCancel, AlertDialogAction } from '@/components/ui/alert-dialog'
import { useConfirmDialog } from '@vueuse/core'
import { useGenerateCaptions } from '@/areas/editor/pages/captions/useGenerateCaptions'

const editorCaptionsStore = useEditorCaptionsStore()
const canUseEmojis = editorCaptionsStore.hasFeature('emojis')

const historyStore = useHistoryStore()
function updateHistoryStore() {
  // For some reason captions settings don't update until the next tick.
  setTimeout(() => historyStore.push(), 0)
}

const tab = ref<'text' | 'settings'>('text')

const tags = ['all', 'popular', 'playful', 'simple']
const selectedTag = ref('all')

const defaultCaptionStyle = ref(getLastUsedCaptionStyle())
const sortedCaptionsArray = computed(() => captionStyleSettingsArray
  .map((captionStyle) => ({ ...captionStyle, createdAt: captionStyle.createdAt || new Date(0) }))
  .sort((a, b) => {
    if (a.type === defaultCaptionStyle.value) {
      return -1
    } else if (b.type === defaultCaptionStyle.value) {
      return 1
    } else if (intersection(a?.tags, ['christmas', 'halloween']).length > 0) {
      return 1
    } else if (intersection(b?.tags, ['christmas', 'halloween']).length > 0) {
      return -1
    } else if (isBefore(a.createdAt, b.createdAt)) {
      return 1
    } else {
      return -1
    }
  }))

const styles = computed(() => {
  if (selectedTag.value === 'all') {
    return sortedCaptionsArray.value
  } else if (selectedTag.value === 'seasonal') {
    return sortedCaptionsArray.value.filter((style) => intersection(style?.tags, ['christmas', 'halloween']).length > 0)
  } else {
    return sortedCaptionsArray.value.filter((style) => style?.tags?.includes(selectedTag.value))
  }
})

const showMore = ref(false)

// As a side effect of setting 'Caption grouping' to single, we set Highlight the current spoken word to false and vice versa
watch(() => editorCaptionsStore.baseOptions.grouping, () => {
  if (editorCaptionsStore.baseOptions.grouping === 'single') {
    editorCaptionsStore.baseOptions.highlight = false
  } else {
    editorCaptionsStore.baseOptions.highlight = true
  }
}, { immediate: true })

const { reveal, isRevealed, confirm, cancel } = useConfirmDialog()
const grouping = computed({
  get: () => editorCaptionsStore.baseOptions.grouping,
  set: async (value) => {
    const { isCanceled } = await reveal()
    if (!isCanceled) {
      if (!editorCaptionsStore.captionsDocument) {
        const { generateCaptionsAsync } = useGenerateCaptions()
        await generateCaptionsAsync()
      }
      editorCaptionsStore.baseOptions.grouping = value
      updateHistoryStore()
    }
  }
})
</script>

<template>
  <div class="flex flex-row gap-1">
    <RadioToggleButton v-model="tab" value="text" size="lg" class="flex-col relative font-light data-[state=active]:font-semibold shrink-0">
      <span class="absolute inset-auto flex items-center gap-2">
        <IconSaxSubtitle class="w-4 h-4" /> Captions
      </span>
      <span class="font-semibold invisible flex items-center gap-2">
        <IconSaxSubtitle class="w-4 h-4" /> Captions
      </span>
    </RadioToggleButton>

    <RadioToggleButton v-model="tab" value="settings" size="lg" class="flex-col relative font-light data-[state=active]:font-semibold shrink-0">
      <span class="absolute inset-auto flex items-center gap-2">
        <IconSaxSetting2 class="w-4 h-4" /> Settings
      </span>
      <span class="font-semibold invisible flex items-center gap-2">
        <IconSaxSetting2 class="w-4 h-4" /> Settings
      </span>
    </RadioToggleButton>
  </div>

  <div class="relative h-full">
    <Transition
      :enter-from-class="tab === 'text' ? 'opacity-0 -translate-x-6' : 'opacity-0 translate-x-6'"
      :leave-to-class="tab === 'text' ? 'opacity-0 translate-x-6' : 'opacity-0 -translate-x-6'"
      enter-active-class="motion-safe:transition-[transform,_opacity] duration-1000"
      leave-active-class="motion-safe:transition-[transform,_opacity] duration-1000"
    >
      <div class="flex flex-col gap-4 absolute inset-0" :key="tab">
        <CaptionEditor v-if="tab === 'text'" />
        <ScrollArea class="min-h-0 overflow-y-auto -mx-4 px-4 2xl:-mx-8 2xl:px-8" v-if="tab === 'settings'">
          <div class="flex flex-col gap-2">
            <section class="flex flex-col gap-2">
              <header>
                <h3 class="text-lg font-semibold leading-snug">Caption size</h3>
                <p class="font-light">Change the base text size</p>
              </header>
  
              <div class="flex flex-row gap-1">
                <RadioToggleButton
                  v-for="option in [
                    { label: 'Large', value: 'large' },
                    { label: 'Medium', value: 'medium' },
                    { label: 'Small', value: 'small' },
                  ]"
                  :key="option.value" :value="option.value"
                  v-model="editorCaptionsStore.baseOptions.size"
                  @click="updateHistoryStore"
                  class="flex-col relative font-light data-[state=active]:font-semibold shrink-0 flex-auto p-2"
                >
                  <span class="absolute inset-auto">{{ capitalize(option.label) }}</span>
                  <span class="font-semibold invisible">{{ capitalize(option.label) }}</span>
                </RadioToggleButton>
              </div>
            </section>
  
            <div class="h-px bg-surface-panel-border shrink-0 my-4" />

            <section class="flex flex-col gap-2">
              <header>
                <h3 class="text-lg font-semibold leading-snug">Show captions per word or sentence</h3>
                <p class="font-light">Change whether to show captions as single words or short sentences.</p>
              </header>

              <div class="flex flex-row gap-1">
                <RadioToggleButton
                  v-for="option in [
                    { label: 'Single word', value: 'single' },
                    { label: 'Short sentence', value: 'group' },
                  ]"
                  :key="option.value" :value="option.value"
                  v-model="grouping"
                  class="flex-col relative font-light data-[state=active]:font-semibold shrink-0 flex-auto"
                >
                  <span class="absolute inset-auto">{{ capitalize(option.label) }}</span>
                  <span class="font-semibold invisible">{{ capitalize(option.label) }}</span>
                </RadioToggleButton>
              </div>
            </section>

            <div class="h-px bg-surface-panel-border shrink-0 my-4" />
  
            <section class="flex flex-col gap-2">
              <header>
                <h3 class="text-lg font-semibold leading-snug">Caption animation style</h3>
                <p class="font-light">Choose your preferred animation</p>
              </header>
  
              <div class="flex flex-row flex-wrap gap-1">
                <RadioToggleButton
                  v-for="option in [
                    { label: 'None', value: '' },
                    { label: 'Reveal', value: 'reveal' },
                    { label: 'Fade', value: 'fade-in' },
                    { label: 'Slide Right', value: 'slide-right' },
                    { label: 'Slide Top', value: 'slide-top' },
                    { label: 'Shrink', value: 'shrink' },
                  ]"
                  :key="option.value" :value="option.value"
                  v-model="editorCaptionsStore.baseOptions.animation"
                  @click="updateHistoryStore"
                  class="flex-col relative font-light data-[state=active]:font-semibold flex-auto shrink-0 p-2"
                >
                  <span class="absolute inset-auto">{{ capitalize(option.label) }}</span>
                  <span class="font-semibold invisible">{{ capitalize(option.label) }}</span>
                </RadioToggleButton>
              </div>
            </section>
  
            <div class="h-px bg-surface-panel-border shrink-0 my-4" />
  
            <Transition
              appear
              enter-from-class="opacity-0 translate-x-6"
              leave-to-class="opacity-0 translate-x-6"
              enter-active-class="motion-safe:transition-[transform,_opacity]"
              leave-active-class="motion-safe:transition-[transform,_opacity]"
            >
              <section v-if="editorCaptionsStore.baseOptions.grouping !== 'single'" class="flex flex-col gap-2">
                <header>
                  <h3 class="text-lg font-semibold leading-snug">Animate per word or sentence</h3>
                  <p class="font-light">Choose which part of the caption you want the animation to apply to</p>
                </header>
  
                <div class="flex flex-row flex-nowrap gap-1">
                  <RadioToggleButton
                    v-for="option in [
                      { label: 'Each word', value: 'word' },
                      { label: 'Whole sentence', value: 'sentence' },
                    ]"
                    :key="option.value" :value="option.value"
                    @click="updateHistoryStore"
                    v-model="editorCaptionsStore.baseOptions.animationTarget"
                    class="flex-col relative font-light data-[state=active]:font-semibold shrink-0 flex-1"
                  >
                    <span class="absolute inset-auto">{{ capitalize(option.label) }}</span>
                    <span class="font-semibold invisible">{{ capitalize(option.label) }}</span>
                  </RadioToggleButton>
                </div>
              </section>
            </Transition>

            <Transition
              appear
              enter-from-class="opacity-0 translate-x-6"
              leave-to-class="opacity-0 translate-x-6"
              enter-active-class="motion-safe:transition-[transform,_opacity]"
              leave-active-class="motion-safe:transition-[transform,_opacity]"
            >
              <div class="h-px bg-surface-panel-border shrink-0 my-4" v-if="editorCaptionsStore.baseOptions.grouping !== 'single'" />
            </Transition>

            <Transition
              appear
              enter-from-class="opacity-0 translate-x-6"
              leave-to-class="opacity-0 translate-x-6"
              enter-active-class="motion-safe:transition-[transform,_opacity]"
              leave-active-class="motion-safe:transition-[transform,_opacity]"
            >
              <section v-if="editorCaptionsStore.baseOptions.grouping !== 'single'" class="flex flex-col gap-2">
                <div class="flex justify-between gap-2">
                  <header>
                    <h3 class="text-lg font-semibold leading-snug">Highlight the current spoken word</h3>
                    <p class="font-light">Choose the preferred color of the spoken word</p>
                  </header>
                  <Switch v-model:checked="editorCaptionsStore.baseOptions.highlight" @click="updateHistoryStore" />
                </div>

                <Transition
                  appear
                  enter-from-class="opacity-0 translate-x-6"
                  leave-to-class="opacity-0 translate-x-6"
                  enter-active-class="motion-safe:transition-[transform,_opacity]"
                  leave-active-class="motion-safe:transition-[transform,_opacity]"
                >
                  <section v-if="editorCaptionsStore.baseOptions.highlight && editorCaptionsStore.styleOptions.data?.highlightColor">
                    <div class="flex flex-row flex-wrap gap-1">
                      <RadioToggleButton
                        v-for="color in editorCaptionsStore.captionStyleSettings.colors"
                        :key="color" :value="color"
                        v-model="editorCaptionsStore.styleOptions.data.highlightColor"
                        @click="updateHistoryStore"
                        class="flex-col relative font-light data-[state=active]:font-semibold shrink-0 p-1 h-auto bg-transparent border-transparent rounded-full"
                      >
                        <div
                          class="h-5 w-5 rounded-full"
                          :style="{
                          background: editorCaptionsStore.captionStyleSettings.gradients?.[color] ?? color,
                          borderColor: tinyColor(color).darken(40).toString(),
                        }"
                          :class="tinyColor(color).getBrightness() > 200 ? 'border' : ''"
                        />
                      </RadioToggleButton>
                    </div>
                  </section>
                </Transition>
              </section>
            </Transition>

            <Transition
              appear
              enter-from-class="opacity-0 translate-x-6"
              leave-to-class="opacity-0 translate-x-6"
              enter-active-class="motion-safe:transition-[transform,_opacity]"
              leave-active-class="motion-safe:transition-[transform,_opacity]"
            >
              <div class="h-px bg-surface-panel-border shrink-0 my-4" v-if="editorCaptionsStore.baseOptions.grouping !== 'single'" />
            </Transition>

            <Transition
              appear
              enter-from-class="opacity-0 translate-x-6"
              leave-to-class="opacity-0 translate-x-6"
              enter-active-class="motion-safe:transition-[transform,_opacity]"
              leave-active-class="motion-safe:transition-[transform,_opacity]"
            >
              <section v-if="editorCaptionsStore.styleOptions.data?.baseColor" class="flex flex-col gap-2">
                <header>
                  <h3 class="text-lg font-semibold leading-snug">Text color</h3>
                  <p class="font-light">Choose your preferred color</p>
                </header>
  
                <div class="flex flex-row flex-wrap gap-1">
                  <RadioToggleButton
                    v-for="color in editorCaptionsStore.captionStyleSettings.colors"
                    :key="color" :value="color"
                    v-model="editorCaptionsStore.styleOptions.data.baseColor"
                    @click="updateHistoryStore"
                    class="flex-col relative font-light data-[state=active]:font-semibold shrink-0 p-1 h-auto bg-transparent border-transparent rounded-full"
                  >
                    <div
                      class="h-5 w-5 rounded-full"
                      :style="{
                        background: editorCaptionsStore.captionStyleSettings.gradients?.[color] ?? color,
                        borderColor: tinyColor(color).darken(40).toString(),
                      }"
                      :class="tinyColor(color).getBrightness() > 200 ? 'border' : ''"
                    />
                  </RadioToggleButton>
                </div>
              </section>
            </Transition>
  
            <div class="h-px bg-surface-panel-border shrink-0 my-4" v-if="editorCaptionsStore.styleOptions.data?.baseColor" />

            <Transition
              appear
              enter-from-class="opacity-0 translate-x-6"
              leave-to-class="opacity-0 translate-x-6"
              enter-active-class="motion-safe:transition-[transform,_opacity]"
              leave-active-class="motion-safe:transition-[transform,_opacity]"
            >
              <section v-if="canUseEmojis" class="flex flex-col gap-4">
                <div class="flex justify-between gap-2">
                  <header>
                    <h3 class="text-lg font-semibold leading-snug">AI emojis 😎 (English only)</h3>
                    <p class="font-light">Quickly identify important keywords in your text</p>
                  </header>
                  <Switch v-model:checked="editorCaptionsStore.baseOptions.emojis" @click="updateHistoryStore" />
                </div>
                <div class="flex flex-col gap-2">
                  <header>
                    <h3 class="font-light leading-snug">Placement</h3>
                  </header>
  
                  <div class="flex flex-row gap-1">
                    <RadioToggleButton value="top" :disabled="!editorCaptionsStore.baseOptions.emojis"
                      v-model="editorCaptionsStore.baseOptions.emojiLocation"
                      @click="updateHistoryStore"
                      class="flex-col relative font-light data-[state=active]:font-semibold shrink-0 flex-auto"
                    >
                      <TopEmoji />
                    </RadioToggleButton>
                    <RadioToggleButton value="bottom" :disabled="!editorCaptionsStore.baseOptions.emojis"
                      v-model="editorCaptionsStore.baseOptions.emojiLocation"
                      @click="updateHistoryStore"
                      class="flex-col relative font-light data-[state=active]:font-semibold shrink-0 flex-auto"
                    >
                      <BottomEmoji />
                    </RadioToggleButton>
                  </div>
                </div>
              </section>
            </Transition>
  
            <div class="h-px bg-surface-panel-border shrink-0 my-4" v-if="canUseEmojis" />
  
            <section class="flex flex-col gap-2">
              <div class="flex justify-between gap-2">
                <header>
                  <h3 class="text-lg font-semibold leading-snug">Random rotation</h3>
                  <p class="font-light">Give each caption a slight and random rotation.</p>
                </header>
                <Switch v-model:checked="editorCaptionsStore.baseOptions.rotate" @click="updateHistoryStore" />
              </div>
            </section>
  
            <div class="h-px bg-surface-panel-border shrink-0 my-4" v-if="editorCaptionsStore.styleOptions.data?.baseColor" />
  
            <section class="flex flex-col gap-2">
              <div class="flex justify-between gap-2">
                <header>
                  <h3 class="text-lg font-semibold leading-snug">Remove punctuation</h3>
                  <p class="font-light">Remove all automatically added punctuation (such as ?,!. etc) from captions.</p>
                </header>
  
                <Switch v-model:checked="editorCaptionsStore.baseOptions.stripPunctuation" @click="updateHistoryStore" />
              </div>
            </section>
  
            <div class="h-px bg-surface-panel-border shrink-0 my-4" />
  
            <section class="flex flex-col gap-4">
              <div class="flex flex-col gap-0.5">
                <header>
                  <h3 class="text-lg font-semibold leading-snug">Style</h3>
                  <p class="font-light">Change your caption style.</p>
                </header>
  
                <div class="flex flex-row gap-1">
                  <RadioToggleButton
                    v-for="tag in tags" :key="tag" :value="tag" v-model="selectedTag"
                    size="sm" class="flex-col relative font-light data-[state=active]:font-semibold  shrink-0"
                  >
                    <span class="absolute inset-auto">{{ capitalize(tag) }}</span>
                    <span class="font-semibold invisible">{{ capitalize(tag) }}</span>
                  </RadioToggleButton>
                </div>
              </div>
  
              <div class="h-0 relative w-full" :class="showMore ? 'pb-[120%]' : 'pb-[60%]'">
                <div class="absolute inset-0 flex flex-col">
                  <div class="grid gap-2 grid-cols-[repeat(auto-fill,_minmax(130px,_1fr))] overflow-y-auto snap-y min-h-0">
                    <div v-for="(captionStyle, index) in styles" :key="captionStyle.type + index" class="relative snap-start">
                      <CaptionStylePreview
                        show-tier-badge
                        @click="editorCaptionsStore.captionStyle = captionStyle.type"
                        :settings="captionStyle"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <ToggleButton v-model="showMore">
                <template v-if="showMore">
                  Show less <IconSaxArrowUp2 class="w-4 h-4" />
                </template>
                <template v-else>
                  Show more <IconSaxArrowDown2 class="w-4 h-4" />
                </template>
              </ToggleButton> 
            </section>
          </div>
        </ScrollArea>
      </div>
    </Transition>
  </div>

  <AlertDialog v-model:open="isRevealed">
    <AlertDialogContent>
      <AlertDialogTitle class="text-lg font-bold">Update grouping</AlertDialogTitle>
      <AlertDialogDescription>
        Are you sure you want to change grouping? Any edits you've made to your captions will be lost.
      </AlertDialogDescription>
      <AlertDialogFooter>
        <AlertDialogCancel @click="cancel">
          Cancel
        </AlertDialogCancel>
        <AlertDialogAction @click="confirm">
          Confirm
        </AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>

<style scoped lang="scss">

</style>
