<template>
  <Tile :aspect=".6" :selected="selected" class="h-20" feature="captions" tabindex="0"
    @mouseleave="stopAnimation" @mouseover="startAnimation" 
    @resize="resize" @click="setLastUsedCaptionStyle(settings.type)"
  >
    <div class="w-full h-full grid place-items-center transition-[transform,_opacity]" :class="{ 'opacity-0 scale-75': !ready }">
      <CaptionElement
        v-if="!isActive"
        key="key-34234"
        :caption="captions[0]"
        :caption-style-definition="captionSettings"
        :enable-time-line-animation="false"
        class="text !relative"
        @ready="ready = true"
      />
      <CaptionElement
        v-for="(caption, index) in captions"
        v-else
        v-show="index === counter + 1"
        :key="caption.id"
        :caption="caption"
        :enable-time-line-animation="false"
        :caption-style-definition="captionSettings"
        class="text !relative"
      />
    </div>

    <OverlayBadges :is-new="isNewCaption(captionSettings)" :tags="captionSettings.tags" />
  </Tile>
</template>

<script lang="ts" setup>
import CaptionElement from '@/components/Captions/CaptionElement.vue'
import { useInterval } from '@vueuse/core'
import Tile from '@/components/Tile.vue'
import { isNewCaption } from '@/helpers/isNewOverlayElement'
import OverlayBadges from '@/components/OverlayBadges.vue'
import { useCaptionSettings } from '@/components/Captions/useCaptionSettings'
import type { CaptionStyleSettings } from '@/components/Captions/styles/CaptionStyleManager'
import { ref } from 'vue'
import { setLastUsedCaptionStyle } from '@/components/Captions/useLocalCaptionSettings'

const props = defineProps<{
  settings: CaptionStyleSettings,
  selected?: boolean
}>()

const { resize, captionSettings, captions, animationSpeed } = useCaptionSettings(props.settings, {
  width: 0, // Changing this value does not seem to have any effect
  height: 200,
})

const { counter, reset, pause, resume, isActive } = useInterval(animationSpeed, {
  controls: true,
  immediate: false,
  callback: () => {
    if (captions.length - 1 === counter.value) {
      reset()
    }
  },
})

const ready = ref(false)

const startAnimation = () => {
  resume()
}

const stopAnimation = () => {
  pause()
  reset()
}
</script>
