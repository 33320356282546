import UAParser from 'ua-parser-js'

export function throwIfBrowserUnsupported() {
  if (!isBrowserSupported()) {
    throw new UnsupportedBrowserError()
  }
}

export function isBrowserSupported() {
  // OffscreenCanvas is now polyfilled in src/lib/offscreenCanvasPolyfill.js
  return 'OffscreenCanvas' in window
}

export class UnsupportedBrowserError extends Error {

  constructor() {

    const parser = new UAParser()
    const result = parser.getResult()
    const browser = result.browser.name

    if (browser === 'Safari') {
      super(`Please update to the latest version of ${browser} and ${result.os.name} or try Chrome/Edge.`)
    } else if (browser === 'Chrome' || browser === 'Edge') {
      super(`Please update to the latest version of ${browser} to use StreamLadder.`)
    } else {
      super(`Please update to the latest version of ${browser} or try Chrome/Edge.`)
    }
    
    return this
  }
}
