import type { Ref } from 'vue'
import { onUnmounted, watch } from 'vue'
import { gsap } from 'gsap'
import { _globalTimeline } from '@/store/editor/editorVideo'

export const useTimeLine = () => {
  return _globalTimeline
}

let debounceTimeout: string | undefined
export const forceUpdateNextTick = () => {
  if (debounceTimeout) {
    clearTimeout(debounceTimeout)
    debounceTimeout = undefined
  }

  debounceTimeout = setTimeout(() => {
    _globalTimeline.invalidate()
    _globalTimeline.render(_globalTimeline.time(), true, true)
  }, 0)
}

export const useTimeLineAnimation = (
  target: any,
  createFunction: (timeline: gsap.core.Timeline) => void,
  localTimeline?: Ref<gsap.core.Timeline>
) => {
  let context: gsap.Context

  watch(target, (value) => {
    context?.revert()
    if (!value) return
    context = gsap.context(() => createFunction(_globalTimeline))
    forceUpdateNextTick()
  })

  onUnmounted(() => {
    context?.revert()
  })
}
