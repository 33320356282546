import { type VariantProps, cva } from 'class-variance-authority'

export { default as Button } from './Button.vue'

export const _buttonVariants = {
  variant: {
    default: 'bg-primary text-primary-foreground hover:bg-primary/90',
    primary: 'bg-primary text-primary-foreground hover:bg-primary/90 shadow-primary',
    outline: 'border-current font-bold text-primary hover:bg-primary/90 hover:text-background dark:text-secondary-foreground dark:hover:bg-secondary-foreground/10',
    toggle: 'bg-zinc-100 border-zinc-600 hover:bg-zinc-200 hover:border-zinc-300 text-zinc-1000 hover:bg-brand-state-hover-bg hover:border-brand-state-hover-border data-[state=active]:bg-brand-state-hover-bg data-[state=active]:border-brand-state-hover-border data-[state=active]:text-brand-state-active-border cursor-pointer',
    depressed: 'bg-zinc-300 dark:bg-zinc-400 hover:bg-zinc-100 dark:hover:bg-zinc-100',
    ghost: 'hover:bg-muted-foreground/10',
    destructive: 'bg-red-500 text-white hover:bg-red-600',
    ghostDestructive: 'text-red-500 hover:bg-[#fdefef] dark:hover:bg-[#53232599]',
    link: 'text-brand-state-link hover:underline underline-offset-2 transition-all font-light transition-none active:scale-100',
    gradient:
      'hover:shadow-gradient bg-gradient border-none flex cursor-pointer items-center justify-center gap-2 font-semibold text-white disabled:bg-none',
    dashboardOutline: 'gap-2 border-current text-white hover:bg-white/10  hover:bg-white/10 active:scale-90',
    dashboardOverlay:
      'gap-2 self-start rounded-xl bg-white font-bold text-indigo-500 transition-all hover:text-indigo-600 active:scale-90',
    clipgoat: 'bg-gradient-to-r from-[#E55F82] to-[#C9509D] hover:shadow-[6px_12px_48px_0px_#E55F824D] text-white font-bold',
    discord: 'bg-[#7289DA] text-white hover:bg-[#5B6DAE] focus-visible:ring-[#7289DA]',
  },
  size: {
    default: 'h-10 px-4 py-2',
    sm: 'h-7 rounded-lg px-3 font-semibold text-sm',
    lg: 'h-12 rounded-lg px-6',
    square: 'h-10 w-10 shrink-0',
    circle: 'h-10 w-10 rounded-full',
  },
}

export const buttonVariants = cva(
  'inline-flex items-center justify-center gap-2 border border-transparent active:scale-90 whitespace-nowrap rounded-lg text-md font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:!pointer-events-none disabled:!bg-brand-state-inactive disabled:!text-zinc-900 disabled:!border-transparent',
  {
    variants: _buttonVariants,
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

export type ButtonVariants = VariantProps<typeof buttonVariants>
