<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.3023 4.20541L19.788 6.68988L17.3023 4.20541ZM18.9008 2.01669L12.1797 8.73777C11.8324 9.08456 11.5956 9.52639 11.499 10.0076L10.8782 13.1152L13.9858 12.4932C14.467 12.397 14.9082 12.1611 15.2556 11.8137L21.9767 5.09264C22.1787 4.89067 22.3389 4.65089 22.4482 4.38701C22.5575 4.12312 22.6137 3.84029 22.6137 3.55466C22.6137 3.26903 22.5575 2.9862 22.4482 2.72232C22.3389 2.45843 22.1787 2.21866 21.9767 2.01669C21.7747 1.81472 21.535 1.65451 21.2711 1.5452C21.0072 1.4359 20.7244 1.37964 20.4387 1.37964C20.1531 1.37964 19.8703 1.4359 19.6064 1.5452C19.3425 1.65451 19.1027 1.81472 18.9008 2.01669V2.01669Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.34704 21.8121L3.96399 22.7358C4.55353 22.9803 5.19997 23.1152 5.87793 23.1152H8.37793V22.1152V21.1152H5.87793C5.46747 21.1152 5.08105 21.0339 4.7301 20.8884L4.34704 21.8121ZM13.3779 22.1152V23.1152H15.8779C16.5559 23.1152 17.2023 22.9803 17.7919 22.7358L17.4088 21.8121L17.0258 20.8884C16.6748 21.0339 16.2884 21.1152 15.8779 21.1152H13.3779V22.1152ZM5.87793 3.11523H8.37793V4.11523V5.11523H5.87793C5.46747 5.11523 5.08105 5.19656 4.7301 5.34209L4.34704 4.41836L3.96399 3.49464C4.55353 3.25017 5.19997 3.11523 5.87793 3.11523ZM1.87793 15.6152H0.87793V18.1152C0.87793 18.7932 1.01286 19.4396 1.25733 20.0292L2.18106 19.6461L3.10478 19.2631C2.95925 18.9121 2.87793 18.5257 2.87793 18.1152V15.6152H1.87793ZM1.87793 10.6152H0.87793V8.11523C0.87793 7.43728 1.01286 6.79083 1.25733 6.2013L2.18106 6.58435L3.10478 6.9674C2.95925 7.31835 2.87793 7.70477 2.87793 8.11523V10.6152H1.87793ZM19.8779 15.6152H20.8779V18.1152C20.8779 18.7932 20.743 19.4396 20.4985 20.0292L19.5748 19.6461L18.6511 19.2631C18.7966 18.9121 18.8779 18.5257 18.8779 18.1152V15.6152H19.8779Z"
      fill="currentColor"
    />
  </svg>
</template>
