import { useViewportSize } from '@/Hooks/useViewportSize'
import { computed } from 'vue'

// @ts-ignore
import compatabilityChecker from '@/services/compatabilityChecker'

export function useIsMobile() {
  const { width} = useViewportSize();
  return computed(() => width.value < 640 && compatabilityChecker.isMobile());
}
