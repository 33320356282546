<script setup lang="ts">
import { Input } from '@/components/ui/input'
import { IconLoaderQuarter } from '@tabler/icons-vue'
import { Button } from '@/components/ui/button'
import { ScrollArea } from '@/components/ui/scroll-area'
import GiphyElement from '@/components/Giphy/GiphyElement.vue'
import type { IGif } from '@giphy/js-types'
import { computed, ref, capitalize } from 'vue'
import { debouncedRef } from '@vueuse/core'
import { useInfiniteQuery } from '@tanstack/vue-query'
import { GiphyService } from '@/components/Giphy/GiphyService'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import { useEditorFocusStore, FocusTypes } from '@/store/editor/editorFocus'
import compatabilityChecker from '@/services/compatabilityChecker.js'
import RadioToggleButton from '@/components-v2/data-input/RadioToggleButton.vue'
import Image from '@/areas/editor/components/AppImage.vue'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useVideoStore } from '../../store/useVideoStore'
import { clamp } from 'lodash-es'

type Giph = {
  id: string;
  url: string;
  gif: IGif;
};

const search = ref('')

const selectedFilter = ref<(typeof filters)[number]['key']>('stickers')
const filters = [
  {
    key: 'stickers',
    name: 'Stickers',
  },
  {
    key: 'gifs',
    name: 'Gifs',
  },
]

const defaultStickerIds = [
  'di6rzuTRYgDOZ1f9ud',
  'XeXtOVyYF961jhiPbt',
  '8oAA2rCZ5P553MJnhM',
  '8ISjP4SqfYjNb8d5qd',
  'MZ5B2tnU2KkYAg1Vxr',
  'ZZwvAzBzemqdSE1HxB',
  'ekIiUW41nR5PUA6psw',
  'lVZnnXraJX440',
  'JY5dig6xQ9S7u',
  'BXjqytvu9bKzCUHdzz',
  '3YAxVrJrjWIeeADRM7',
  'Ve5hR4qmFYrAKWQbj6',
  'ibjOWWBs0x3c2syap9',
  'aXPw3suOkxrBC',
  'FaGFQRGDArn3QpLbPc',
  'IxxwlgHmmkEW9bdsLL'
];

const defaultGifIds = [
  'l3q2K5jinAlChoCLS',
  'puOukoEvH4uAw',
  'NEvPzZ8bd1V4Y',
  'FY8c5SKwiNf1EtZKGs',
  'QMHoU66sBXqqLqYvGO',
  'ne3xrYlWtQFtC',
  'xUPJPD4OF0KUDosIHC',
  'tvU9iTev6uBIQ',
  'vFtWp05vBYnMQ',
  'xTiN0CNHgoRf1Ha7CM',
  '9BzrkrC8KIuhtM1b0B'
];

const throttledSearch = debouncedRef(search, 250)
const { data, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
  queryKey: ['giphy', selectedFilter, throttledSearch],
  queryFn: async (params) => {
    if (throttledSearch.value) {
      return GiphyService.search(throttledSearch.value, {
        type: selectedFilter.value,
        offset: params.pageParam,
        limit: 20
      })
    } else {
      if (selectedFilter.value === 'stickers') {
        return GiphyService.gifs(defaultStickerIds);
      } else {
        return GiphyService.gifs(defaultGifIds);
      }
    }
  },
  initialPageParam: 0,
  getNextPageParam: (lastPage) => {
    return lastPage.pagination.offset + lastPage.pagination.count
  },
  placeholderData: (data) => data,
  staleTime: 1000 * 60 * 5 // 5 minutes
})

const toListItem = (gif: IGif): Giph => ({
  id: gif.id.toString(),
  url: gif.images.downsized_medium.url,
  gif: gif,
})

const gifs = computed(() => data.value?.pages.flatMap(page => page.data.map(toListItem)));

const stickersStore = useStickersStore()
const focusStore = useEditorFocusStore()

const videoStore = useVideoStore();

async function gifSelected({ gif }: Giph) {

  const aspectRatio = gif.images.original.width / gif.images.original.height;
  const screenWidth = 1080;
  const screenHeight = 1920;
  const absoluteWidth = screenWidth * 0.5;
  const absoluteHeight = absoluteWidth / aspectRatio;

  const gifVideo = document.createElement('video');
  gifVideo.src = gif.images.fixed_width_small.mp4;
  await new Promise((resolve) => {
    gifVideo.ondurationchange = () => resolve(null);
    gifVideo.onerror = () => resolve(null);
  })

  const videoDurationMs = videoStore.videoElement!.duration * 1000;
  const videoCurrentTimeMs = videoStore.videoElement!.currentTime * 1000;
  const minDurationMs = Math.max(gifVideo.duration * 1000, Math.min(videoDurationMs, 5000))

  const startMs = clamp(videoCurrentTimeMs, 0, videoDurationMs - minDurationMs)
  const endMs = Math.min(startMs + minDurationMs, videoDurationMs)
  
  const stickerId = stickersStore.createGiphySticker({
    imageUrl: gif.images.original.webp,
    naturalWidth: gif.images.original.width,
    naturalHeight: gif.images.original.height,
    key: gif.id.toString(),
    area: {
      x: (0.5 * screenWidth - 0.5 * absoluteWidth) / screenWidth,
      y: 0.1 + Math.random() * (0.9 - absoluteHeight / screenHeight),
      width: absoluteWidth / screenWidth,
      height: absoluteHeight / screenHeight,
    },
    startMs: startMs,
    endMs: endMs,
    editing: false,
  })

  if (compatabilityChecker.isMobile()) {
    window.scrollTo(0, 0);
  }

  setTimeout(() => focusStore.setFocus(FocusTypes.STICKER, stickerId), 0)

  useHistoryStore().push()
}
</script>

<template>
  <div class="flex flex-col gap-4 p-4 pb-0 2xl:p-8 h-full sm:h-screen">
    <header class="hidden md:block">
      <h2 class="text-xl font-semibold leading-snug">Gifs</h2>
      <p class="font-light">
        Add gifs to your clip to make it more engaging!
      </p>
    </header>

    <div class="flex flex-col gap-2 shrink-0">
      <div class="flex flex-row gap-2">

        <div class="w-full min-w-0 overflow-x-auto overflow-y-hidden">
          <div class="flex flex-row gap-1">
            <RadioToggleButton
              v-for="filter in filters" :key="filter.key" :value="filter.key" v-model="selectedFilter"
              size="sm" class="flex-col relative font-light data-[state=active]:font-semibold shrink-0"
            >
              <span class="absolute inset-auto">{{ capitalize(filter.name) }}</span>
              <span class="font-semibold invisible">{{ capitalize(filter.name) }}</span>
            </RadioToggleButton>
          </div>
        </div>
        
        <a href="https://giphy.com/" target="_blank" class="ml-auto w-24 mt-0.5 mr-6 md:mr-0 md:mt-0">
          <Image src="/images/giphy/powered-by.png" alt="Powered by Giphy" class="max-w-full rounded hover:scale-105 transition-all" />
        </a>
      </div>
      <Input class="font-light transition-all" v-model="search" placeholder="Search Giphy" />
    </div>

    <ScrollArea class="min-h-0 overflow-y-auto -mx-4 px-4 2xl:-mx-8 2xl:px-8" v-if="gifs && gifs.length > 0">
      <div class="h-full p-1 grid gap-[8px] grid-rows-[repeat(auto-fill,_4px)] grid-cols-2 lg:grid-cols-[repeat(auto-fill,_minmax(120px,_1fr))]">
        <GiphyElement v-for="item in gifs" :key="item.id" :item="item" @click="gifSelected(item)" />
      </div>
    </ScrollArea>

    <Button v-if="isFetchingNextPage" variant="ghost" class="w-full" @click="fetchNextPage">
      <IconLoaderQuarter class="animate-spin h-6 w-6 text-company-primary-50" />
    </Button>

    <Button v-else-if="gifs && gifs.length > 3 && throttledSearch" variant="depressed" class="w-full !border-surface-panel-border" @click="fetchNextPage">
      Load more
    </Button>

    <div v-else-if="!gifs || gifs?.length === 0" class="flex justify-center items-center text-center text-sm font-light h-full shadow-inner rounded">
      No results found 🙁
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
