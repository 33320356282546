<template>
  <img v-if="imageUrl" @load="onAssetLoad" :src="imageUrl" />
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      default: '',
    },
  },
  methods: {
    onAssetLoad() {
      this.$emit('stickerLoaded', this)
    },
  },
}
</script>

<style lang="scss" scoped></style>
