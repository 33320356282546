import type { CaptionStyleDefinition } from '@/components/Captions/captionTypes'

export const halloweenCaptionStyles: Record<string, CaptionStyleDefinition> = {
  'blood-on-your-hands': {
    previewSettings: {
      animationSpeed: 750,
      lottieUrl: '',
      displayName: 'Blood',
      scaling: 1.5,
      previewText: [
        'Blood',
        'The vampire 🧛‍♂️',
        'is a bloodsucking',
        'creature of the night',
        'that can turn into',
        'a bat 🦇',
      ],
    },
    fontFamily: 'Brain Melt',
    fontSize: {
      fontSize: 31,
      lineHeight: 1.1,
    },
    cssClass: '',
    style: {
      border: {
        color: '#000000',
        width: 2,
      },
      dropShadow: {
        color: '#000000',
        offset: { x: 4, y: 4 },
        blur: 0,
      },
      color: 'default',
    },
    colors: ['#F9D828', '#00B728', '#FF7518', '#2895F9', '#28F93D', '#F5F928', '#F928CB'],
    highlightColor: '#00B728',
    gradients: {
      '#F9D828': 'linear-gradient(to bottom, #F92828, #F9D828)',
      '#00B728': 'linear-gradient(to bottom, #28F9D3, #00B728)',
    },
    tags: ['halloween'],
    effects: {},
    disableHighlight: false,
  },

  'stinky-slime': {
    previewSettings: {
      animationSpeed: 750,
      lottieUrl: '',
      displayName: 'Slime',
      scaling: 1.2,
      previewText: [
        'Slime',
        'The gelatinous cube',
        'is a 10ft cube',
        'of transparent slime',
        'that smells like',
        'a dead body 💀',
      ],
    },
    fontFamily: 'Stinky Slime',
    fontSize: {
      fontSize: 33,
      lineHeight: 1.1,
    },
    cssClass: '',
    style: {
      border: {
        color: 'default',
        width: 1,
      },
      dropShadow: {
        color: 'default',
        offset: { x: 2, y: 2 },
        blur: 3,
        opacity: 0.75,
      },
      color: 'default',
    },
    colors: ['#009245', '#D4145A', '#933ACE', '#EE9CA7', '#4E65FF', '#F2994A'],
    highlightColor: '#EE9CA7',
    gradients: {
      '#009245': 'radial-gradient(circle, #FCEE21, #009245)',
      '#D4145A': 'radial-gradient(circle, #FBB03B, #D4145A)',
      '#933ACE': 'radial-gradient(circle, #ED1E79, #933ACE)',
      '#EE9CA7': 'radial-gradient(circle, #FFDDE1, #EE9CA7)',
      '#4E65FF': 'radial-gradient(circle, #92EFFD, #4E65FF)',
      '#F2994A': 'radial-gradient(circle, #F2C94C, #F2994A)',
    },
    borderColors: {
      '#009245': '#097600',
      '#D4145A': '#640024',
      '#933ACE': '#580020',
      '#EE9CA7': '#6F2F46',
      '#4E65FF': '#1833ED',
      '#F2994A': '#B65B0B',
    },
    tags: ['halloween'],
    effects: {},
    disableHighlight: false,
  },
  'the-season-of-the-witch': {
    previewSettings: {
      animationSpeed: 750,
      lottieUrl: '',
      displayName: 'Enchanted',
      scaling: 1.5,
      previewText: [
        'Enchanted',
        'A hag 🧟‍♀️',
        'is a wretched creature',
        'that lives in a swamp',
        'and eats children',
        'and the flesh',
        'of the dead 💀',
      ],
    },
    fontFamily: 'Haunted Witch',
    fontSize: {
      fontSize: 29,
      lineHeight: 1.1,
    },
    cssClass: '',
    style: {
      border: {
        color: '#99325F',
        width: 1,
      },
      dropShadow: {
        color: '#32101F',
        offset: { x: 0, y: 0 },
        blur: 4,
        opacity: 0.75,
      },
      color: 'default',
    },
    colors: ['#E087FF', '#FFC700', '#87FF93', '#00B728', '#F9D828', '#009245', '#EE9CA7'],
    highlightColor: '#FFC700',
    gradients: {
      '#E087FF': 'linear-gradient(to bottom, #00F0FF, #E087FF)',
      '#FFC700': 'linear-gradient(to bottom, #E087FF, #FFC700)',
      '#87FF93': 'linear-gradient(to top, #00E0FF, #87FF93)',
      '#009245': 'radial-gradient(circle, #FCEE21, #009245)',
      '#EE9CA7': 'radial-gradient(circle, #FFDDE1, #EE9CA7)',
      '#00B728': 'linear-gradient(to bottom, #28F9D3, #00B728)',
      '#F9D828': 'linear-gradient(to bottom, #F92828, #F9D828)',
    },
    borderColors: {
      '#E087FF': '#99325F',
      '#FFC700': '#99325F',
      '#87FF93': '#99325F',
      '#009245': '#097600',
      '#EE9CA7': '#6F2F46',
      '#00B728': '#097600',
      '#F9D828': '#99325F',
    },
    tags: ['halloween'],
    effects: {},
    disableHighlight: false,
  },
  'ghoulish-delight': {
    previewSettings: {
      animationSpeed: 750,
      lottieUrl: '',
      displayName: 'Cursed',
      scaling: 1.5,
      previewText: [
        'Cursed',
        'A ghoul 🧟‍♀️',
        'is a creature that',
        'lives in a graveyard',
        'and eats the flesh',
        'of the dead 💀',
      ],
    },
    fontFamily: 'Vermillion',
    fontSize: {
      fontSize: 20,
      lineHeight: 1.5,
    },
    cssClass: 'uppercase stroke',
    style: {
      border: {
        color: 'default',
        width: 2,
      },
      dropShadow: {
        color: 'default',
        offset: { x: 4, y: 4 },
        blur: 0,
        opacity: 0.5,
      },
      color: 'default',
    },
    colors: ['#FF006E', '#00C3C3', '#333333', '#ff00ff', '#ea4040', '#009f50', '#006be9', '#FFFFFF'],
    highlightColor: '#00C3C3',
    tags: ['halloween'],
    effects: {},
    disableHighlight: false,
  },
}
