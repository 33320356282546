<template>
  <div ref="captionElement" class="pointer-events-none inset-0" :class="captionStyleDefinition.cssClass" :style="styles">
    <CaptionText :caption="{ ...caption, words: captionWordsWithSpaces }" :caption-style-definition="captionStyleDefinition" />
  </div>
</template>
<script setup lang="ts">
import { computed, ref, onMounted } from 'vue'
import type { Ref, StyleValue } from 'vue'
import { interpolate } from '@/components/Editor/Timeline/helpers'
import type { CaptionStyleDefinition } from '@/components/Captions/captionTypes'
import CaptionText from '@/components/Captions/CaptionText.vue'
import { useTimeLineAnimation } from '@/components-v2/modules/VideoPlayer/useVideoManager'
import type { StoredCaption } from '@/components/Captions/captionTypes'
import { useFontsStore } from '@/store/fonts'

const props = withDefaults(
  defineProps<{
    caption: StoredCaption
    captionStyleDefinition: CaptionStyleDefinition
    enableTimeLineAnimation?: boolean
  }>(),
  {
    enableTimeLineAnimation: true,
  }
)

const emit = defineEmits<{
  (e: 'ready'): void
}>()

const captionWordsWithSpaces = computed(() => {
  // Add space before each word except the first one, to make sure the words are separated.
  // This way we can ensure captions behave the same as text stickers.
  return props.caption.words.map((w, i) => ({ ...w, text: i > 0 ? ' ' + w.text : w.text }))
})

const _text = computed(() => {
  const _text = props.caption.htmlText || props.caption.text
  return _text
})

const captionElement = ref<HTMLElement | null>(null)

if (props.enableTimeLineAnimation) {
  useTimeLineAnimation([captionElement, props], (globalTimeline) => {
    if (!captionElement.value || props.caption.start < 0 || props.caption.end < 0) {
      return
    }

    const wordStart = Math.max(props.caption.start / 1000, 0)
    const wordEnd = Math.max(props.caption.end / 1000, 0)
    globalTimeline
      .set(
        captionElement.value,
        {
          autoAlpha: 0,
        },
        0
      )
      .fromTo(
        captionElement.value,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 0,
        },
        wordStart
      )
      .fromTo(
        captionElement.value,
        {
          autoAlpha: 1,
        },
        {
          autoAlpha: 0,
          duration: 0,
        },
        wordEnd
      )
      .set(
        captionElement.value,
        {
          autoAlpha: 0,
        },
        globalTimeline.duration() - 0.1
      )
  })
}

const styles: Ref<StyleValue> = computed(() => {
  // random rotation
  const rotation = props.captionStyleDefinition.effects.rotate
    ? interpolate(
        -1 * props.captionStyleDefinition.effects.rotate,
        props.captionStyleDefinition.effects.rotate,
        props.caption.randomizer || 0.5
      )
    : 0

  const textCount = _text.value.replace(/<\/?[^>]+(>|$)/g, '').length

  // // scale based on text length
  // const textBasedScale = 20 / textCount
  //
  // const clampTextBasedScale = clamp(
  //   textBasedScale,
  //   props.captionStyleDefinition.sizing.minScale,
  //   props.captionStyleDefinition.sizing.maxScale
  // )

  const scale = props.captionStyleDefinition.fontSize.scale || 1

  return {
    transform: `rotate(${rotation}deg)`,
    'font-size': props.captionStyleDefinition.fontSize.fontSize * scale + 'px',
    lineHeight: props.captionStyleDefinition.fontSize.lineHeight || 1.2,
  }
})

const fontsStore = useFontsStore()
onMounted(async () => {
  await fontsStore.loadFontByLabel(props.captionStyleDefinition.fontFamily)
  emit('ready')
})
</script>

<style scoped>
div {
  position: absolute;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
</style>
