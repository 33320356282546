import type { Clip } from '@/Hooks/useTwitchClips'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { prepareStores } from '@/areas/editor/startup/generalStartupMethods'
import { retryAsync } from '@/helpers/retry'
import type { StartupConfig } from '@/areas/editor/startup/StartupConfig'
import { handleStartupError } from '@/areas/editor/startup/handleStartupError'
import { throwIfBrowserUnsupported } from '@/areas/editor/startup/UnsupportedBrowserError'
import { repairTwitchClipAudio } from './repairTwitchClip'

export async function startupFromTwitchClipByClipId(clipId: string, config: StartupConfig, mp4UrlOverride?: string) {

  const editorClipInfoStore = useEditorClipInfoStore()
  try {

    throwIfBrowserUnsupported()

    editorClipInfoStore.loadingState = {
      state: 'loading',
      description: 'Fetching clip info. This may take a while...'
    }

    const response = await retryAsync(() => fetch(`https://streamladder-twitchclipimporter.fly.dev/clip/${clipId}`, { signal: config.signal.value })
      .then(response => response.json() as Promise<Clip>)
      .catch((reason) => {
        if (reason instanceof DOMException && reason.name === 'AbortError') {
          throw reason
        } else {
          throw new Error('Failed to fetch clip info')
        }
      }))
    
    config.signal.value?.throwIfAborted()

    isValidClipOrThrow(response)
    const clip = {
      ...response,
      id: clipId,
      mp4Url: mp4UrlOverride ?? response.mp4Url
    }

    // TODO: We expect this to be removed in the future if Twitch has fixed the issues with Clips.
    const audioMightBeOutOfSync = clip.mp4Url.startsWith('https://twitch-clips-v2.b-cdn.net/')

    if (!mp4UrlOverride && audioMightBeOutOfSync) {
      await startupFromTwitchClip(clip, config)

      const clipInfoStore = useEditorClipInfoStore();
      clipInfoStore.uploadRepairedTwitchClip = repairTwitchClipAudio(clip.mp4Url);

      return { error: null };
    } else {
      return await startupFromTwitchClip(clip, config)
    }
  } catch (error) {
    return handleStartupError(error)
  }
}

function isValidClipOrThrow(clip: Clip) {

  if (!clip?.id) {
    throw new Error('Clip not found')
  }
  
  if (!clip.mp4Url) {
    throw new Error('Clip does not have a video source')
  }
}



export async function startupFromTwitchClip(clip: Clip, config: StartupConfig) {

  const editorClipInfoStore = useEditorClipInfoStore()
  editorClipInfoStore.id = clip.id
  editorClipInfoStore.title = clip.title
  editorClipInfoStore.viewCount = clip.viewCount
  editorClipInfoStore.mp4Url = clip.mp4Url
  editorClipInfoStore.thumbnailUrl = clip.thumbnailUrl
  editorClipInfoStore.source = 'twitch-clip'
  editorClipInfoStore.languageCode = clip.languageCode?.toLowerCase() ?? 'en_us'
  editorClipInfoStore.isLocalFile = false

  return await prepareStores(config)
}
