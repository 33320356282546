import EventBus from '@/eventBus'
import upgradeEvents from '@/events/upgradeEvents'
import features, { type Feature } from '@/data/features'

export const upgradeDialog = {
  open: open,
  close() {
    EventBus.$emit(upgradeEvents.CLOSE_UPGRADE_DIALOG)
  },
}

export type UpgradeDialogPayload = ({ feature: Feature } | { tier: 0 | 10 | 100 } | Record<never, never>) & {
  reason: string
  title?: string
  subtitle?: string
}

function open(command: Feature | UpgradeDialogPayload): void {
  if (typeof command === 'string') {
    const feature = features.find((f) => f.name === command)
    const reason = `User has no access to feature: ${command}`
    EventBus.$emit(upgradeEvents.OPEN_UPGRADE_DIALOG, {
      reason: feature && 'promo' in feature ? feature.promo.reason : reason,
      feature: command,
    })
  } else {
    EventBus.$emit(upgradeEvents.OPEN_UPGRADE_DIALOG, command)
  }
}
