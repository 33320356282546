import { defineStore, acceptHMRUpdate } from 'pinia'
import type { LayoutKey } from '@/data/layoutData'
import type { ScaleSet } from '@/modules/SLVideoplayer/types'
import type { StickerData } from '@/store/editor/editorStickers'

interface UserTemplatesState {
  savedTemplates: Template[]
  isLoading: boolean
}

export const useUserTemplatesStore = defineStore('userTemplates', {
  state: (): UserTemplatesState => {
    return {
      savedTemplates: [] as Template[],
      isLoading: true
    }
  },
  getters: {
    getTemplateById: (state) => (id: string) => {
      return state.savedTemplates.find((t) => t.id === id)
    },
  },
})

type BaseTemplate = {
  id: string
  layoutName: LayoutKey
  displayName: string
  templateName: string
  ratio: string
  createdTime: string
  croppers: {
    cropperKey: string
    data: ScaleSet
  }[]
  feeds: {
    feedKey: string
    data: ScaleSet
  }[]
  stickers: StickerData[]
}

type CustomTemplate = Omit<BaseTemplate, 'layoutName'> & {
  layoutName: 'custom'
  layoutId: string
}

export type Template = BaseTemplate | CustomTemplate

// Allows hot-reloading of the store
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useUserTemplatesStore, import.meta.hot))
}
