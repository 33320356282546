import { addYears } from 'date-fns'

const globalDomain = '.' + window.location.hostname.split('.').slice(-2).join('.')

export function storeLoggedInStateCookie(isLoggedIn: boolean) {
  if (isLoggedIn) {
    document.cookie = formatCookie('__is_logged_in', 'true', {
      expires: addYears(new Date(), 1),
      domain: globalDomain,
    })
  } else {
    // Remove cookie
    document.cookie = formatCookie('__is_logged_in', 'false', {
      expires: new Date(0),
      domain: globalDomain,
    })
  }
}

function formatCookie(
  name: string,
  value: string,
  options: {
    expires?: Date
    domain?: string
    path?: string
    sameSite?: 'Lax' | 'Strict'
  } = {}
) {
  const cookie = name + '=' + value
  const expires = options.expires ? 'expires=' + options.expires.toUTCString() : ''
  const domain = options.domain ? 'domain=' + options.domain : ''
  const path = options.path ? 'path=' + options.path : ''
  const sameSite = 'SameSite=' + (options.sameSite ?? 'Strict')

  return [cookie, expires, domain, path, sameSite, 'secure'].filter(Boolean).join('; ')
}
