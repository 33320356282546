import { WSAdapter, WSChannel } from '@/modules/WebsocketService/WebsocketAdapter'
import Pusher, { Channel, Options } from 'pusher-js'

class PusherChannelAdapter extends WSChannel {
  private channel: Channel

  constructor(channel: Channel) {
    super(channel.name)
    this.channel = channel
  }

  public bind(eventName: string, callback: (data: any) => void) {
    this.channel.bind('event', callback)
  }

  public bind_global(callback: (eventName: string, data: any) => void) {
    this.channel.bind_global(callback)
  }

  public unbind_global(callback: (data: any) => void) {
    this.channel.unbind_global(callback)
  }

  public disconnect() {
    this.channel.unbind()
  }
}

export class PusherAdapter extends WSAdapter {
  private pusherClient: Pusher | null = null
  private readonly appKey: string
  private readonly options: Options | undefined

  constructor(appKey: string, options?: Options) {
    super()
    this.appKey = appKey
    this.options = options
    console.log('pusherClient created setting up connection')
  }

  public connect() {
    this.pusherClient = new Pusher(this.appKey, this.options || { cluster: 'us3' })
    console.log('pusherClient created setting up connection')
  }

  public disconnect() {
    this.pusherClient?.disconnect()
    this.pusherClient = null
    console.log('pusherClient removed stopped connection')
  }

  public isConnected(): boolean {
    return !!this.pusherClient
  }

  public subscribe(channel: string) {
    const pusherChannel = this.pusherClient?.subscribe(channel)
    if (!pusherChannel) {
      throw new Error('Channel not found')
    }
    return new PusherChannelAdapter(pusherChannel)
  }
}
