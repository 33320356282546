import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { UnsupportedBrowserError } from '@/areas/editor/startup/UnsupportedBrowserError'

export function handleStartupError(error: unknown) {

  const editorClipInfoStore = useEditorClipInfoStore()
  
  if (error instanceof UnsupportedBrowserError) {
    
    editorClipInfoStore.loadingState = {
      state: 'error',
      title: 'Browser Issue Detected! 🚫',
      description: error.message + ' Still having issues? Open a ticket for help! 💬'
    }
    
    return { error: error.message }

  } if (error instanceof DOMException && error.name === 'AbortError') {
    editorClipInfoStore.$reset()
    return { error: null }
  } else {

    const message = determineMessageOf(error)
    editorClipInfoStore.loadingState = {
      state: 'error',
      description: message
    }
    
    return { error: message }
  }
}

function determineMessageOf(error: unknown) {
  if (error instanceof Error) {
    return error.message
  } else if (typeof error === 'string') {
    return error
  } else if (typeof error === 'object' && error?.toString?.()) {
    return error.toString()
  } else {
    return 'An error occurred.'
  }
}
