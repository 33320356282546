import { posthog } from 'posthog-js'
import { ref, type Ref, reactive, watch, computed } from 'vue'

export const FEATURE_FLAGS = {
  'three-column-layout': {
    variants: ['control', 'wide'],
  },
  'clip-gpt': {
    variants: ['control', 'clipgpt'],
  },
  'upgrade-dialog-two-cards-with-two-steps': {
    variants: ['control', 'two-steps'],
  },
} as const satisfies Record<string, { variants: Readonly<['control', ...string[]]> }>

const featureFlags = reactive<Record<string, boolean | string | undefined>>({})

const client = posthog
client.onFeatureFlags((featureFlagsKeys) => {
  featureFlagsKeys.forEach((key) => {
    featureFlags[key] = client.getFeatureFlag(key)
  })

  Object.keys(featureFlags).forEach((key) => {
    if (!featureFlagsKeys.includes(key)) {
      delete featureFlags[key]
    }

    if (key in FEATURE_FLAGS) {
      resetFeatureFlagIfUnknown(key as keyof typeof FEATURE_FLAGS)
    }
  })
})

function resetFeatureFlagIfUnknown<T extends keyof typeof FEATURE_FLAGS>(key: T) {
  const variants = FEATURE_FLAGS[key].variants as Readonly<string[]>

  if (!variants.includes(featureFlags[key] as string)) {
    featureFlags[key] = 'control'
  }
}

export function useFeatureFlagEnabled(key: string): Ref<boolean | string | undefined> {
  const flag = ref(featureFlags[key])

  watch(
    () => featureFlags[key],
    (value) => {
      if (value === undefined) return
      if (value !== flag.value) {
        flag.value = value
      }
    }
  )

  return flag
}

export function useFeatureFlagVariantEnabled<TKey extends keyof typeof FEATURE_FLAGS>(
  key: TKey,
  variant: (typeof FEATURE_FLAGS)[TKey]['variants'][number]
): Ref<boolean | string | undefined> {
  const flag = useFeatureFlagEnabled(key)
  return computed(() => flag.value === variant)
}

export function useFeatureFlags() {
  return featureFlags
}
