import type { UnwrapRef } from 'vue'
import { reactive, watch } from 'vue'
import type { CaptionStyleDefinition } from '@/components/Captions/captionTypes'
import { addDays } from 'date-fns'
import { uniq } from 'lodash-es'
import type { Sticker } from '@/components/Stickers/stickerLibrary/stickerLibrary'
import type { TextLibraryItem } from '@/components/Stickers/textLibrary'
import type { CaptionStyle, CaptionStyleSettings } from '@/components/Captions/styles/captionStyles'

const twoWeeksAgo = addDays(new Date(), -14)

export const isNewCaption = (caption: CaptionStyleSettings | undefined) =>
  !!caption && caption.createdAt > twoWeeksAgo && !usedItems.captions.includes(caption.type)

export const newestCaptionFirst = (a: CaptionStyleSettings, b: CaptionStyleSettings) => {
  return newestFirstSort(a, b, isNewCaption, (c) => c.createdAt)
}

export const markCaptionAsUsed = (caption: CaptionStyle) => {
  usedItems.captions = uniq([...usedItems.captions, caption])
}

export const isNewSticker = (sticker: Sticker | undefined) =>
  !!sticker && sticker.createdAt > twoWeeksAgo && !usedItems.stickers.includes(sticker.key)

export const markStickerAsUsed = (sticker: Sticker) => {
  usedItems.stickers = uniq([...usedItems.stickers, sticker.key])
}

export const newestStickerFirst = (a: Sticker, b: Sticker) => {
  return newestFirstSort(a, b, isNewSticker, (s) => s.createdAt)
}

export const isNewText = (text: TextLibraryItem | undefined) =>
  !!text && text.createdAt > twoWeeksAgo && !usedItems.text.includes(text.key)

export const markTextAsUsed = (text: TextLibraryItem) => {
  usedItems.text = uniq([...usedItems.text, text.key])
}

export const newestTextFirst = (a: TextLibraryItem, b: TextLibraryItem) => {
  return newestFirstSort(a, b, isNewText, (s) => s?.createdAt ?? new Date())
}

const findUsedItemsInStorageOrDefault = () => {
  const storage = localStorage.getItem('__sl_used_overlay_elements')
  if (storage) {
    return JSON.parse(storage)
  }

  const initialState = {
    stickers: [],
    captions: [],
    text: [],
  }

  updateLocalStorage(initialState)
  return initialState
}

const updateLocalStorage = (payload: UnwrapRef<typeof usedItems>) => {
  localStorage.setItem('__sl_used_overlay_elements', JSON.stringify(payload))
}

const usedItems = reactive<Record<'stickers' | 'captions' | 'text', string[]>>(findUsedItemsInStorageOrDefault())
watch(usedItems, updateLocalStorage)

const newestFirstSort = <T extends CaptionStyleDefinition | Sticker | TextLibraryItem>(
  a: T,
  b: T,
  selectIsNew: (element: T) => boolean,
  selectDate: (element: T) => Date
) => {
  const aIsNew = selectIsNew(a)
  const bIsNew = selectIsNew(b)

  if (aIsNew > bIsNew) {
    return -1
  } else if (aIsNew < bIsNew) {
    return 1
  } else {
    return selectDate(b).getTime() - selectDate(a).getTime()
  }
}
