<script lang="ts" setup>
import { onMounted } from 'vue'

defineProps<{ color: 'pumpkin' | 'blood' | 'witch' }>()
const emit = defineEmits(['stickerLoaded'])

onMounted(() => emit('stickerLoaded', this))
</script>

<template>
  <svg
    :class="{
      'text-[#F98728]': color === 'pumpkin',
      'text-[#DA0062]': color === 'blood',
      'text-[#0FA688]': color === 'witch',
    }"
    class="h-24 w-48"
    fill="none"
    height="38"
    viewBox="0 0 86 38"
    width="86"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.7359 16.7323C57.1638 -0.500183 73.2509 -4.38308 86 4.90538C82.819 4.56176 71.6667 6.19824 78.5602 15.506C72.2108 15.302 68.448 20.4842 69.7602 25.1038C65.629 20.4907 61.0166 27.3459 61.4477 30.0261C57.4631 25.0866 51.6557 23.2761 49.3097 26.1067C47.9724 27.7196 47.5099 38.0024 47.5099 38.0024C47.5099 38.0024 45.3083 29.1112 41.4869 27.4898C38.1678 26.0809 33.4716 29.3324 31.3287 35.3565C30.862 32.6849 24.2781 27.8098 21.8903 33.5761C21.6182 28.7719 16.3821 25.1832 10.4659 27.5563C13.9273 16.4166 2.88592 18.7081 0 20.1256C8.98214 6.99072 25.4061 5.12229 39.9132 18.1175C39.9132 18.1175 38.9777 15.5168 38.2892 11.4105C38.9296 12.0333 39.4653 12.5724 39.7374 12.9031C41.623 12.1665 43.6571 11.7541 45.8985 11.7928C45.8985 11.7928 46.4635 9.92437 46.4656 9.48196C46.4656 9.03955 47.9054 15.2032 47.7359 16.7323Z"
      fill="#000"
    />
    <path
      d="M44.2954 15.2159C44.1092 14.9539 44.804 14.0068 45.2497 13.9252C45.9173 13.8027 46.2982 14.6468 46.1454 14.8723C45.9236 15.203 44.4356 15.4156 44.2933 15.2159H44.2954Z"
      fill="currentColor"
    />
    <path
      d="M42.4747 15.5383C42.5647 15.2269 41.5999 14.574 41.1542 14.6513C40.4845 14.7652 40.4029 15.6908 40.6184 15.8518C40.9365 16.0859 42.4056 15.7767 42.4747 15.5404V15.5383Z"
      fill="currentColor"
    />
  </svg>
</template>

<style lang="scss" scoped></style>
