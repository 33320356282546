import { ref, watch, computed } from 'vue'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { createGlobalState } from '@vueuse/core'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'

export const useCurrentSegment = createGlobalState(() => {

  const segmentsStore = useSegmentsStore()
  const videoStore = useVideoStore()
  const segments = segmentsStore.whereIsNotZoom()
  const editorFocusStore = useEditorFocusStore()
  
  const currentSegmentId = ref<string | null>()
  watch(() => editorFocusStore.focus, focus => {
    if (!focus) {
      currentSegmentId.value = null
    } else {
      const focusType = focus?.type
      if (focusType === FocusTypes.SEGMENT) {
        currentSegmentId.value = focus.key
      }
      const isFocussedOnSegmentPart = focusType === FocusTypes.SEGMENT || focusType === FocusTypes.CROP || focusType === FocusTypes.ZOOM
      if (!isFocussedOnSegmentPart) {
        currentSegmentId.value = null
      }
    }
  }, { deep: true })

  return computed(() => {
    const id = currentSegmentId.value
    const segment = id ? segmentsStore.state[id] : null
    if (segment && segment.type === 'layout') {
      return segment
    } else {
      return segments.value.find((segment) => {
        return segment.startMs <= videoStore.currentTimeMs 
          && segment.endMs >= videoStore.currentTimeMs
      }) ?? segments.value[0]
    }
  })
})
