<template>
  <div :class="color" class="generic-c">
    <div class="logo">
      <sticker-icons :icon="icon" />
    </div>
    <div class="text-wrapper">
      <TextSticker
        ref="sticker"
        variant="none"
        @update-rect="(e) => $emit('updateRect', e)"
        @update-content="(e) => $emit('updateContent', e)"
        @update="(e) => $emit('update', e)"
        :html-content="htmlContent"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const sticker = ref()
defineExpose({
  setEditMode: () => {
    sticker.value.setEditMode()
  },
})
</script>

<script>
import StickerIcons from '../../StickerIcons.vue'
import TextSticker from '@/components/Stickers/TextSticker.vue'

export default {
  components: {
    TextSticker,
    StickerIcons,
  },
  props: {
    htmlContent: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'purple',
    },
    icon: {
      type: String,
      default: 'twitch',
    },
    setEditMode: {
      type: Function,
      required: false,
    },
  },
  mounted() {
    this.$emit('stickerLoaded', this)
  },
}
</script>

<style lang="scss" scoped>
.purple {
  // twitch
  --background: linear-gradient(90deg, #ecebff -0.67%, #e9eeff);
  --font-color: #492480;
  --logo-color: #a236fe;
}

.red {
  // youtube
  --background: linear-gradient(90deg, #ffebeb -0.67%, #ffe9e9 100%);
  --font-color: #bd0808;
  --logo-color: #ff0000;
}

.blue {
  // facebook
  --background: linear-gradient(90deg, #ebf3ff -0.67%, #e9ebff 100%);
  --font-color: #1a3f89;
  --logo-color: #4267b2;
}

.green {
  --background: linear-gradient(90deg, #ebffed -0.67%, #e9ffea 100%);
  --font-color: #177c1c;
  --logo-color: #42b24b;
}

.pink {
  --background: linear-gradient(90deg, #ffebfe -0.67%, #ffe9ff 100%);
  --font-color: #b03b93;
  --logo-color: #de21c8;
}

.orange {
  --background: linear-gradient(90deg, #fff6eb -0.67%, #fff5e9 100%);
  --font-color: #b16e4b;
  --logo-color: #ff9500;
}

.generic-c {
  display: inline-flex;
  // overflow: hidden;
  white-space: nowrap;
  line-height: 0;
  user-select: none;

  background: var(--background);
  border-radius: 10px;

  width: auto;
  padding: 0.3em 0.5em;

  * {
    box-sizing: content-box;
  }

  > .text-wrapper {
    display: flex;
    align-items: center;
    margin-left: 0.3em;

    font-family: 'Metropolis';
    color: var(--font-color);

    .none {
      padding-left: 2px;
      font-weight: bold;
      font-style: normal;
      font-size: 23px;
      line-height: 23px;
    }
  }

  > .logo {
    display: flex;
    align-items: center;
    color: var(--logo-color);

    > svg {
      width: 23px;
      height: auto;
    }
  }
}
</style>
