<script setup lang="ts">
import type { StoredCaption } from '@/components/Captions/captionTypes'
import { computed, onMounted, ref, watch } from 'vue'
import CaptionText from '@/components/Captions/CaptionText.vue'
import { captionStylesSettings } from '@/components/Captions/styles/CaptionStyleManager'
import { merge } from 'lodash-es'
import { useFontsStore } from '@/store/fonts'

const props = defineProps<{
  variant: string
  // Maps to a hardcoded CSS Class, differs per Variant
  color: string
  // Start and end time in the GSAP timeline. In milliseconds
  start?: number
  end?: number
  // En-/disables setEditMode() function
  editable?: boolean
  // If set, these override the Text and Secondary colors
  primaryColor?: string
  secondaryColor?: string
  // Override the used font-family
  fontFamily?: string
  htmlContent?: string

  isRenderer?: boolean
}>()

const emit = defineEmits<{
  (event: 'updateRect', content: string): void
  (event: 'updateContent', content: string): void
  (event: 'stickerLoaded'): void
}>()

const text = ref(props.htmlContent || 'Enter text\n here')
const editing = ref(false)

if (!props.editable) {
  watch(() => props.htmlContent, (value) => {
    if (!editing.value && value && value !== text.value) {
      text.value = value
    }
  })
}

const update = (e: { text: string }) => {
  emit('updateRect', e.text)
}

const updateContent = async (event: InputEvent) => {

  window.getSelection()?.removeAllRanges()
  await new Promise((resolve) => setTimeout(resolve, 0))

  editing.value = false
  
  const target = event.target as HTMLInputElement

  if (target.innerText) {
    emit('updateContent', target.innerText)
  }
}

const setEditMode = () => {
  editing.value = true
}

defineExpose({
  setEditMode,
})

const storedCaption = computed<StoredCaption>(() => {
  return {
    start: props.start,
    end: props.end,
    color: props.color,
    words: [
      {
        text: text.value,
        start: props.start,
        end: props.end,
      },
    ],
  }
})

const captionStyleDefinition = computed(() => {
  const definition = captionStylesSettings[props.variant as keyof typeof captionStylesSettings] ?? captionStylesSettings.lit
  return merge(definition, { effects: { stripPunctuation: false } })
});

const styles = computed(() => {
  const scale = 0.7

  return {
    'font-size': captionStyleDefinition.value.fontSize.fontSize * scale + 'px',
    lineHeight: captionStyleDefinition.value.fontSize.lineHeight || 1.2,
  }
})

const fontsStore = useFontsStore()
onMounted(async () => {
  await fontsStore.loadFontByLabel(captionStyleDefinition.value.fontFamily)
  emit('stickerLoaded')
})
</script>

<template>
  <span
    class="relative text-center"
    :class="captionStyleDefinition.cssClass"
    @click="setEditMode"
    :style="styles"
  >
    <CaptionText
      class="whitespace-pre"
      :caption="storedCaption"
      :captionStyleDefinition="captionStyleDefinition"
      :editable="editing"
      @input="update"
      @blur="updateContent"
      :isRenderer="isRenderer"
    />
  </span>
</template>

<style scoped></style>
