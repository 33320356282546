<template>
  <div :class="color" class="generic-a">
    <div class="left">
      <div class="logo">
        <sticker-icons :icon="icon" />
      </div>
      <div class="live">LIVE</div>
    </div>

    <div class="text-wrapper">
      <TextSticker
        ref="sticker"
        variant="none"
        @update-rect="(e) => emit('updateRect', e)"
        @update-content="(e) => emit('updateContent', e)"
        @update="(e) => emit('update', e)"
        @sticker-loaded="(e) => emit('stickerLoaded', e)"
        :html-content="htmlContent"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import StickerIcons from '../../StickerIcons.vue'
import { ref } from 'vue'
import TextSticker from '@/components/Stickers/TextSticker.vue'

const props = defineProps({
  htmlContent: {
    type: String,
    default: '',
  },
  // Either blue, red, green
  color: {
    type: String,
    default: 'blue',
  },
  // Either twitch or youtube
  icon: {
    type: String,
    default: 'twitch',
  },
  setEditMode: {
    type: Function,
    required: false,
  },
})

const emit = defineEmits(['stickerLoaded', 'updateContent', 'update', 'paste', 'updateRect'])

const sticker = ref<TextSticker>()
defineExpose({
  setEditMode: () => {
    sticker.value.setEditMode()
  },
})
</script>

<style lang="scss" scoped>
.generic-a {
  --twitch-color: white;
  align-items: flex-start;
  display: flex;
  // overflow: hidden;
  white-space: nowrap;
  line-height: 0;
  user-select: none;

  width: auto;
  color: #ffffff;
  position: relative;

  * {
    box-sizing: content-box;
  }
    
  .text-wrapper {
    display: flex;
    align-items: center;

    padding: 0.75em;

    font-family: 'Metropolis';

    .none {
      font-weight: bold;
      font-style: normal;
      font-size: 23px;
      line-height: 23px;
    }
  }

  .logo {
    height: 1.95em;
    padding: 0.5em;

    display: flex;
    align-items: center;

    > svg {
      width: 1.5em;
    }
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .live {
    margin-top: -5px;
    font-size: 10px;
    height: 10px;
    padding: 2px 5px;
    line-height: 1.1;
    border-radius: 3em;
    font-weight: bold;
    background-color: red;
    top: 90%;
    left: 4px;
    color: white;
  }

  &.dark {
    .text-wrapper {
      background: #000000;
    }

    .logo {
      background: #9146ff;
    }
  }

  &.color {
    .text-wrapper {
      background: #000000;
    }

    .logo {
      background: #9146ff;
    }
  }

  &.light {
    .text-wrapper {
      background: #000000;
    }

    .logo {
      background: #ffffff;
      color: #9146ff;
    }
  }
}

</style>
