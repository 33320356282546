export class WSChannel {
  private channelName: string

  constructor(channelName: string) {
    // console.log('Channel', channelName)
    this.channelName = channelName
  }

  public bind(eventName: string, callback: (data: any) => void) {
    console.log('bind', this.channelName)
  }

  public bind_global(callback: (eventName: string, data: any) => void) {
    console.log('bind_global on', this.channelName)
  }

  public unbind_global(callback: (data: any) => void) {
    console.log('unbind_global', this.channelName)
  }

  public disconnect() {
    console.log('disconnect')
  }
}

export class WSAdapter {
  private connection: boolean

  constructor() {
    this.connection = false
  }

  public connect() {
    console.log('connect')
    this.connection = true
  }

  public disconnect() {
    console.log('disconnect')
    this.connection = false
  }

  public isConnected() {
    console.log('isConnected')
    return this.connection
  }

  public subscribe(channel: string) {
    console.log('subscribe', channel)
    return new Channel(channel)
  }

  public unsubscribe(channel: string) {
    console.log('unsubscribe', channel)
  }

  public bind_global(callback: (data: any) => void) {
    console.log('bind_global')
  }

  public unbind_global(callback: (data: any) => void) {
    console.log('unbind_global')
  }
}
