import type { Sticker } from '@/components/Stickers/stickerLibrary/stickerLibrary'
import GenericTranslucent from '@/components/Stickers/stickerLibrary/components/GenericTranslucent.vue'
import GenericA from '@/components/Stickers/stickerLibrary/components/GenericA.vue'
import GenericB from '@/components/Stickers/stickerLibrary/components/GenericB.vue'
import GenericC from '@/components/Stickers/stickerLibrary/components/GenericC.vue'
import GenericD from '@/components/Stickers/stickerLibrary/components/GenericD.vue'
import { markRaw } from 'vue'

const RAINBOW_GRADIENT =
  'linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) 10%, rgba(208,222,33,1) 20%, rgba(79,220,74,1) 30%, rgba(63,218,216,1) 40%, rgba(47,201,226,1) 50%, rgba(28,127,238,1) 60%, rgba(95,21,242,1) 70%, rgba(186,12,248,1) 80%, rgba(251,7,217,1) 90%, rgba(255,0,0,1) 100%)'

export const facebookStickers = [
  {
    key: 'facebook-generic-a',
    component: markRaw(GenericA),
    tags: ['facebook'],
    icon: 'facebook',
    colors: {
      blue: '#37C2FE',
      red: '#DA0062',
      green: '#0FA688',
      // rainbow: RAINBOW_GRADIENT
    },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'facebook-generic-b',
    component: markRaw(GenericB),
    icon: 'facebook',
    tags: ['facebook'],
    colors: { black: '#000', blue: '#223ECF', red: '#ff0000' },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'facebook-generic-c',
    component: markRaw(GenericC),
    icon: 'facebook',
    tags: ['facebook'],
    colors: { blue: '#4267B2', orange: '#ff9500', pink: '#de21c8', green: '#42b25a' },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'facebook-generic-d',
    component: markRaw(GenericD),
    icon: 'facebook',
    tags: ['facebook'],
    colors: { blue: '#4267B2', aqua: '#1BBDF2', black: '#000' },
    createdAt: new Date(1970, 0, 1),
  },
  {
    key: 'facebook-generic-translucent',
    component: markRaw(GenericTranslucent),
    tags: ['facebook'],
    icon: 'facebook',
    colors: { dark: '#000000', facebook: '#4267B2' },
    createdAt: new Date(2023, 9, 1),
  },
] as const satisfies Readonly<Sticker[]>
