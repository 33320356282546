<script setup lang="ts">
import tinyColor from 'tinycolor2'
import RadioToggleButton from '@/components-v2/data-input/RadioToggleButton.vue'
import IconSaxSlash from '@/components/Icons/iconsax/IconSaxSlash.vue'
import type { CaptionStyleDefinition } from '@/components/Captions/captionTypes'

defineProps<{ captionStyleSettings: CaptionStyleDefinition }>()
const model = defineModel<string | null | undefined>('color', { required: true })
</script>

<template>
  <RadioToggleButton
    class="flex-col relative font-light data-[state=active]:font-semibold shrink-0 p-0.5 h-auto rounded-full bg-transparent border-transparent"
    :value="null" v-model="model"
  >
    <IconSaxSlash class="w-4 h-4" />
  </RadioToggleButton>
  <RadioToggleButton
    class="flex-col relative font-light data-[state=active]:font-semibold shrink-0 p-0.5 h-auto rounded-full bg-transparent border-transparent"
    v-for="color in captionStyleSettings.colors"
    :key="color" :value="color"
    v-model="model"
  >
    <div
      class="h-4 w-4 rounded-full"
      :style="{
        background: captionStyleSettings.gradients?.[color] ?? color,
        borderColor: tinyColor(color).darken(40).toString()
      }"
      :class="tinyColor(color).getBrightness() > 200 ? 'border' : ''"
    />
  </RadioToggleButton>
</template>

<style scoped lang="scss">

</style>
