<script setup lang="ts">
defineProps<{ imageUrl: string; naturalWidth: number; naturalHeight: number; }>()

const emit = defineEmits<{
  (event: 'onAssetLoaded'): void
  (event: 'stickerLoaded'): void
}>()

function onLoad() {
  emit('onAssetLoaded')
  emit('stickerLoaded')
}
</script>

<template>
  <img
    draggable="false"
    class="select-none"
    crossorigin="anonymous"
    :src="imageUrl"
    :width="naturalWidth"
    :height="naturalHeight"
    alt="Giphy sticker"
    @load="onLoad"
  />
</template>

<style scoped lang="scss">

</style>
