import type { StoredCaption } from '@/components/Captions/captionTypes'

export const setBaseColor = (captions: StoredCaption[], baseColor: string) => {
  captions.forEach((caption) => {
    caption.color = baseColor
  })
}

export const setHighlightColor = (captions: StoredCaption[], color: string | undefined) => {
  captions.forEach((caption) => {
    caption.words.forEach((word) => {
      if (word.Highlighted) word.color = color
    })
  })
}
