import type { Area, Point, Directions, Bounds, Size } from '@/modules/SLMovable/@types/Movable'
import { cover, contain } from '@/modules/SLMovable/helpers/fit'
import { keepPointInsideBoundsX, keepPointInsideBoundsY } from '@/modules/SLMovable/helpers/point'

export function resizeAspectLockedFromCenter(
  from: Area,
  to: Point,
  directions: Directions,
  bounds: Bounds,
  aspectLock: Size,
  minSize: Size | null
): Area {

  const isResizingX = directions.includes('e') || directions.includes('w')
  const isResizingY = directions.includes('n') || directions.includes('s')

  if (isResizingX && isResizingY) {
    return resizeBothAspectLockedFromCenter(from, to, bounds, aspectLock, minSize)
  } else if (isResizingX) {
    return resizeXAspectLockedFromCenter(from, to, bounds, aspectLock, minSize)
  } else if (isResizingY) {
    return resizeYAspectLockedFromCenter(from, to, bounds, aspectLock, minSize)
  }

  throw new Error('At least one direction must be provided')
}

function resizeXAspectLockedFromCenter(
  from: Area,
  to: Point,
  bounds: Bounds,
  aspectLock: Size,
  minSize: Size | null
): Area {

  const anchorX = from.x + 0.5 * from.width
  const anchorY = from.y + 0.5 * from.height

  const deltaX = _findDeltaX(anchorX, to, bounds)

  const width = minSize
    ? Math.max(minSize.width, Math.abs(deltaX * 2))
    : Math.abs(deltaX * 2)

  const height = width * (aspectLock.height / aspectLock.width)

  const top = anchorY - 0.5 * height
  const bottom = top + height

  const outOfBoundsTop = -1 * top + keepPointInsideBoundsY(top, bounds)
  const outOfBoundsBottom = bottom - keepPointInsideBoundsY(bottom, bounds)

  const newHeight = height - 2 * Math.max(outOfBoundsTop, outOfBoundsBottom)
  const newWidth = newHeight * (aspectLock.width / aspectLock.height) * Math.sign(deltaX)

  return {
    x: anchorX - 0.5 * newWidth,
    y: anchorY - 0.5 * newHeight,
    width: Math.abs(newWidth),
    height: Math.abs(newHeight),
  }
}

function resizeYAspectLockedFromCenter(
  from: Area,
  to: Point,
  bounds: Bounds,
  aspectLock: Size,
  minSize: Size | null
): Area {

  const anchorY = from.y + 0.5 * from.height
  const anchorX = from.x + 0.5 * from.width

  const deltaY = _findDeltaY(anchorY, to, bounds)

  const height = minSize
    ? Math.max(minSize.height, Math.abs(2 * deltaY))
    : Math.abs(2 * deltaY)

  const width = height * (aspectLock.width / aspectLock.height)

  const left = anchorX - 0.5 * width
  const right = left + width

  const outOfBoundsLeft = -1 * left + keepPointInsideBoundsX(left, bounds)
  const outOfBoundsRight = right - keepPointInsideBoundsX(right, bounds)

  const newWidth = width - 2 * Math.max(outOfBoundsLeft, outOfBoundsRight)
  const newHeight = newWidth * (aspectLock.height / aspectLock.width) * Math.sign(deltaY)

  return {
    x: anchorX - 0.5 * newWidth,
    y: anchorY - 0.5 * newHeight,
    width: Math.abs(newWidth),
    height: Math.abs(newHeight),
  }
}

function resizeBothAspectLockedFromCenter(
  from: Area,
  to: Point,
  bounds: Bounds,
  aspectLock: Size,
  minSize: Size | null
): Area {

  const anchorX = from.x + 0.5 * from.width
  const anchorY = from.y + 0.5 * from.height

  const deltaX = _findDeltaX(anchorX, to, bounds)
  const deltaY = _findDeltaY(anchorY, to, bounds)

  const width = minSize
    ? Math.max(minSize.width, Math.abs(deltaX * 2))
    : Math.abs(deltaX * 2)
  const height = minSize
    ? Math.max(minSize.height, Math.abs(deltaY * 2))
    : Math.abs(deltaY * 2)
  
  const size = cover(aspectLock, {
    width: width,
    height: height,
  })

  const top = anchorY - 0.5 * size.height
  const bottom = top + size.height

  const left = anchorX - 0.5 * size.width
  const right = left + size.width

  const outOfBoundsLeft = -1 * left + keepPointInsideBoundsX(left, bounds)
  const outOfBoundsRight = right - keepPointInsideBoundsX(right, bounds)
  const mostOutOfBoundsX = Math.max(outOfBoundsLeft, outOfBoundsRight)

  const outOfBoundsTop = -1 * top + keepPointInsideBoundsY(top, bounds)
  const outOfBoundsBottom = bottom - keepPointInsideBoundsY(bottom, bounds)
  const mostOutOfBoundsY = Math.max(outOfBoundsTop, outOfBoundsBottom)

  if (mostOutOfBoundsX * (aspectLock.height / aspectLock.width) > mostOutOfBoundsY) {

    const newWidth = size.width - 2 * mostOutOfBoundsX
    const newHeight = newWidth * (aspectLock.height / aspectLock.width)

    return {
      x: anchorX - 0.5 * newWidth,
      y: anchorY - 0.5 * newHeight,
      width: newWidth,
      height: newHeight,
    }
  } else {

    const newHeight = size.height - 2 * mostOutOfBoundsY
    const newWidth = newHeight * (aspectLock.width / aspectLock.height)

    return {
      x: anchorX - 0.5 * newWidth,
      y: anchorY - 0.5 * newHeight,
      width: newWidth,
      height: newHeight,
    }
  }
}

function _findDeltaX(anchorX: number, to: Point, bounds: Bounds) {

  const clampedX = keepPointInsideBoundsX(to.x, bounds)

  const candidates = [Math.abs(anchorX - clampedX)]

  if (bounds && bounds.left !== null) {
    candidates.push(Math.abs(anchorX - bounds.left))
  }

  if (bounds && bounds.right !== null) {
    candidates.push(Math.abs(anchorX - bounds.right))
  }

  return Math.min(...candidates)
}

function _findDeltaY(anchorY: number, to: Point, bounds: Bounds) {

  const clampedY = keepPointInsideBoundsY(to.y, bounds)

  const candidates = [Math.abs(anchorY - clampedY)]

  if (bounds && bounds.top !== null) {
    candidates.push(Math.abs(anchorY - bounds.top))
  }

  if (bounds && bounds.bottom !== null) {
    candidates.push(Math.abs(anchorY - bounds.bottom))
  }

  return Math.min(...candidates)
}
