import { computed, type MaybeRefOrGetter, toValue } from 'vue'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import type { StoredWord } from '@/components/Captions/captionTypes'
import { PunctuationRegexp } from '@/data/PunctuationRegexp'

export const useStripPunctuation = (stripPunctuation?: MaybeRefOrGetter<boolean | undefined>) => {
  const editorCaptionsStore = useEditorCaptionsStore()
  return computed(() => {
    const stripIsEnabled = (toValue(stripPunctuation) ?? editorCaptionsStore.baseOptions.stripPunctuation)
    return (word: Pick<StoredWord, 'text' | 'editedByUser'>) => {
      if (stripIsEnabled && !word.editedByUser) {
        return word.text.replace(PunctuationRegexp, '')
      } else {
        return word.text
      }
    }
  })
}
