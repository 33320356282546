<template>
  <span class="error-icon">
    <CrossIcon class="w-[1em] h-[1em] inline fill-red-600" />
  </span>
</template>

<script>
import CrossIcon from "@/components/Icons/CrossIcon.vue";

export default {
  components: { CrossIcon }
}
</script>

<style lang="scss"></style>
