/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/vue-query'
import type { UseQueryOptions, QueryFunction, QueryKey, UseQueryReturnType } from '@tanstack/vue-query'
import { unref } from 'vue'
import type { MaybeRef } from '@tanstack/vue-query/build/lib/types'
import { streamLadderAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never

export const getApiYouTubeClipsClipId = (
  clipId: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  return streamLadderAxiosInstance<void>({ url: `/api/YouTubeClips/${unref(clipId)}`, method: 'get', signal }, options)
}

export const getGetApiYouTubeClipsClipIdQueryKey = (clipId: MaybeRef<string>) =>
  ['api', 'YouTubeClips', clipId] as const

export const getGetApiYouTubeClipsClipIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiYouTubeClipsClipId>>,
  TError = unknown
>(
  clipId: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiYouTubeClipsClipId>>, TError, TData>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getApiYouTubeClipsClipId>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiYouTubeClipsClipIdQueryKey(clipId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiYouTubeClipsClipId>>> = ({ signal }) =>
    getApiYouTubeClipsClipId(clipId, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!clipId, staleTime: 60000, ...queryOptions }
}

export type GetApiYouTubeClipsClipIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiYouTubeClipsClipId>>>
export type GetApiYouTubeClipsClipIdQueryError = unknown

export const useGetApiYouTubeClipsClipId = <
  TData = Awaited<ReturnType<typeof getApiYouTubeClipsClipId>>,
  TError = unknown
>(
  clipId: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiYouTubeClipsClipId>>, TError, TData>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiYouTubeClipsClipIdQueryOptions(clipId, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}
