import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { getFileFromStorage } from '@/components/Dialog/MultiUploadDialog/file-uploads/_storage'
import { useFileUploads } from '@/components/Dialog/MultiUploadDialog/file-uploads/useFileUploads'
import { onUserInfoReadyAsync, onLoggedIn } from '@/store/user/userInfo'
import { useGetUploadedVideosQuery } from '@/components/Dialog/MultiUploadDialog/file-uploads/useUploadedVideos'
import { metadataService } from '@/services/metadataService'
import { prepareStores } from '@/areas/editor/startup/generalStartupMethods'
import type { StartupConfig } from '@/areas/editor/startup/StartupConfig'
import { handleStartupError } from '@/areas/editor/startup/handleStartupError'
import { throwIfBrowserUnsupported } from '@/areas/editor/startup/UnsupportedBrowserError'

export async function startupFromLocalFileByClipId(clipId: string, config: StartupConfig) {
  
  try {
    throwIfBrowserUnsupported()

    const editorClipInfoStore = useEditorClipInfoStore()
    editorClipInfoStore.isLoadingClip = true
    editorClipInfoStore.id = clipId

    const fileData = await getFileFromStorage(clipId)
    config.signal.value?.throwIfAborted()

    editorClipInfoStore.loadingState = {
      state: 'loading',
      description: 'Retrieving file...'
    }

    // No file data, so we can't use the file
    if (fileData == null) {

      editorClipInfoStore.loadingState = {
        state: 'loading',
        description: 'Fetching uploaded videos...'
      }

      const upload = await fetchPreviousUpload(clipId, config)
      config.signal.value?.throwIfAborted()

      if (upload) {
        return await startupFromLocalFile({
          id: clipId,
          title: upload.title,
          mp4Url: upload.mp4Url,
        }, config)
      } else {

        editorClipInfoStore.loadingState = {
          state: 'error',
          description: 'File not found.'
        }

        return { error: 'File not found' }
      }
    }

    // Start refetching previous uploads to determine if upload needs be resumed.
    // Not awaited because the switch should be (mostly) seamless and user might not be logged in.
    const fileUploads = useFileUploads()
    fetchPreviousUpload(clipId, config)
      .then((upload) => {
        config.signal.value?.throwIfAborted()
        if (upload) {
          editorClipInfoStore.id = upload.id
          editorClipInfoStore.title = upload.title
          editorClipInfoStore.mp4Url = upload.mp4Url
        } else {
          fileUploads.resume(clipId)
        }
      }).catch(reason => {
      throw reason
    })

    const clipUrl = URL.createObjectURL(fileData)
    return await startupFromLocalFile({
      id: clipId,
      title: fileData?.name.split('.').slice(0, -1).join('.') ?? 'New Clip',
      mp4Url: clipUrl,
    }, config)
  } catch (e) {
    return handleStartupError(e)
  }
}

export async function startupFromLocalFile(clip: { id: string, title: string, mp4Url: string }, config: StartupConfig) {

  const editorClipInfoStore = useEditorClipInfoStore()
  editorClipInfoStore.id = clip.id
  editorClipInfoStore.title = clip.title
  editorClipInfoStore.mp4Url = clip.mp4Url
  editorClipInfoStore.source = 'local-file'
  editorClipInfoStore.languageCode = 'en_us'
  editorClipInfoStore.isLocalFile = true

  return await prepareStores(config)
}

async function fetchPreviousUpload(clipId: string, config: StartupConfig) {

  const userInfo = await onUserInfoReadyAsync()
  if (!userInfo.isAuthenticated) return null

  config.signal.value?.throwIfAborted()

  const { uploadedVideos, suspense } = useGetUploadedVideosQuery()
  await isLoggedInAsyncOrTimeout()
  config.signal.value?.throwIfAborted()

  await suspense()
  config.signal.value?.throwIfAborted()

  const previousUpload = uploadedVideos.value.find((upload) => upload.id === clipId)
  if (!previousUpload) return null

  // Validate if the video is playable
  const canPlay = await metadataService.canPlayUrl(previousUpload.videoUrl)
  if (!canPlay) return null

  config.signal.value?.throwIfAborted()

  return {
    id: previousUpload.id,
    title: previousUpload.name,
    mp4Url: previousUpload.videoUrl,
    slug: previousUpload.name,
    isLocalFile: true,
    source: 'local-file',
  }
}

async function isLoggedInAsyncOrTimeout() {
  return new Promise<void>((resolve) => {
    onLoggedIn(() => setTimeout(resolve, 0))
    setTimeout(() => resolve(), 5_000)
  })
}
