import { ref } from 'vue'
import { tryOnMounted, tryOnUnmounted } from '@vueuse/core'

export function useViewportSize() {

  const width = ref(window.innerWidth);
  const height = ref(window.innerHeight);

  const handleResize = () => {
    width.value = window.innerWidth;
    height.value = window.innerHeight;
  }
  
  tryOnMounted(() => {
    window.addEventListener('resize', handleResize);
    window.visualViewport?.addEventListener('resize', handleResize);
  })
  
  tryOnUnmounted(() => {
    window.removeEventListener('resize', handleResize);
    window.visualViewport?.addEventListener('resize', handleResize);
  })

  return { width, height };
}
