import { halloweenStickers } from '@/components/Stickers/stickerLibrary/seasonal/halloween/halloweenStickers'
import type { Sticker } from '@/components/Stickers/stickerLibrary/stickerLibrary'
import { uniq } from 'lodash-es'

function enhanceWithSeasonalTag(sticker: Sticker): Sticker {
  return {
    ...sticker,
    tags: uniq([...sticker.tags, 'seasonal']),
  }
}

export const seasonalStickers = [...halloweenStickers].map(enhanceWithSeasonalTag)
