import { createGlobalState, useRafFn } from '@vueuse/core'
import { ref, computed } from 'vue'
import type { StoredCaption, StoredWord } from '@/components/Captions/captionTypes'
import { useCaptionMethods } from '@/areas/editor/pages/captions/useCaptionMethods'

export const useCurrentCaption = createGlobalState(() => {
  
  const { findCaptionAtCurrentTime, findWordAtCurrentTime } = useCaptionMethods()

  const currentCaption = ref<StoredCaption | null>(null)
  const currentCaptionId = computed(() => currentCaption.value?.id ?? null)
  const currentWord = ref<StoredWord | null>(null)
  const currentWordId = computed(() => currentWord.value?.id ?? null)

  useRafFn(() => {
    currentCaption.value = findCaptionAtCurrentTime() ?? null 
    currentWord.value = findWordAtCurrentTime() ?? null
  })
  
  return {
    currentCaption,
    currentCaptionId,
    currentWord,
    currentWordId,
  }
})
