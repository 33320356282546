import { useUserInfoStore } from '@/store/user/userInfo'
import { computed, type MaybeRefOrGetter } from 'vue'
import type { Feature } from '@/data/features'
import features, { useFeature } from '@/data/features'
import { tiers } from '@/enums/tiers'
import { upgradeDialog } from '@/helpers/upgradeDialog'

export const useGuard = (guard: MaybeRefOrGetter<Feature | undefined>) => {
  const userInfo = useUserInfoStore()
  const feat = useFeature(guard)

  return computed(() => {
    if (!feat.value) return false
    return userInfo.tier >= feat.value.tier
  })
}

export const canGuard = (guard: Feature) => {
  const feat = features.find((f) => f.name === guard)
  if (!feat) return false
  return feat.tier <= useUserInfoStore().tier
}

export const canGuardWithPopup = (
  guard: Feature,
  overrides?: {
    title?: string
    subtitle?: string
  }
) => {
  const can = canGuard(guard)
  if (can) return true

  const feat = features.find((f) => f.name === guard)
  // @ts-expect-error
  if (!feat || feat.tier === tiers.FREE) return true

  if (overrides) {
    upgradeDialog.open({
      title: overrides.title,
      subtitle: overrides.subtitle,
      feature: guard,
      reason: `${guard} not available for user of tier ${useUserInfoStore().tier}`,
    })
  } else {
    upgradeDialog.open(guard)
  }

  return false
}
