import type { LayoutWithCrops, Crop } from '@/areas/editor/@type/Project'
import { baseColors, calculateFeedSize } from '@/areas/editor/@data/prefabs/_helpers'
import { contain } from '@/modules/SLMovable/helpers/fit'

export function full(videoWidth: number, videoHeight: number): LayoutWithCrops {
  return {
    id: 'full',
    name: 'Fullscreen',
    presetId: 'full',
    crops: [
      gameplay(videoWidth, videoHeight),
    ]
  }
}

function gameplay(videoWidth: number, videoHeight: number): Omit<Crop, 'layoutId'> {

  const size = 0.9
  const aspectRatio = 9 / 16

  const { width, height } = contain(
    { width: 1, height: 1 / aspectRatio },
    { width: videoWidth * size, height: videoHeight * size })

  const { width: feedWidth, height: feedHeight } = calculateFeedSize(width, height)

  return {
    id: 'full:gameplay',
    x: (0.5 * videoWidth - 0.5 * width) / videoWidth,
    y: (0.5 * videoHeight - 0.5 * height) / videoHeight,
    width: width / videoWidth,
    height: height / videoHeight,
    z: 1,
    feedData: {
      x: 0,
      y: 1 - feedHeight,
      width: feedWidth,
      height: feedHeight,
    },
    input: {
      name: 'Gameplay',
      color: baseColors.gameplayColor,      
      shape: '9:16'
    }
  }
}
