import type { EditorFragment } from '@/modules/SLVideoplayer/types'
import { contain } from '@/modules/SLMovable/helpers/fit'

export const LayoutKeys = {
  SmallFacecam: 'SmallFacecam',
  CircleFacecam: 'CircleFacecam',
  GameUi: 'GameUi',
  Split16x9: 'Split16x9',
  Split4x3: 'Split4x3',
  SplitFree: 'SplitFree',
  BlurredBackground: 'BlurredBackground',
  FullScreen: 'FullScreen',
  // Square: 'Square',
  Custom: 'Custom',
} as const

export type LayoutKey = keyof typeof LayoutKeys

export type Layout = {
  name: string
  cssClass: string
  premium: boolean
  sort: number
  baseFragments: EditorFragment[]
}

function calculateRemainingAspectRatio(width: number, height: number) {
  const taken = contain({ width, height }, { width: 9, height: 16 })
  return 9 / (16 - taken.height)
}

export const LayoutData = {
  SmallFacecam: {
    name: 'Small Facecam',
    cssClass: 'small-facecam',
    premium: true,
    sort: 1,
    baseFragments: [
      {
        key: 'smallfacecam_fc',
        source: '',
        zIndex: 2,
        feedOptions: {
          type: 'facecam',
        },
        cropOptions: {
          fixedRatio: false,
          aspectRatio: 16 / 9,
          initialCropSize: 0.2,
        },
      },
      {
        key: 'smallfacecam_gf',
        source: '',
        zIndex: 1,
        feedOptions: {
          resizable: false,
          snapVertical: false,
          type: 'gamefeed',
        },
        cropOptions: {
          fixedRatio: false,
          initialCropSize: 0.9,
          aspectRatio: 1,
          showSnapping: true,
        },
      },
    ],
  },
  CircleFacecam: {
    name: 'Circle Facecam',
    cssClass: 'circle-facecam',
    premium: true,
    sort: 2,
    baseFragments: [
      {
        key: 'circlefacecam_fc',
        source: '',
        zIndex: 2,
        feedOptions: {
          type: 'facecam',
        },
        cropOptions: {
          fixedRatio: true,
          aspectRatio: 1,
          initialCropSize: 0.2,
          isRound: true,
        },
      },
      {
        key: 'circlefacecam_gf',
        source: '',
        zIndex: 1,
        feedOptions: {
          resizable: false,
          snapVertical: false,
          type: 'gamefeed',
        },
        cropOptions: {
          fixedRatio: false,
          initialCropSize: 0.9,
          aspectRatio: 11 / 9,
          showSnapping: true,
        },
      },
    ],
  },
  GameUi: {
    name: 'Game Ui',
    cssClass: 'triple-crop',
    premium: true,
    sort: 3,
    baseFragments: [
      {
        key: 'gameui_fc',
        source: '',
        zIndex: 3,
        feedOptions: {
          type: 'facecam',
        },
        cropOptions: {
          fixedRatio: false,
          aspectRatio: 16 / 9,
          initialCropSize: 0.2,
        },
      },
      {
        key: 'gameui_gf',
        source: '',
        zIndex: 1,
        feedOptions: {
          resizable: false,
          snapVertical: true,
          type: 'gamefeed',
        },
        cropOptions: {
          fixedRatio: false,
          initialCropSize: 0.9,
          aspectRatio: 11 / 9,
          showSnapping: true,
        },
      },
      {
        key: 'gameui_ui',
        source: '',
        zIndex: 2,
        feedOptions: {
          type: 'gameui',
        },
        cropOptions: {
          fixedRatio: false,
          initialCropSize: 0.1,
          aspectRatio: 10 / 2,
        },
      },
    ],
  },
  Split16x9: {
    name: 'Split16x9',
    cssClass: 'split',
    premium: false,
    sort: 4,
    baseFragments: [
      {
        key: 'split16x9_fc',
        source: '',
        zIndex: 2,
        feedOptions: {
          resizable: false,
          draggable: false,
          type: 'facecam',
        },
        cropOptions: {
          fixedRatio: true,
          aspectRatio: 16 / 9,
          initialCropSize: 0.2,
        },
      },
      {
        key: 'split16x9_gf',
        source: '',
        zIndex: 1,
        feedOptions: {
          resizable: false,
          draggable: false,
          type: 'gamefeed',
        },
        cropOptions: {
          fixedRatio: true,
          initialCropSize: 0.9,
          aspectRatio: calculateRemainingAspectRatio(16, 9),
          showSnapping: true,
        },
      },
    ],
  },
  Split4x3: {
    name: 'Split4x3',
    cssClass: 'split',
    premium: false,
    sort: 4,
    baseFragments: [
      {
        key: 'split4x3_fc',
        source: '',
        zIndex: 2,
        feedOptions: {
          resizable: false,
          draggable: false,
          type: 'facecam',
        },
        cropOptions: {
          fixedRatio: true,
          aspectRatio: 4 / 3,
          initialCropSize: 0.2,
        },
      },
      {
        key: 'split4x3_gf',
        source: '',
        zIndex: 1,
        feedOptions: {
          resizable: false,
          draggable: false,
          type: 'gamefeed',
        },
        cropOptions: {
          fixedRatio: true,
          initialCropSize: 0.9,
          aspectRatio: calculateRemainingAspectRatio(4, 3),
          showSnapping: true,
        },
      },
    ],
  },
  SplitFree: {
    name: 'SplitFree',
    cssClass: 'split',
    premium: false,
    sort: 4,
    baseFragments: [
      {
        key: 'splitfree_fc',
        source: '',
        zIndex: 2,
        feedOptions: {
          resizable: false,
          draggable: false,
          type: 'facecam',
        },
        cropOptions: {
          fixedRatio: false,
          aspectRatio: 1,
          initialCropSize: 0.2,
        },
      },
      {
        key: 'splitfree_gf',
        source: '',
        zIndex: 1,
        feedOptions: {
          resizable: false,
          draggable: false,
          type: 'gamefeed',
        },
        cropOptions: {
          fixedRatio: false,
          initialCropSize: 0.6,
          aspectRatio: calculateRemainingAspectRatio(1, 1),
          showSnapping: true,
        },
      },
    ],
  },
  BlurredBackground: {
    name: 'Blurred',
    cssClass: 'blurred',
    premium: false,
    sort: 5,
    baseFragments: [
      {
        key: 'blurredbackground_gf',
        source: '',
        zIndex: 1,
        feedOptions: {
          type: 'gamefeed',
          resizable: false,
          draggable: false,
        },
        cropOptions: {
          fixedRatio: false,
          initialCropSize: 0.5,
          aspectRatio: 1,
          showSnapping: true,
        },
      },
    ],
  },
  FullScreen: {
    name: 'Fullscreen',
    cssClass: 'fullscreen',
    premium: false,
    sort: 6,
    baseFragments: [
      {
        key: 'fullscreen_gf',
        source: '',
        zIndex: 1,
        feedOptions: {
          type: 'gamefeed',
          resizable: false,
          draggable: false,
        },
        cropOptions: {
          fixedRatio: true,
          initialCropSize: 0.9,
          aspectRatio: 9 / 16,
          showSnapping: true,
        },
      },
    ],
  },
  // Square: {
  //   name: 'Square',
  //   cssClass: 'square',
  //   premium: false,
  //   sort: 7,
  //   baseFragments: [
  //     {
  //       key: 'square_fc',
  //       source: '',
  //       zIndex: 2,
  //       feedOptions: {
  //         resizable: false,
  //         draggable: false,
  //         snapVertical: false,
  //         type: 'facecam',
  //       },
  //       cropOptions: {
  //         fixedRatio: false,
  //         aspectRatio: 16 / 9,
  //         initialCropSize: 0.2,
  //       },
  //     },
  //     {
  //       key: 'square_gf',
  //       source: '',
  //       zIndex: 1,
  //       feedOptions: {
  //         resizable: false,
  //         draggable: false,
  //         snapVertical: false,
  //         type: 'gamefeed',
  //       },
  //       cropOptions: {
  //         fixedRatio: true,
  //         initialCropSize: 0.9,
  //         aspectRatio: 1,
  //         showSnapping: true,
  //       },
  //     },
  //   ],
  // },
} as const satisfies Record<LayoutKey, Layout>

export const getLayout = (layoutKey: LayoutKey | string) => {
  if (layoutKey?.toLowerCase() === 'split') {
    return LayoutData.Split16x9
  }

  // @ts-expect-error TS7053
  const layouts = Object.entries(LayoutData) as [LayoutKey, Layout][]
  return layouts.find(([key]) => key.toLowerCase() === layoutKey.toLowerCase())?.[1]
}

export function getLayoutsWithRoutes(baseUrl: string) {
  const layouts = LayoutData as Record<string, Layout>
  layouts.Split16x9.name = 'Split'
  return Object.entries(layouts)
    .map(([key, layout]) => {
      return {
        ...layout,
        key,
      }
    })
    .filter((x) => !['SplitFree', 'Split4x3'].includes(x.key))
    .sort((a, b) => a.sort - b.sort)
}

export const layoutKeys = getLayoutsWithRoutes('').map((x) => x.key)

export function getLayoutNameByKey(key: LayoutKey | string | undefined) {

  if (key === undefined) return undefined

  const isCustom = !Object.values(LayoutKeys)
    .map((key) => key.toLowerCase())
    .includes(key.toLowerCase())

  if (isCustom) {
    return 'Custom'
  } else {
    return key
  }
}
