<script setup lang="ts">
import BrandKitStyleDialog from "@/areas/editor/pages/text/brand-kit/BrandKitStyleDialog.vue";
import { Button } from "@/components/ui/button";
import GoldPlanButton from "@/components/Account/Upgrade/GoldPlanButton.vue";
import { ref } from "vue";
import { canGuardWithPopup } from "@/Hooks/useGuard";
import quotes from "@/data/quotes";
import type { FontLabel } from "@/data/fonts";
import { oldTextStylesArray } from "@/components/Stickers/textLibrary";
import { useUserBrandkitStore } from "@/store/user/userBrandkit";

const preview = ref(quotes.randomQuote());
const fontFamily = ref<FontLabel>("Inter");
const selected = ref<(typeof oldTextStylesArray)[number]['variant']>(oldTextStylesArray[0].variant);
const primary = ref("#02D9FC");
const secondary = ref("#333333");

const userBrandKitStore = useUserBrandkitStore();

const isSavingStyle = ref(false);
const isOpen = ref(false);
async function saveStyle() {
  const canSaveBrandKit = canGuardWithPopup('brandkit');
  if (!canSaveBrandKit) {
    return
  }

  isSavingStyle.value = true;
  await userBrandKitStore.add({
    primaryColor: primary.value,
    secondaryColor: secondary.value,
    fontFamily: fontFamily.value,
    variant: selected.value,
  });

  isSavingStyle.value = false;
  isOpen.value = false;
}
</script>

<template>
  <BrandKitStyleDialog
    v-model:open="isOpen"
    v-model:preview="preview"
    v-model:fontFamily="fontFamily"
    v-model:selected="selected"
    v-model:primary="primary"
    v-model:secondary="secondary"
  >
    <slot />
    <template #save>
      <Button variant="primary" :disabled="isSavingStyle" @click="saveStyle">
        <template v-if="isSavingStyle">
          <div class="w-4 h-4 border border-current border-t-transparent rounded-full animate-spin" />
          Saving...
        </template>
        <template v-else>
          Save custom style <GoldPlanButton feature="brandkit" :small="true" :can-click="false" />
        </template>
      </Button>
    </template>
  </BrandKitStyleDialog>
</template>

<style scoped lang="scss">

</style>
