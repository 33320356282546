<template>
  <div :class="[color, icon]" class="generic-a">
    <div class="logo">
      <sticker-icons :icon="icon" />
    </div>

    <div class="text-wrapper">
      <TextSticker
        ref="sticker"
        variant="none"
        @update-rect="(e) => emit('updateRect', e)"
        @update-content="(e) => emit('updateContent', e)"
        @update="(e) => emit('update', e)"
        @sticker-loaded="(e) => emit('stickerLoaded', e)"
        :html-content="htmlContent"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import StickerIcons from '../../StickerIcons.vue'
import { ref } from 'vue'
import TextSticker from '@/components/Stickers/TextSticker.vue'

const props = defineProps({
  htmlContent: {
    type: String,
    default: '',
  },
  // Either blue, red, green
  color: {
    type: String,
    default: 'blue',
  },
  // Either twitch or youtube
  icon: {
    type: String,
    default: 'twitch',
  },
  setEditMode: {
    type: Function,
    required: false,
  },
})

const emit = defineEmits(['stickerLoaded', 'updateContent', 'update', 'paste', 'updateRect'])

const sticker = ref<TextSticker>()
defineExpose({
  setEditMode: () => {
    sticker.value.setEditMode()
  },
})
</script>

<style lang="scss" scoped>
.twitch {
  --accent-color: #9146ff;
}

.kick {
  --accent-color: #53fc19;

  > .logo {
    svg {
      color: var(--accent-color);
    }
  }
}

.youtube {
  --accent-color: #ff0000;
}

.generic-a {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  user-select: none;

  justify-content: center;

  border-radius: 999999px;
  width: auto;
  color: #ffffff;

  > .text-wrapper {
    display: flex;
    align-items: center;
    font-family: 'Metropolis';

    .none {
      padding: 0.5em 0.75em 0.5em 0.4em;
      font-weight: bold;
      font-style: normal;
      font-size: 23px;
      line-height: 23px;
    }
  }

  > .logo {
    padding: 11px;
    border-radius: 999999px;
    aspect-ratio: 1;
    flex-shrink: 0;

    display: flex;
    align-items: center;

    height: 100%;

    justify-content: center;

    > svg {
      width: 1.5em;
      height: 1.5em;
    }
  }
}

.generic-a.dark {
  background: #000000;

  > .logo {
    background: #ffffff;
    color: #000000;
  }
}

.generic-a.light {
  background: #ffffff;
  color: #000000;

  > .logo {
    background: #000000;
    color: #ffffff;
  }
}

.generic-a.color {
  background: #ffffff;
  color: #000000;

  > .logo {
    background: var(--accent-color);
    color: #ffffff;
  }

  &.kick {
    background: var(--accent-color);

    > .logo {
      background: #000000;
    }
  }
}
</style>
